
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import Image from "./components/Image.vue";
import Intro from "./components/Intro.vue";
import Highlight from "./components/Highlight.vue";
import News from "./components/News.vue";
import Collection from "./components/Collection.vue"
import { useStore } from "vuex";

@Options({
  components: {
    Image,
    Intro,
    Highlight,
    News,
    Collection,
  },
})
export default class Home extends Vue {
  news: Array<any> = [];
  collections: Array<any> = [];
  heritages: Array<any> = [];
  layouts: Array<any> = [];
  page = 1;
  show = 100;
  search: string = "";
  ADDRESS = ADDRESS;
  offset = 0;
  store = useStore()
  // pages: number = 0;
  // elements:Array<any> = [
  //   { id: "1", name:"image", image: require("@/assets/layout/khon-001.jpg") },
  //   { id: "2", name:"intro", image: require("@/assets/layout/text.jpg") },
  //   { id: "2", name:"highlight", image: require("@/assets/layout/highlight-ich.jpg") },
  //   { id: "3", name:"news", image: require("@/assets/layout/news-event.jpg") },
  //   { id: "4", name:"collection", image: require("@/assets/layout/collection.jpg") },
  // ];
  pushOffset() {
    if (this.offset > (this.heritages.length - 4) * 32) {
      this.offset = 0;
    } else {
      this.offset += 32;
    }
  }
  getFirstPhoto(items: Array<any>) {
    for (const item of items) {
      let split = item.path.toLowerCase().split(".");
      let ext: string = split[split.length - 1];
      if (["jpg", "jpeg", "png", "gif", "bmp"].includes(ext)) {
        return item.path;
      }
    }
    return "n/a";
  }

  fetchNews() {
    let query = {
      search: {
        status: "ไฮไลท์",
      },
      page: this.page,
      limit: 4,
      sort: { code: -1 },
    };
    console.log(query);
    axios.post(`${ADDRESS}/blogs/paginate`, query).then((response) => {
      console.log(response);
      this.news = response.data.docs;
    });
  }
  fetchLayout() {
    axios.get(`${ADDRESS}/layout/get-by-name/home`).then((response) => {
      console.log(response);
      // if(!this.store.state.dataList.includes('layout')) {
      //   this.store.commit("setDataList",'layout')
      // }
      this.store.commit("setDataList",'layout')
      try {
        this.layouts = response.data.result.elementSingle;
      } catch (error) {
        this.layouts = [
          {
            name: "image",
            elementType: "image",
            image: "/img/khon-001.1095985f.jpg",
            _id: "62786f8ec7f286658d6ba7aa",
          },
          {
            name: "intro",
            elementType: "image",
            image: "/img/text.18d0b660.jpg",
            _id: "62786f8ec7f286658d6ba7ab",
          },
          {
            name: "highlight",
            elementType: "image",
            image: "/img/highlight-ich.f0d4882c.jpg",
            _id: "62786f8ec7f286658d6ba7ac",
          },
          {
            name: "news",
            elementType: "image",
            image: "/img/news-event.b27252c9.jpg",
            _id: "62786f8ec7f286658d6ba7ad",
          },
          {
            name: "collection",
            elementType: "image",
            image: "/img/collection.d5b566d4.jpg",
            _id: "62786f8ec7f286658d6ba7ae",
          },
        ];
      }
    });
  }
  fetchHeritage() {
    let query = {
      search: {
        status: "ไฮไลท์",
      },
      page: this.page,
      limit: 5,
      sort: { code: -1 },
    };
    console.log(query);
    axios.post(`${ADDRESS}/heritages/paginate`, query).then((response) => {
      console.log(response);
      this.heritages = response.data.docs;
    });
  }
  mounted() {
    this.fetchLayout();
    this.fetchHeritage()
    this.fetchNews()


  }
  stripHTML(text: string) {
    return (
      text
        .replace(/<\/?[^>]+(>|$)/g, "")
        .replace(/&nbsp;/g, "")
        .substring(0, 200) + "..."
    );
  }
}
