
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";

@Options({
  components: {},
})
export default class List extends Vue{
  docs:Array<any> = []
  page =  1
  show = 20
  search:string = ""
  pages: number = 0;
  total: number = 0;
  ADDRESS = ADDRESS
  sort: any = {
    start: -1,
    to:-1
  };
  // pages: number = 0;

  getFirstPhoto(items:Array<any>){
    for(const item of items){
      let split = item.path.split(".")
      let ext:string = split[split.length-1]
      if(['jpg','jpeg','png','gif','bmp'].includes(ext)){
        return item.path
      }
    }
    return "n/a"
  }
  fetchData() {
    this.docs = [];
    let sort: any = this.sort;
    let query = {
      search:{
        status: "เผยแพร่",
        $or:[
          {title:{ $regex: this.search }},
          {detail:{ $regex: this.search }},
        ]
      },
      page: this.page,
      limit: this.show,     
      
      sort: { 
        // code: -1,
        createdAt: -1
       },
    };
    console.log(query)
    axios
      .post(`${ADDRESS}/blogs/paginate`, query)
      .then((response) => {
        console.log(response)
        this.docs = response.data.docs
        this.pages = response.data.totalPages;

      });
  }
  mounted() {
    this.fetchData();
  }
}
