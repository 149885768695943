
import { Options, Vue } from "vue-class-component";
import L, { Icon } from "leaflet";
import axios from "axios";
import ADDRESS from "@/settings";
import "leaflet/dist/images/marker-shadow.png";
import * as _ from "lodash";
import { Watch } from "vue-property-decorator";
import Progress from "./components/Progress.vue";
import SaveIndicator from "@/components/SaveIndicator.vue";
import InputText from "@/views/portal/components/InputText.vue";
import InputNumber from "@/views/portal/components/InputNumber.vue";
import Textarea from "@/views/portal/components/Textarea.vue";
import { useToast, POSITION } from "vue-toastification";
@Options({
  components: {
    Progress,
    SaveIndicator,
    InputText,
    InputNumber,
    Textarea,
  },
  props: {
    modelValue: Array,
    folder: String,
    label: String,
  },
})
export default class HeritageForm extends Vue {
  engVersion: boolean = true;
  forms: Array<any> = [];
  item: any = {
    proposer: {},
    consent: {},
    imageDetail: {},
    coordinator: [{}],
  };
  doc: any = {
    proposer: {
      telephone:""
      },
    consent: {},
    coordinator: {
      telephone:""
    },
    items: [
      {
        name: "",
        level: "",
        data: {},
      },
    ],
  };

  saving: boolean = false;
  timeout!: number|any;
  autoSave() {
    let delay = 60 * 3 * 1000
    console.log("auto saving...");
    this.timeout = setTimeout(() => {
      this.autoSave();
      this.submitForm();
    }, delay);
  }
  clearAutoSave() {
    clearTimeout(this.timeout);
  }
  beforeUnmount() {
    this.clearAutoSave();
  }
  activityActive(name: string) {
    try {
      if (this.item.activities.includes(name)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  pushConsents() {
    if (!Array.isArray(this.item.consents)) this.item.consents = [];
    this.item.consents.push({
      firstName: "",
      lastName: "",
      status: "",
      ensure: false,
    });
  }

  get thailand() {
    const thailandObject = require("@/data/thailand.json");
    return _.values(thailandObject);
  }
  getDistrict(name: string) {
    try {
      const districtObject = this.thailand.find(
        (province: any) => province.name.th == name
      ).amphoes;
      return _.values(districtObject);
    } catch (error) {
      return [];
    }
  }
  getSubDistrict(name: string, field: string) {
    try {
      console.log({ item: this.item });
      const subDistrictObject = this.getDistrict(
        this.item[field].province
      ).find((district: any) => district.name.th == name).tambons;
      return _.values(subDistrictObject);
    } catch (error) {
      return [];
    }
  }
  mounted() {
    if (this.$route.params.id != undefined) {
      this.fetchData();
      this.autoSave();
    }
  }
  pushPerson() {
    let items: Array<any> = this.item.persons;
    if (!Array.isArray(items)) items = [];
    items.push({
      name: "",
      occupation: "",
    });
    this.item.persons = items;
  }
  pushItem() {
    let items: Array<any> = this.doc.items;
    if (!Array.isArray(items)) items = [];
    items.push({
      name: "",
      level: "",
    });
  }
  submitForm(formSubmit?: boolean) {
    this.saving = true;
    if (this.$route.params.id != undefined)
      axios
        .patch(`${ADDRESS}/request/update-item/${this.$route.params.id}`, {
          ...this.item,
          mp2: true,
        })
        .then((response) => {
          if (formSubmit) {
            const toast = useToast();
            toast.success("บันทึกข้อมูลแล้ว (ref:S-17)", {
              timeout: 2000,
              position: POSITION.BOTTOM_RIGHT,
            });
            this.$router.push(`/portal/request/item-list/${this.doc._id}`);
          } else {
            this.fetchData();
          }
          this.saving = false;
        });
    else
      axios
        .post(`${ADDRESS}/request/create`, {
          ...this.doc,
        })
        .then((response) => {
          const toast = useToast();
          toast.success("บันทึกข้อมูลแล้ว (ref:S-18)", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
        });
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/request/item/${this.$route.params.id}`)
      .then((res) => {
        console.log(res);
        let data = res.data.doc

        if (data.proposer == undefined) data.proposer = {};
        if (data.consent == undefined) data.consent = {};
        if (data.coordinator == undefined) data.coordinator = {};
        if (data.imageDetail == undefined) data.imageDetail = {};
        this.doc = data;
        this.item = res.data.item;
        console.log("proposer", this.item.proposer);
        if (this.item.proposer == undefined) this.item.proposer = {};
        if (this.item.consent == undefined) this.item.consent = {};
        if (this.item.coordinator == undefined) this.item.coordinator = {};
        if (this.item.imageDetail == undefined) this.item.imageDetail = {};
        else if (this.item.persons.length == 0) this.item.persons = [{}];
        if (this.item.activities.length === 0) {
          this.item.activities = [
            { subject: "การศึกษา วิจัย", checked: false, detail: "" },
            { subject: "การอนุรักษ์ พื้นฟู", checked: false, detail: "" },
            { subject: "การสืบสานและถ่ายทอด", checked: false, detail: "" },
            {
              subject: "การพัฒนาต่อยอดมรดกภูมิปัญญา",
              checked: false,
              detail: "",
            },
            { subject: "การดำเนินงานด้านอื่น ๆ", checked: false, detail: "" },
          ];
        }
        if (this.item.imageType.length === 0) {
          this.item.imageType = [
            { subject: "ข้อมูลภาพถ่าย", checked: false, detail: "" },
            { subject: "ข้อมูลภาพเคลื่อนไหว", checked: false, detail: "" },
            { subject: "ข้อมูลเสียง", checked: false, detail: "" },
          ];
        }
        if (this.item.data == undefined) this.item.data = {};
        axios
          .get(`${ADDRESS}/setting/get-by-name/data-field`)
          .then((response) => {
            try {
              console.log(response.data.result);
              this.forms = response.data.result.member;
            } catch (error) {
              this.forms = [];
            }
          });
      });
  }
}
