
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import { Watch } from "vue-property-decorator";

@Options({
  components: {},
  props: {
    id: String,
  },
})
export default class JMHeritage extends Vue {
  id: string = "";
  doc: any = {};
  ADDRESS = ADDRESS;
  index = 0;
  filesArray: Array<Object> = [];

  get active() {
    try {
      let res = this.filesArray[this.index];
      if (res == undefined) throw "undefined";
      return res;
    } catch (error) {
      return {
        path: "",
      };
    }
  }

  get filesArrayImage() {
    let ArrayImage: Array<Object> = [];
    this.filesArray.forEach((element: any) => {
      console.log(element);
      if (
        element.path.search(".png") > -1 ||
        element.path.search(".jpg") > -1 ||
        element.path.search(".bmp") > -1 ||
        element.path.search(".jpeg") > -1 ||
        element.path.search(".gif") > -1 ||
        element.path.search(".mp4") > -1 ||
        element.path.search(".PNG") > -1 ||
        element.path.search(".JPG") > -1 ||
        element.path.search(".MP4") > -1
      )
        ArrayImage.push(element);
    });
    console.log(ArrayImage);
    return ArrayImage;
  }

  fetchData() {
    axios
      .get(`${ADDRESS}/heritage/get/${this.$route.params.id}`)
      .then((response) => {
        console.log("response", response.data);
        this.doc = response.data.result;
        this.filesArray = this.doc.uploadItems;
      });
  }
  mounted() {
    this.fetchData();
  }
}
