
import { Options, Vue } from "vue-class-component";
import L, {Icon} from "leaflet";
import axios from "axios"
import ADDRESS from "@/settings"
import "leaflet/dist/images/marker-shadow.png";
import * as _ from "lodash"

@Options({
  components: {},
  props:{
    doc:Object
  }
})
export default class Progress extends Vue {
  doc!: any
  mounted(){
    console.log("doc",this.doc)
  }
}
