
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    page:Number,
    pages:Number,
    total:Number,
    show:Number,
    "onUpdate:modelValue": Function,
    modelValue: Object,
  },
})
export default class JMPubPaginate extends Vue {
  page!:number
  pages!:number
  total!:number
  show!:number
  limit:number = 5
  updatePaginate(i: number) {
    if(i>=1&&i<=this.pages)
      this.$emit('change',i)
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
}
