
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { DateTime } from "luxon";
import { confirmAction, time } from "@/decorators"
import * as _ from "lodash"
@Options({
  components: {},
})
export default class MediaList extends Vue{
  docs:Array<any> = []
  page =  1
  show = 100
  filter1:string|null = null
  filter2:string|null = null
  search:string = ""
  status = "รายการทั้งหมด"
  showAdvance:boolean = false
  showDetail:boolean = false
  statusOptions:Array<any> = [
    { icon: "fa-solid fa-circle", value:"เผยแพร่", color:'#00A325', weight:'fw-bold'},
    { icon: "fa-solid fa-circle-minus", value:"ไม่เผยแพร่", color:'grey', weight:'fw-bold'},
    // { icon: "", value:"แบบร่าง", color:'grey', weight:'fw-light', border:'.border-0' },
  ]
  get thailand() {
    const thailandObject = require("@/data/thailand.json")
    return _.values(thailandObject)
  }
  getDistrict(name:string){
    try {
      const districtObject = this.thailand.find((province:any) => province.name.th == name).amphoes
      return _.values(districtObject)
    } catch (error) {
      return []
    }
  }
  updateStatus(id:string, status:string){
    let option:any = {}
    if(status=="เผยแพร่") option = {status:"เผยแพร่"} 
    else if(status=="ไม่เผยแพร่") option = {status:"ไม่เผยแพร่"} 
    else if(status=="ไฮไลท์") option = {status:"เผยแพร่"} 
    console.log(id, status, option)
    axios.patch(`${ADDRESS}/community/update/${id}`, option).then(response=>{
      console.log(response.data)
      this.fetchData()
    })
  }
  get currentYear(){
    return new Date().getFullYear() + 543
  }
  @confirmAction("คุณต้องการลบรายการคอลเลกชัน")
  deleteItem(id:string) {
    axios.delete(`${ADDRESS}/community/delete/${id}`).then((response) => {
      this.fetchData()
    })
    console.log("ลบแล้ว!")
  }
  // deleteItem(i:number) {
  //   this.docs.splice(i,1)
  //   console.log("ลบแล้ว!")
  // }
  countstatus(status:string){
    try {
      if(status=="รายการทั้งหมด") return this.docs.length
      else return this.docs.filter(item=>item.status == status).length
    } catch (error) {
      return 0 
    }
  }
  fetchData() {
    let query = {
      search:{
        status:this.status!="รายการทั้งหมด"?this.status:undefined,
        province: this.filter1??undefined,
        district: this.filter2??undefined,
        $or:[
          {name:{ $regex: this.search }},
          {code:{ $regex: this.search }},
        ]
      },
      page: this.page,
      limit: this.show,     
      sort: { code: -1 },
      populate:"items"
    };
    console.log(this.filter1, query)
    axios
      .post(`${ADDRESS}/communities/paginate`, query)
      .then((response) => {
        this.docs = response.data.docs
        this.docs = this.docs.map(doc=>{
          return {
            ...doc,
            status:doc.isHighlight?"ไฮไลท์":doc.status
          }
        })
      });
  }
  mounted() {
    this.fetchData()
  }
}
