
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
@Options({
  components: {},
})
export default class Template extends Vue{
  name:Array<{display:string,name:string}> = [
    {display:"หน้าแรก", name:"home"},
    {display:"รายการมรดกภูมิปัญญาทางวัฒนธรรมระดับจังหวัด", name:"province"},
    {display:"รายการมรดกภูมิปัญญาทางวัฒนธรรมระดับชาติ", name:"national"},
    {display:"รายการมรดกภูมิปัญญาทางวัฒนธรรมระดับนานาชาติ", name:"international"},
    {display:"รายละเอียดมรดกภูมิปัญญาทางวัฒนธรรม", name:"detail"},
    // {display:"รายการชุมชน", name:"communitylist"},
    // {display:"รายละเอียดชุมชน", name:"communitydetail"},
    // {display:"หน่วยงานที่เกี่ยวข้อง", name:"agency"},
    // {display:"รายการกิจกรรมและข่าว", name:"newslist"},
    // {display:"รายละเอียดกิจกรรมและข่าว", name:"newsdetail"},
    // {display:"รายการสื่อและเอกสาร", name:"medialist"},
    {display:"ติดต่อเรา", name:"contact"}
  ]
}
