
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {},
})
export default class structureMenu extends Vue{
  doc:any = {}

  mounted(){
    this.fetchData()
  }
  fetchData(){
    axios.get(`${ADDRESS}/navigation/get/${this.$route.params.id}`).then((response) => {
      console.log(response.data)
      this.doc = response.data.result
    });
  }
  createNewItem(i:number){
    this.doc.children.splice(i,0,{
      name:"เมนูใหม่"
    })
  }
  pushChildren(i:number){
    if(!Array.isArray(this.doc.children[i].children)) this.doc.children[i].children = []
    this.doc.children[i].children.push({})
  }
  submitForm(){
    axios.patch(`${ADDRESS}/navigation/update/${this.$route.params.id}`,this.doc).then((response) => {
      console.log(response.data)
    });
    const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-32)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT,
    });
  }
}
