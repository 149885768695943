
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import "leaflet/dist/images/marker-shadow.png";
import * as _ from "lodash";
import JMMap from "@/components/JMMap.vue";
import { Watch } from "vue-property-decorator";
import { useToast, POSITION } from "vue-toastification";
import draggable from "vuedraggable";

@Options({
  components: {
    draggable
  },
})
export default class HeritageForm extends Vue {
  doc: any = {
    name: "",
    code: "ระบบจะสร้างรหัสให้อัตโนมัติ",
    activities: [],
    currentSituations: [],
    location: {
      type: "Point",
      coordinates: [100.5708937, 13.7670538],
    },
    nameEN: "",
    imageDetail: {},
    persons: [{}],
    areas: [{
      location: {
        type: "Point",
        coordinates: [100.5708937, 13.7670538],
      },
      address: "",
      zone: "",
      province: "",
      district: "",
      subDistrict: ""
    }],

    display: [
      "ลักษณะ/สาขา",
      "ประเภท",
      "ระดับการขึ้นบัญชีฯ",
      "สถานะการคงอยู่",
      "ปีที่ขึ้นทะเบียน",
      "สาระสำคัญโดยรวม",
    ],

    level: [],
    coverOffset: 0,
    uploadItems: []
  };
  branchType: Array<any> = []
  coverHeight: number = 0;
  isHidden = true;
  ADDRESS = ADDRESS;
  mymap: any;
  layer: any;
  zoom = 17;
  engVersion: boolean = true;
  statusOptions: Array<any> = [
    { icon: "fa-solid fa-circle", value: "เผยแพร่", color: "#00A325" },
    { icon: "fa-solid fa-circle-minus", value: "ไม่เผยแพร่", color: "grey" },
    { icon: "fa-solid fa-star", value: "ไฮไลท์", color: "#008ED9" },
    { icon: "", value: "แบบร่าง", color: "grey" },
  ];
  deleteItem(index: number) {
    this.doc.uploadItems.splice(index, 1);
  }
  @Watch("doc.branch")
  banchType() {
    this.getBranch();
  }
  get thailand() {
    const thailandObject = require("@/data/thailand.json");
    return _.values(thailandObject);
  }
  getBranch() {
    let branchType = "";
    switch (this.doc.branch) {
      case "วรรณกรรมพื้นบ้านและภาษา":
        branchType = "01";
        break;
      case "ศิลปะการแสดง":
        branchType = "02";
        break;
      case "แนวปฏิบัติทางสังคม พิธีกรรม ประเพณี และเทศกาล":
        branchType = "03";
        break;
      case "ความรู้และแนวปฏิบัติเกี่ยวกับธรรมชาติและจักรวาล":
        branchType = "04";
        break;
      case "งานช่างฝีมือดั้งเดิม":
        branchType = "05";
        break;
      case "การเล่นพื้นบ้าน กีฬาพื้นบ้านและศิลปะการต่อสู้ป้องกันตัว":
        branchType = "06";
        break;
      default:
        break;
    }
    this.doc.branchType = branchType;
    return branchType;
  }

  getFirstPhoto(items: Array<any>) {
    try {
      for (const item of items) {
        let split = item.path.toLowerCase().split(".");
        let ext: string = split[split.length - 1];
        if (["jpg", "jpeg", "png", "gif", "bmp"].includes(ext)) {
          return item.path;
        }
      }
    } catch (error) {
      return "n/a";
    }
    return "n/a";
  }

  get coverPercent() {
    try {
      return ((this.doc.coverOffset ?? 0) / (this.coverHeight ?? 0)) * 100;
    } catch (error) {
      return 0;
    }
  }

  // get type() {
  //   switch (this.doc.branch) {
  //     case "ศิลปะการแสดง":
  //       return [
  //         "ดนตรี",
  //         "การแสดง",
  //         "ดนตรีและการแสดงในพิธีกรรม",
  //         "เพลงร้องพื้นบ้าน",
  //       ];
  //       break;
  //     case "งานช่างฝีมือดั้งเดิม":
  //       return [
  //         "ผ้าและผลิตภัณฑ์จากผ้า",
  //         "เครื่องจักสาน",
  //         "เครื่องรัก",
  //         "เครื่องปั้นดินเผา",
  //         "เครื่องโลหะ",
  //         "เครื่องไม้",
  //         "เครื่องหนัง",
  //         "เครื่องประดับ",
  //         "งานศิลปกรรมพื้นบ้าน",
  //         "ผลิตภัณฑ์อย่างอื่น",
  //       ];
  //       break;

  //     case "วรรณกรรมพื้นบ้านและภาษา":
  //       return [
  //         "นิทานพื้นบ้าน",
  //         "ประวัติศาสตร์บอกเล่า",
  //         "บทสวดหรือบทกล่าวในพิธีกรรม",
  //         "บทร้องพื้นบ้าน",
  //         "สำนวนและภาษิต",
  //         "ปริศนาคำทาย",
  //         "ตำรา",
  //         "ภาษาไทย",
  //         "ภาษาถิ่น",
  //         "ภาษากลุ่มชาติพันธุ์",
  //       ];
  //       break;

  //     case "การเล่นพื้นบ้าน กีฬาพื้นบ้านและศิลปะการต่อสู้ป้องกันตัว":
  //       return ["การเล่นพื้นบ้าน", "กีฬาพื้นบ้าน", "ศิลปะการต่อสู้ป้องกันตัว"];
  //       break;

  //     case "ความรู้และแนวปฏิบัติเกี่ยวกับธรรมชาติและจักรวาล":
  //       return [
  //         "อาหารและโภชนาการ",
  //         "การแพทย์แผนไทยและการแพทย์พื้นบ้าน",
  //         "โหราศาสตร์และดาราศาสตร์",
  //         "การจัดการทรัพยากรธรรมชาติ",
  //         "ชัยภูมิและการรตั้งถิ่นฐาน",
  //       ];
  //       break;

  //     case "แนวปฏิบัติทางสังคม พิธีกรรม ประเพณี และเทศกาล":
  //       return ["มารยาท", "ขนบธรรรมเนียมประเพณี", "งานเทศกาล"];
  //       break;

  //     default:
  //       return [];
  //       break;
  //   }
  // }
  get type() {
    try {
      let found = this.branchType.find(bt => bt.name === this.doc.branch)
      return found.member.map((mb: any) => mb.name)
    } catch (error) {

    }
    return []
  }
  getDistrict(name: string) {
    try {
      const districtObject = this.thailand.find(
        (province: any) => province.name.th == name
      ).amphoes;
      return _.values(districtObject);
    } catch (error) {
      return [];
    }
  }
  getSubDistrict(name: string, index: number) {
    try {
      const subDistrictObject = this.getDistrict(this.doc.areas[index].province).find(
        (district: any) => district.name.th == name
      ).tambons;
      return _.values(subDistrictObject);
    } catch (error) {
      return [];
    }
  }
  get today() {
    return new Date();
  }
  async submitForm(isDraft = false) {
    try {
      if (this.doc.location.type !== "Point") this.doc.location.type = "Point"
    } catch (error) {

    }
    if (this.$route.params.id != undefined) {
      console.log("this.doc", this.doc.currentSituations);

      axios
        .patch(`${ADDRESS}/heritage/update/${this.$route.params.id}`, this.doc)
        .then((response) => {
          console.log(response);
          const toast = useToast();
          toast.success("แก้ไขข้อมูลแล้ว!", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
          let savedDoc = response.data.result;
          console.log({ doc: this.doc, savedDoc });
          this.$router.push(`/portal/heritage??page=${this.$route.query.page}`)
          // if (isDraft) this.$router.push(`/portal/heritage/edit/${savedDoc._id}`);
          // else this.$router.push(`/portal/heritage`);
        });
    } else {
      let response = await axios
        .post(`${ADDRESS}/heritage/create`, {
          ...this.doc,
        })
      // .then((response) => {
      // });

      console.log("create33", response.data)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-10)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      let savedDoc = response.data;
      this.$router.push(`/portal/heritage/edit/${savedDoc._id}`);
      setTimeout(() => {
        window.location.reload()
      }, 250);
      //this.$router.push(`/portal/heritage`);

    }

  }
  fetchData() {
    axios
      .get(`${ADDRESS}/heritage/get/${this.$route.params.id}`)
      .then((response) => {
        console.log("response", response.data);
        this.doc = response.data.result;
        if (this.doc.areas.length == 0) {
          this.doc.areas = [{
            //location:{},
            location: this.doc.location ?? {
              type: "Point",
              coordinates: [100.5708937, 13.7670538],
            },
            address: this.doc.address ?? "",
            zone: this.doc.zone ?? "",
            province: this.doc.province ?? "",
            district: this.doc.district ?? "",
            subDistrict: this.doc.subDistrict ?? ""
          }]
        }
        if (this.doc.nameEN != undefined) {
          this.isHidden = false;
          this.doc.engVersion = this.isHidden;
          if (
            Number.isNaN(this.doc.coverOffset) ||
            typeof this.doc.coverOffset == "undefined"
          ) {
            this.doc.coverOffset = 0;
          }
        }
      });
  }
  async fetchBranchType() {
    const response1 = await axios.get(`${ADDRESS}/setting/get-by-name/วรรณกรรมพื้นบ้านและภาษา`)
    const response2 = await axios.get(`${ADDRESS}/setting/get-by-name/ศิลปะการแสดง`)
    const response3 = await axios.get(`${ADDRESS}/setting/get-by-name/แนวปฏิบัติทางสังคม พิธีกรรม ประเพณี และเทศกาล`)
    const response4 = await axios.get(`${ADDRESS}/setting/get-by-name/ความรู้และแนวปฏิบัติเกี่ยวกับธรรมชาติและจักรวาล`)
    const response5 = await axios.get(`${ADDRESS}/setting/get-by-name/งานช่างฝีมือดั้งเดิม`)
    const response6 = await axios.get(`${ADDRESS}/setting/get-by-name/การเล่นพื้นบ้าน กีฬาพื้นบ้านและศิลปะการต่อสู้ป้องกันตัว`)
    this.branchType = [
      { name: response1.data.result.name, member: response1.data.result.member },
      { name: response2.data.result.name, member: response2.data.result.member },
      { name: response3.data.result.name, member: response3.data.result.member },
      { name: response4.data.result.name, member: response4.data.result.member },
      { name: response5.data.result.name, member: response5.data.result.member },
      { name: response6.data.result.name, member: response6.data.result.member },
    ]
  }
  mounted() {

    this.fetchBranchType()
    if (this.$route.params.id == undefined) {
      this.getBranch();
    } else {
      this.fetchData();
    }
    this.$nextTick(() => {
      setTimeout(() => {
        let cover = this.$refs.cover as any;
        // console.log(cover.clientHeight);
        this.coverHeight = cover.clientHeight;
        if (this.coverHeight === 0) this.coverHeight = 300
      }, 200);
    });
  }
  pushPerson() {
    let items: Array<any> = this.doc.persons;
    if (!Array.isArray(items)) items = [];
    items.push({
      name: "",
      occupation: "",
      knowledge: "",
      contact: "",
    });
    this.doc.persons = items;
  }
  pushArea() {
    if (!Array.isArray(this.doc.areas)) this.doc.areas = []
    let items: Array<any> = this.doc.areas;
    items.push({
      location: {
        type: "Point",
        coordinates: [100.5708937, 13.7670538],
      },
      address: "",
      zone: "",
      province: "",
      district: "",
      subDistrict: ""
    });
    this.doc.areas = items;
  }
}
//
