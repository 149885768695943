
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import { Watch } from "vue-property-decorator";
import JMColorPicker from "@/components/JMColorPicker.vue";
import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {
    JMColorPicker,
  },
})
export default class Color extends Vue {
  colors: any | string = "#194D33A8";
  color = "";
  doc = {
    _id:"",
    member:[
      { name:"Primary", text1:"#3E3757" },
      { name:"Secondary", text1:"#E6DBCF" },
      { name:"Light", text1:"#F9F9FB" },
      { name:"Dark", text1:"#71350D" },
      { name:"Highlight", text1:"#F4DBB8" },
      { name:"Background", text1:"#F5F5F5" },
      { name:"BoxBackground", text1:"#FFFFFF" },
    ]
  }
  reset(){
    this.doc.member = [
      { name:"Primary", text1:"#3E3757" },
      { name:"Secondary", text1:"#E6DBCF" },
      { name:"Light", text1:"#F9F9FB" },
      { name:"Dark", text1:"#71350D" },
      { name:"Highlight", text1:"#F4DBB8" },
      { name:"Background", text1:"#F5F5F5" },
      { name:"BoxBackground", text1:"#FFFFFF" },
    ]
  }
  mounted(){
    this.fetchData()
  }
  getColor(i:number){
    try {
      return this.doc.member[i]
    } catch(error){
      return {}
    }
    // doc.member[0].text1
    // doc.member[1].text1
    // doc.member[2].text1
    // doc.member[3].text1
    // doc.member[4].text1
    // doc.member[5].text1
  }
  submit(){
    axios.patch(`${ADDRESS}/setting/update/${this.doc._id}`,this.doc).then(response=>{
      console.log("done!")
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-28)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
    })
  }
  fetchData(){
    axios.get(`${ADDRESS}/setting/get-by-name/color`).then((response) => {
      console.log(response.data.result)
      if(response.data.result.member.length > 0){
        this.doc = response.data.result
      } else {
        this.doc._id = response.data.result._id
      }
      this.$forceUpdate()
      // if(response.data.result.member === []){
      //   this.reset()
      //   this.doc._id = response.data.result._id
      // } else {
      //   this.doc = response.data.result;
      // }
    });
  }
}
