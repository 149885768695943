import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "jm-select-container" }
const _hoisted_2 = { class: "d-flex align-items-center justify-content-center" }
const _hoisted_3 = { class: "position-relative" }
const _hoisted_4 = {
  class: "list-group mt-0 position-absolute w-100 bg-white",
  style: {"z-index":"999"}
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "d-flex align-items-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["form-select mb-0", _ctx.displaySelect.border]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.active = !_ctx.active))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("i", {
          class: _normalizeClass(["me-2", _ctx.displaySelect.icon]),
          style: _normalizeStyle(`color:${_ctx.displaySelect.color}`)
        }, null, 6),
        _createElementVNode("span", {
          class: _normalizeClass(["text-nowrap", _ctx.displaySelect.weight]),
          style: _normalizeStyle(`color:${_ctx.displaySelect.color}`)
        }, _toDisplayString(_ctx.displaySelect.value), 7)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "list-group-item cursor-pointer hoverable",
            onClick: ($event: any) => (_ctx.emitUp(item))
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("i", {
                class: _normalizeClass(["me-2", item.icon]),
                style: _normalizeStyle(`color:${item.color}`)
              }, null, 6),
              _createElementVNode("span", {
                class: _normalizeClass(["text-nowrap", item.weight]),
                style: _normalizeStyle(`color:${item.color}`)
              }, _toDisplayString(item.value), 7)
            ])
          ], 8, _hoisted_5))
        }), 256))
      ], 512), [
        [_vShow, _ctx.active]
      ])
    ])
  ])), [
    [_directive_click_outside, _ctx.close]
  ])
}