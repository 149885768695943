
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";

@Options({
  components: {},
})
export default class Home extends Vue {
  collections: Array<any> = [];
  page = 1;
  show = 100;
  search: string = "";
  ADDRESS = ADDRESS;

  fetchCollection() {
    let query = {
      search: {
        status:"เผยแพร่",
      },
      page: this.page,
      limit: 20,
      sort: { code: -1 },
    };
    console.log(query);
    axios.post(`${ADDRESS}/collections/paginate`, query).then((response) => {
      // console.log({collections:response.data})
      this.collections = response.data.docs;
    });
  }
  mounted() {
    this.fetchCollection();
  }
}
