
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings"
import { DateTime } from "luxon";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Login extends Vue {
  username:string=""
  password:string=""
  store = useStore()
  showPassword:boolean = true

  show() {
    this.showPassword = !this.showPassword
    console.log('showPassword',this.showPassword)
  }
  submitLogin() {
    console.log("login")
    axios.post(`${ADDRESS}/user/login`,{
      username :this.username,
      password :this.password,
    })
    .then((response) => {
      console.log(response)
      if(response.data.status === "success"){  
        alert("เข้าสู่ระบบสำเร็จแล้ว")
        this.store.commit("setUser", response.data.user)
        this.$router.push(`/portal`)
      }
      else {  
        alert("เข้าสู่ระบบไม่สำเร็จ")
        alert(response.data.result)
      }
    })
  }

}

