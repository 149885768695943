import store from "@/store"
export function confirmAction(displayText?:string) {
  return function (target: Object, key: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value
    descriptor.value = function (...args: any[]) {
      const allow = confirm(displayText??"คุณแน่ใจหรือไม่?")
      if (allow) {
        const result = original.apply(this, args)
        return result
      } else {
        return null
      }
    }
  }
}

export function time(self:any){
  return function(target: Object, key: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value
    descriptor.value = function(...args: any[]){
      console.log(store.getters.loading)
      store.commit("setLoading", true)
      console.log(store.getters.loading)
      console.time(key)
      const result = original.apply(this, args)
      console.log(store.getters.loading)
      store.commit("setLoading", false)
      console.log(store.getters.loading)
      console.timeEnd(key)
      return result
    }
  }
}

export function ConfirmAction(warning?: string) {
  return function(target: Object, key:string, descriptor:PropertyDescriptor){
    const original = descriptor.value
    descriptor.value = function(...args:any[]){
      const allow = confirm(warning??"คุณแน่ใจหรือไม่?")
      if(allow){
        const result = original.apply(this, args)
        return result
      } else {
        return null
      }
    }
  }
}