
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import * as _ from "lodash";
import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {},
})
export default class RequestForm extends Vue {
  isHighlight: boolean = false;
  doc: any = {
    code: "ระบบจะสร้างรหัสให้อัตโนมัติ",
    items: [],
    articles: [],
    status: "",
    isHighlight: false,
  };
  search: string = "";
  search2: string = "";

  heritages: Array<any> = [];
  articles: Array<any> = [];
  debouncedHandler: any;
  created() {
    this.debouncedHandler = _.debounce((event) => {
      console.log("New value:", event.target.value);
      this.fetchHeritage();
      this.fetchArticle();
    }, 1000);
  }
  beforeUnmount() {
    this.debouncedHandler.cancel();
  }
  fetchHeritage() {
    console.log("fetching...");
    axios
      .post(`${ADDRESS}/heritages/paginate`, {
        search: {
          name: {
            $regex: this.search,
          },
        },
        limit: 5,
        skip: 0,
        page: 1,
        select: {
          _id: 1,
          name: 1,
          code: 1,
          province: 1,
          branch: 1,
          level: 1,
          status: 1,
          createdAt: 1,
        },
      })
      .then((res) => {
        console.log(res);
        this.heritages = res.data.docs;
      });
  }
  fetchArticle() {
    console.log("fetching...");
    axios
      .post(`${ADDRESS}/articles/paginate`, {
        search: {
          title: {
            $regex: this.search2,
          },
        },
        limit: 5,
        skip: 0,
        page: 1,
        select: {
          _id: 1,
          code: 1,
          title: 1,
          detail: 1,
          createdAt:1
        }
      })
      .then((res) => {
        console.log(res);
        this.articles = res.data.docs;
      });
  }
  mounted() {
    _.throttle(this.fetchHeritage, 5000);
    _.throttle(this.fetchArticle, 5000);
    if (this.$route.params.id != undefined) this.fetchData();
  }
  get today() {
    return new Date();
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/collection/get/${this.$route.params.id}`)
      .then((res) => {
        console.log("re", res);
        this.doc = res.data.result;
        if(this.doc.items===undefined) this.doc.items = []
        if(this.doc.articles===undefined) this.doc.articles = []
      });
  }
  submitForm(isDraft = false) {
    if (this.$route.params.id != undefined)
      axios
        .patch(`${ADDRESS}/collection/update/${this.$route.params.id}`, {
          ...this.doc,
        })
        .then((response) => {
          const toast = useToast();
          toast.success("บันทึกข้อมูลแล้ว (ref:S-3)", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
          let savedDoc = response.data.result;
          console.log({ savedDoc });
          if (isDraft)
            this.$router.push(`/portal/collection/edit/${savedDoc._id}`);
          else this.$router.push(`/portal/collection`);
        });
    else
      axios
        .post(`${ADDRESS}/collection/create`, {
          ...this.doc,
        })
        .then((response) => {
          const toast = useToast();
          toast.success("บันทึกข้อมูลแล้ว (ref:S-4)", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
          let savedDoc = response.data
          console.log({savedDoc})
          if (isDraft) this.$router.push(`/portal/collection/edit/${savedDoc._id}`);
          else this.$router.push(`/portal/collection`);
        });
  }
}
