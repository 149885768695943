
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { Watch } from "vue-property-decorator";

@Options({
  components: {},
})
export default class lawmanualform extends Vue{
  docs:Array<any> = []
  page =  1
  show = 100
  search:string = ""
  textSearch:string = ""
  pages: number = 0;
  ADDRESS = ADDRESS
  showDetail:boolean = false
  get filtered(){
    return this.docs.filter(doc=>doc.name.search(this.textSearch) > -1)
  }
  @Watch("search", { deep: true })
  setQuery() {
    this.$router.push({
      path: this.$route.path,
      query: {
        search: JSON.stringify(this.search),
      },
    });
  }
  getQuery() {
    try {
      // this.search = JSON.parse(this.$route.query.search as string);
    } catch (error) {}
  }
  fetchData() {
    let query = {
      search:{
        status:"เผยแพร่",
        type:"law-manual-form"
      },
      page: this.page,
      limit: this.show,     
      sort: { code: -1 },
    };
    console.log(query)
    axios
      .post(`${ADDRESS}/medias/paginate`, query)
      .then((response) => {
        console.log(response)
        this.docs = response.data.docs
        this.pages = response.data.totalPages;
      });
  }
  mounted() {
    this.fetchData();
  }
}
