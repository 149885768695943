
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings"
import contenteditable from "vue-contenteditable";

@Options({
  components: {
    contenteditable,
  },
  props:{
    modelValue:Object,
    "update:modelValue":Function
  }
})
export default class MP2PDF extends Vue {
  item:any = {
    proposer: {},
    consent: {},
    imageDetail: {},
    coordinator: [{}]
  }
  doc: any = {
    proposer:{},
    consent:{},
    coordinator:[],
    items: [
      {
        name:"",
        level: "",
        data:{},
      }
    ],
  };
  print(){
    window.print()
  }
  fetchData(){
    axios.get(`${ADDRESS}/request/item/${this.$route.params.id}`).then((res) => {
      console.log(res)
      this.doc = res.data.doc;
      this.item = res.data.item
      console.log("proposer",this.item.proposer)
      if(this.item.proposer==undefined) this.item.proposer = {}
      if(this.item.consent==undefined) this.item.consent = {}
      if(this.item.coordinator==undefined) this.item.coordinator = {}
      if(this.item.imageDetail==undefined) this.item.imageDetail = {}
      else if(this.item.persons.length==0) this.item.persons = [{}]

      
    });
  } 
  mounted(){
    this.fetchData()
  } 
}
