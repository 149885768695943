import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    userId: "",
    loading: false,
    versionFromPackage: process.env.VUE_APP_VERSION,
    thai: true,
    fullName: "",
    role: {},
    userList:[],
    dataList:[],
    page:0
  },
  getters: {
    version: (state) => {
      return state.versionFromPackage
    },
    loading: (state) => {
      return state.loading
    },
    thai: (state) => {
      return state.thai
    },
    isAdmin: (state) => {
      //@ts-ignore
      let name:string = (state.role??{name:""}).name as string
      if(["ผู้ดูแลระบบ","นักพัฒนา"].includes(name)) return true
      return false
    }
  },
  mutations: {
    pageSet(state,payload){
      state.page = payload
    },
    toggleLanguage(state) {
      state.thai = !state.thai
    },
    setUser(state, payload) {
      state.userId = payload.id,
      state.fullName = payload.fullName
      state.role = payload.role_id
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setUserList(state, payload){
      state.userList = payload
    },
    setDataList(state, payload){
      //@ts-ignore
      state.dataList.push(payload)
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState(
    {
      key: "DCP221_20220617",
      paths: ["userId", "loading", "thai", "fullName", "role"]
    }
  )]
})
