
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import * as _ from "lodash"
import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {},
})
export default class RequestForm extends Vue{
  disabled:boolean = false
  doc: any = {
    items:[
      {
        name:"",
        level:""
      }
    ]
  };
  get thailand() {
    const thailandObject = require("@/data/thailand.json")
    return _.values(thailandObject)
  }
  mounted(){
    this.disabled = this.$route.path.search('/detail')!=-1
    if(this.$route.params.id!=undefined) this.fetchData()
  }
  pushItem() {
    let items: Array<any> = this.doc.items;
    if (!Array.isArray(items)) items = [];
    items.push({
      name: "",
      level: "",
    });
  }
  fetchData(){
    axios.get(`${ADDRESS}/request/get/${this.$route.params.id}`).then((res) => {
      console.log(res)
      this.doc = res.data.result;
    });
  }
  submitForm() {
    if(this.$route.params.id!=undefined) 
    axios
      .patch(`${ADDRESS}/request/update/${this.$route.params.id}`, {
        ...this.doc,
      })
      .then((response) => {
        const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-13)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
        this.$router.push(`/portal/request/item-list/${this.$route.params.id}`)
      });
    else
    axios
      .post(`${ADDRESS}/request/create`, {
        ...this.doc,
      })
      .then((response) => {
        console.log(response)
        const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-14)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
        this.$router.push(`/portal/request/`)
      });
    }
}

