import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import heritageMain from '@/views/portal/heritage/Main.vue'
import heritageList from '@/views/portal/heritage/List.vue'
import heritageListForEdit from '@/views/portal/heritage/ListforEdit.vue'
import heritageForm from '@/views/portal/heritage/Form.vue'
import requestMain from '@/views/portal/request/Main.vue'
import requestList from '@/views/portal/request/List.vue'
import requestForm from '@/views/portal/request/Form.vue'
import reqDocumentList from '@/views/portal/request/DocumentList.vue'
import itemList from '@/views/portal/request/ItemList.vue'
import DocumentMode from '@/views/portal/request/DocumentMode.vue'
import MPUpload from '@/views/portal/request/MPUpload.vue'
import MPPDF from '@/views/portal/request/MPPDF.vue'
import MP1Form from '@/views/portal/request/MP1.vue'
import MP1PDF from '@/views/portal/request/MP1PDF.vue'
import MP2Form from '@/views/portal/request/MP2.vue'
import MP2PDF from '@/views/portal/request/MP2PDF.vue'
import MP3Form from '@/views/portal/request/MP3.vue'
import MP3PDF from '@/views/portal/request/MP3PDF.vue'
import MediaMain from '@/views/portal/media/Main.vue'
import MediaList from '@/views/portal/media/List.vue'
import MediaForm from '@/views/portal/media/Form.vue'
import userMain from '@/views/portal/user/Main.vue'
import userForm from '@/views/portal/user/Form.vue'
import userList from '@/views/portal/user/List.vue'
import UserReset from '@/views/portal/user/Reset.vue'
import userGroup from '@/views/portal/user/Group.vue'
import CollectionMain from '@/views/portal/collection/Main.vue'
import CollectionList from '@/views/portal/collection/List.vue'
import CollectionForm from '@/views/portal/collection/Form.vue'
import CommunityMain from '@/views/portal/community/Main.vue'
import CommunityList from '@/views/portal/community/List.vue'
import CommunityForm from '@/views/portal/community/Form.vue'
import ContactMain from '@/views/portal/contact/Main.vue'
import ContactList from '@/views/portal/contact/List.vue'
import ContactForm from '@/views/portal/contact/Form.vue'
import ContactChat from '@/views/portal/contact/Chat.vue'
import RequestManagementMain from '@/views/portal/requestManagement/Main.vue'
import RequestManagementList from '@/views/portal/requestManagement/List.vue'
import RequestManagementForm from '@/views/portal/requestManagement/Form.vue'
import DocumentList from '@/views/portal/requestManagement/DocumentList.vue'
import DocumentForm from '@/views/portal/requestManagement/DocumentForm.vue'
import BlogMain from '@/views/portal/blog/Main.vue'
import BlogList from '@/views/portal/blog/List.vue'
import BlogForm from '@/views/portal/blog/Form.vue'
import ArticleMain from '@/views/portal/article/Main.vue'
import ArticleList from '@/views/portal/article/List.vue'
import ArticleForm from '@/views/portal/article/Form.vue'
import WebsiteManageMain from '@/views/portal/websiteManage/Main.vue'
import WebsiteManageList from '@/views/portal/websiteManage/List.vue'
import WebsiteManageForm from '@/views/portal/websiteManage/Form.vue'
import Field from '@/views/portal/websiteManage/Field.vue'
import Template from '@/views/portal/websiteManage/Layout.vue'
import TemplateForm from '@/views/portal/websiteManage/LayoutForm.vue'
import Typography from '@/views/portal/websiteManage/Typography.vue'
import Color from '@/views/portal/websiteManage/Color.vue'
import Other from '@/views/portal/websiteManage/Other.vue'
import Layout from '@/views/portal/websiteManage/Layout.vue'
import Structure from '@/views/portal/websiteManage/Structure.vue'
import StructureForm from '@/views/portal/websiteManage/StructureForm.vue'
import LayoutForm from '@/views/portal/websiteManage/LayoutForm.vue'
import AnalyticHeritage from '@/views/portal/analytic/Heritage.vue'
import ReportMain from '@/views/portal/report/Main.vue'
import ReportList from '@/views/portal/report/List.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'analytics',
    name: 'Heritage Analytic',
    component: AnalyticHeritage,
  },
  {
    path: 'heritage',
    name: 'Heritage Main',
    component: heritageMain,
    children: [
      {
        path: '',
        name: 'Heritage List',
        component: heritageList
      },
      {
        path: 'create',
        name: 'Heritage Form',
        component: heritageForm
      },
      {
        path: 'edit/:id',
        name: 'Heritage Edit',
        component: heritageForm
      },
      {
        path: 'listforEdit',
        name: 'Heritage List For Edit',
        component: heritageListForEdit
      },
    ]
  },
  {
    path: 'request',
    name: 'Request Main',
    component: requestMain,
    children: [
      {
        path: '',
        name: 'Request List',
        component: requestList
      },
      {
        path: 'create',
        name: 'Request Form',
        component: requestForm
      },
      {
        path: 'edit/:id',
        name: 'Request Edit',
        component: requestForm
      },
      {
        path: 'detail/:id',
        name: 'Request detail',
        component: requestForm
      },
      {
        path: 'item-list/:id',
        name: 'Item List',
        component: itemList
      },
      {
        path: 'mp/:num/:id',
        name: 'DocumentMode',
        component: DocumentMode
      },
      {
        path: 'upload/mp/:num/:id',
        name: 'MPUpload',
        component: MPUpload
      },
      {
        path: 'mp/pdf/:id',
        name: 'MPPDF',
        component: MPPDF
      },
      {
        path: 'mp1/:id',
        name: 'MP1FormWith',
        component: MP1Form
      },
      {
        path: 'mp1/pdf/:id',
        name: 'MP1PDF',
        component: MP1PDF
      },
      {
        path: 'mp2/:id',
        name: 'MP2FormWith',
        component: MP2Form
      },
      {
        path: 'mp2/pdf/:id',
        name: 'MP2PDF',
        component: MP2PDF
      },
      {
        path: 'mp3/:id',
        name: 'MP3FormWith',
        component: MP3Form
      },
      {
        path: 'mp3/pdf/:id',
        name: 'MP3PDF',
        component: MP3PDF
      },
      {
        path: 'mp1/:id/:index',
        name: 'MP1FormWithIndex',
        component: MP1Form
      },
      {
        path: 'mp2/:id/:index',
        name: 'MP2FormWithIndex',
        component: MP2Form
      },
      {
        path: 'mp3/:id/:index',
        name: 'MP3FormWithIndex',
        component: MP3Form
      },
      {
        path: 'mp/upload/:id/:index',
        name: 'MPUpload',
        component: MPUpload
      },
      {
        path: 'document',
        name: 'reqDocument List',
        component: reqDocumentList
      },
    ]
  },
  {
    path: 'media',
    name: 'Media Main',
    component: MediaMain,
    children: [
      {
        path: '',
        name: 'Media List',
        component: MediaList
      },
      {
        path: 'create',
        name: 'Media Form',
        component: MediaForm
      },
      {
        path: 'edit/:id',
        name: 'Media Edit',
        component: MediaForm
      }
    ]
  },
  {
    path: 'user',
    name: 'User Main',
    component: userMain,
    children: [
      {
        path: '',
        name: 'User List',
        component: userList
      },
      {
        path: 'create',
        name: 'User Form',
        component: userForm
      },
      {
        path: 'edit/:id',
        name: 'User Edit',
        component: userForm
      },
      {
        path: 'group',
        name: 'User Group',
        component: userGroup
      },
    ]
  },
  {
    path: 'collection',
    name: 'Collection Main',
    component: CollectionMain,
    children: [
      {
        path: '',
        name: 'Collection List',
        component: CollectionList
      },
      {
        path: 'create',
        name: 'Collection Form',
        component: CollectionForm
      },
      {
        path: 'edit/:id',
        name: 'Collection Edit',
        component: CollectionForm
      }
    ]
  },
  {
    path: 'community',
    name: 'Community Main',
    component: CommunityMain,
    children: [
      {
        path: '',
        name: 'Community List',
        component: CommunityList
      },
      {
        path: 'create',
        name: 'Community Form',
        component: CommunityForm
      },
      {
        path: 'edit/:id',
        name: 'Community Edit',
        component: CommunityForm
      }
    ]
  },
  {
    path: 'contact',
    name: 'Contact Main',
    component: ContactMain,
    children: [
      {
        path: '',
        name: 'Contact List',
        component: ContactList
      },
      {
        path: 'create',
        name: 'Contact Form',
        component: ContactForm
      },
      {
        path: 'chat/:id',
        name: 'Contact Chat',
        component: ContactChat
      }
    ]
  },
  {
    path: 'request-management',
    name: 'RequestManagement Main',
    component: RequestManagementMain,
    children: [
      {
        path: '',
        name: 'RequestManagement List',
        component: RequestManagementList
      },
      {
        path: 'create/:id',
        name: 'RequestManagement Form',
        component: RequestManagementForm
      },
      {
        path: 'edit/:id',
        name: 'RequestManagement Edit',
        component: RequestManagementForm
      },
      {
        path: 'document',
        name: 'Document List',
        component: DocumentList
      },
      {
        path: 'document/create',
        name: 'Document Form',
        component: DocumentForm
      },
      {
        path: 'document/edit/:id',
        name: 'Document Edit',
        component: DocumentForm
      },
    ]
  },
  {
    path: 'blog',
    name: 'Blog Main',
    component: BlogMain,
    children: [
      {
        path: '',
        name: 'Blog List',
        component: BlogList
      },
      {
        path: 'create',
        name: 'Blog Form',
        component: BlogForm
      },
      {
        path: 'edit/:id',
        name: 'Blog Edit',
        component: BlogForm
      },
    ]
  },
  {
    path: 'article',
    name: 'Article Main',
    component: ArticleMain,
    children: [
      {
        path: '',
        name: 'Article List',
        component: ArticleList
      },
      {
        path: 'create',
        name: 'Article Form',
        component: ArticleForm
      },
      {
        path: 'edit/:id',
        name: 'Article Edit',
        component: ArticleForm
      },
    ]
  },
  {
    path: 'display',
    name: 'WebsiteManage Main',
    component: WebsiteManageMain,
    children: [
      {
        path: '',
        name: 'WebsiteManage List',
        component: WebsiteManageList
      },
      {
        path: 'create',
        name: 'WebsiteManage Form',
        component: WebsiteManageForm
      },
      {
        path: 'edit/:id',
        name: 'WebsiteManage Edit',
        component: WebsiteManageForm
      },
      {
        path: 'color',
        name: 'WebsiteManage Color',
        component: Color
      },
      {
        path: 'other',
        name: 'WebsiteManage Other',
        component: Other
      },
      {
        path: 'field',
        name: 'WebsiteManage Field',
        component: Field
      },
      {
        path: 'layout',
        name: 'WebsiteManage Layout',
        component: Layout
      },
      {
        path: 'layout/form/:id',
        name: 'WebsiteManage Layout Form',
        component: LayoutForm
      },
      {
        path: 'structure',
        name: 'WebsiteManage Structure',
        component: Structure
      },
      {
        path: 'structure/form/:id',
        name: 'WebsiteManage Structure Form',
        component: StructureForm
      },
      {
        path: 'template',
        name: 'WebsiteManage Template',
        component: Template
      },
      {
        path: 'template/form/:name',
        name: 'WebsiteManage Template Form',
        component: TemplateForm
      },
      {
        path: 'typography',
        name: 'WebsiteManage Typography',
        component: Typography
      },
    ]
  },
  {
    path: 'report',
    name: 'Report Main',
    component: ReportMain,
    children: [
      {
        path: '',
        name: 'ReportList',
        component: ReportList
      },
    ]
  }
]
export default routes