
import { nextTick } from "vue";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  props: {
    page: Number,
    pages: Number,
    total: Number,
    show: Number,
    "onUpdate:modelValue": Function,
    modelValue: Object,
  },
})
export default class JMTablePaginate2 extends Vue {
  store = useStore();
  page!: number;
  pages!: number;
  total!: number;
  show!: number;
  limit: number = 5;
  updatePaginate(i: number) {
    if (i >= 1 && i <= this.pages) {
      this.$emit("change", i);
      // this.$route.query.page = i.toString()
      this.$router.push({ query: { page: i.toString() } });
      this.store.commit("pageSet", i);
    }
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  fristPage() {
    this.$router.push({ query: { page: 1 } });
    this.store.commit("pageSet", 1)
    this.$emit("change", 1);
  }
  lastPage() {
    this.$router.push({ query: { page: this.pages } });
    this.store.commit("pageSet", this.pages);
    this.$emit("change", this.pages);
  }
}
