declare var ExcelJS: any;
declare var saveAs: any;
export default class MyDocument {
  _blob = new Blob(["พบข้อผิดพลาดในการสร้างเอกสาร"], {
    type: "text/plain;charset=utf-8",
  });
  init:boolean = false
  public save(name: string) {
    saveAs(this._blob, this.init?name:"error.txt");
  }
  public get blob() {
    return this._blob
  }
}
