
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Article extends Vue{
  store = useStore()
  docs:Array<any> = []
  page =  1
  show = 20
  search:string = ""
  textSearch:string = ""
  pages: number = 0;
  total: number = 0;
  ADDRESS = ADDRESS
  get filtered(){
    return this.docs.filter(doc=>doc.title.search(this.textSearch) > -1)
  }
  get thai(){
    return this.store.getters.thai
  }
  // get filtered(){
  //   return this.docs
  // }
  getFirstItem(items:Array<any>){
    let last = ""
    for(const item of items){
      last = item.path
    }
    return last
  }

  stripHTML(text:string){
    try {
      return text.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, "").substring(0,175)+"..."
    } catch (error) {
      return text
    }
  }
  getFirstPhoto(items:Array<any>){
    for(const item of items){
      let split = item.path.split(".")
      let ext:string = split[split.length-1]
      if(['jpg','jpeg','png','gif','bmp'].includes(ext)){
        return item.path
      }
    }
    return "n/a"
  }
  fetchData() {
    this.docs = []
    let query = {
      search:{
        status: "เผยแพร่",
        $or:[
          { title: { $regex: this.search }},
        ]
      },
      page: this.page,
      limit: this.show,     
      sort: { _id: -1 },
    };
    console.log(query)
    axios
      .post(`${ADDRESS}/articles/paginate`, query)
      .then((response) => {
        console.log(response)
        this.docs = response.data.docs
        this.pages = response.data.totalPages;
      });
  }
  mounted() {
    this.fetchData();
  }
}
