import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5992484f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = {
  key: 0,
  class: "position-absolute",
  style: {"z-index":"999"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sketch = _resolveComponent("Sketch")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "color",
      style: _normalizeStyle(`background-color:${_ctx.color}`),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.active = !_ctx.active))
    }, null, 4),
    (_ctx.active)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Sketch, {
            modelValue: _ctx.colors,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.colors) = $event))
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, _ctx.close]
  ])
}