
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import Helper from "@/helpers"
import { useToast, POSITION } from "vue-toastification";
@Options({
  components: {},
})
export default class RequestList extends Vue{
  doc: any = {
    items:[],
    meetingDate: new Date,
    registrationDate: new Date,
  }
  Helper = Helper
  page =  1
  show = 99999
  showAdvance:boolean = false
  activeItem:any = {}
  fetchData(){
    axios.get(`${ADDRESS}/request/get/${this.$route.params.id}`).then((res) => {
      console.log(res)
      this.doc = res.data.result;
    });
  }
  submitForm() {
    if(this.$route.params.id!=undefined) 
    axios
      .patch(`${ADDRESS}/request/update/${this.$route.params.id}`, {
        ...this.doc,
      })
      .then((response) => {
        console.log(response)
        const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-25)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
        let btn = this.$refs.closemodal as HTMLElement;
        btn.click();
        this.$router.push(`/portal/request-management??page=${this.$route.query.page}`)
        if(this.doc.step == 3) {
        axios
          .post(`${ADDRESS}/process/request/to/heritage/`,{
            id:this.$route.params.id
          })
        }
      });
    else
    axios
      .post(`${ADDRESS}/request/create`, {
        ...this.doc,
      })
      .then((response) => {
        const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-26)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
        this.$router.push(`/portal/request-management/`)
      });
  }
  mounted(){
    if(this.$route.params.id!=undefined) 
      this.fetchData()
  }
}
