
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import { DateTime } from "luxon";
import * as _ from "lodash";

@Options({
  components: {},
})
export default class ListNational extends Vue {
  store = useStore();
  docs: Array<any> = [];
  page = 1;
  show = 20;
  branches: Array<any> = [];
  search: string = "";
  filter1: string = "";
  filter2: string = "";
  filter3: string = "";
  filter4: string = "";
  pages: number = 0;
  ADDRESS = ADDRESS;
  upload: any = {};
  Filter: boolean = false;
  yearArr: Array<number> = [];
  layouts: Array<any> = [];

  get budgetYear() {
    let year = DateTime.now().get("year") + 543;
    for (var i = 0; i < 14; i++) {
      if (i == 0) {
        this.yearArr.push(year);
      } else {
        this.yearArr.push(year - i);
      }
    }
    return this.yearArr;
  }
  get thai() {
    return this.store.getters.thai;
  }
  get thailand() {
    const thailandObject = require("@/data/thailand.json");
    return _.values(thailandObject);
  }
  getFirstPhoto(items: Array<any>) {
    for (const item of items) {
      let split = item.path.split(".");
      let ext: string = split[split.length - 1];
      if (["jpg", "jpeg", "png", "gif", "bmp"].includes(ext)) {
        return item.path;
      }
    }
    return "n/a";
  }

  showFilter() {
    this.Filter = !this.Filter;
  }
  fetchLayout() {
    axios.get(`${ADDRESS}/layout/get-by-name/national`).then((response) => {
      console.log(response);
      try {
        this.layouts = response.data.result.elementSingle;
      } catch (error) {
        this.layouts = [
          {
            name: "banner",
            elementType: "image",
            image: "/img/banner.317e01d1.png",
            _id: "62786fcdc7f286658d6ba7e8",
          },
          {
            name: "intro",
            elementType: "image",
            image: "/img/text.18d0b660.jpg",
            _id: "62786fcdc7f286658d6ba7e9",
          },
          {
            name: "heritage",
            elementType: "image",
            image: "/img/heritage-list.bf635350.png",
            _id: "62786fcdc7f286658d6ba7ea",
          },
        ];
      }
    });
  }
  fetchData() {
    let query = {
      page: this.page,
      limit: this.show,
      search: {
        level: "ระดับชาติ",
        status: { $in: ["เผยแพร่", "ไฮไลท์"] },
        branch: this.filter1 == "" ? undefined : this.filter1,
        inscribedYear: this.filter2 == "" ? undefined : this.filter2,
        zone: this.filter3 == "" ? undefined : this.filter3,
        province: this.filter4 == "" ? undefined : this.filter4,
        $or: [
          {
            name: {
              $regex: this.search,
            },
          },
          {
            nameEN: {
              $regex: this.search, $options: 'i'
            },
          },
        ],
      },
    };
    console.log(query);
    axios.post(`${ADDRESS}/heritages/paginate`, query).then((response) => {
      console.log(response);
      this.docs = response.data.docs;
      this.pages = response.data.totalPages;
      this.branches = response.data.branches;
    });
  }
  getBranchCount(str: string) {
    try {
      return this.branches.find((branch) => branch._id === str).count;
    } catch (error) {
      return 0;
    }
  }
  getBranchTotal() {
    try {
      return this.branches
        .map((branch) => branch.count)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return 0;
    }
  }

  stripHTML(text: string) {
    return (
      text
        .replace(/<\/?[^>]+(>|$)/g, "")
        .replace(/&nbsp;/g, "")
        .substring(0, 175) + "..."
    );
  }
  mounted() {
    this.fetchData();
    this.fetchLayout();
  }
}
