
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import * as _ from "lodash";
import PDFViewer from "pdf-viewer-vue";
import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {
    PDFViewer,
  },
})
export default class RequestForm extends Vue {
  doc: any = {
    type: "ebook",
    status: "",
    uploadItems:[]
  };
  address = ADDRESS;
  isHidden = true;
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  fetchData() {
    axios.get(`${ADDRESS}/media/get/${this.$route.params.id}`).then((res) => {
      console.log(res);
      this.doc = res.data.result;
    });
  }
  submitForm(n:boolean) {
    // if(n) {
    //   this.doc.status = "แบบร่าง";
    // } else {
    //   if (!this.doc.isHighlight || this.doc.status == "") {
    //     this.doc.status = "ไม่เผยแพร่";
    //   } else {
    //     this.doc.status = this.doc.status;
    //   }
    // }
    console.log('status',this.doc.status);
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${ADDRESS}/media/update/${this.$route.params.id}`, {
          ...this.doc,
        })
        .then((response) => {
          console.log('response1',response);
          const toast = useToast();
          toast.success("บันทึกข้อมูลแล้ว (ref:S-11)", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
          let savedDoc = response.data.result
          console.log({savedDoc})
          // if (n) this.$router.push(`/portal/media/edit/${savedDoc._id}`);
          // else this.$router.push(`/portal/media`);
        });
    } else {
      axios
        .post(`${ADDRESS}/media/create`, {
          ...this.doc,
        })
        .then((response) => {
          console.log('response2',response);
          const toast = useToast();
          toast.success("บันทึกข้อมูลแล้ว (ref:S-12)", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
          let savedDoc = response.data
          console.log({savedDoc})
          // if (n) this.$router.push(`/portal/media/edit/${savedDoc._id}`);
          // else this.$router.push(`/portal/media`);
        });
    }
  }
}
