
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { confirmAction, time } from "@/decorators"
import { DateTime } from "luxon";
import ExcelJS from "exceljs"
import { saveAs } from 'file-saver';
@Options({
  components: {},
})
export default class ReportList extends Vue{
  province:Array<any> = []
  national:Array<any> = []
  international:Array<any> = []

  ExportProvince(){
    console.log("generateExcel");
    let workbook = new ExcelJS.Workbook()
    let worksheet = workbook.addWorksheet('Report')
    worksheet.addRow(['รายงานมรดกภูมิปัญญาระดับจังหวัด'])
    worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.mergeCells('A1:H1')
    worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("A2").value = `ลำดับ`
    worksheet.getCell("B2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("B2").value = `รหัสคำขอ`
    worksheet.getCell("C2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("C2").value = `ชื่อรายการ`
    worksheet.getCell("D2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("D2").value = `สาขา`
    worksheet.getCell("E2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("E2").value = `ประเภท`
    worksheet.getCell("F2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("F2").value = `ระดับการขึ้นบัญชี`
    worksheet.getCell("G2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("G2").value = `สถานะการคงอยู่`
    worksheet.getCell("H2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("H2").value = `วันที่ปรับปรุงข้อมูลล่าสุด`
    //width
    worksheet.getColumn("A").width = 5
    worksheet.getColumn("B").width = 14
    worksheet.getColumn("C").width = 100
    worksheet.getColumn("D").width = 15
    worksheet.getColumn("E").width = 15
    worksheet.getColumn("F").width = 17
    worksheet.getColumn("G").width = 26
    worksheet.getColumn("H").width = 20

    this.province.forEach((el: any, i: number) => {
      worksheet.addRow([
        i + 1,
        el.code,
        el.name,
        el.branch??"-",
        el.type,
        "ระดับจังหวัด",
        el.persist,
        new Date(el.createdAt).toThaiShort()
      ])
    })

    worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn > 1) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })
    workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "มรดกภูมิปัญญาระดับจังหวัด.xlsx");
    });
  }
  ExportNational() {
    console.log("generateExcel");
    let workbook = new ExcelJS.Workbook()
    let worksheet = workbook.addWorksheet('Report')
    worksheet.addRow(['รายงานมรดกภูมิปัญญาระดับชาติ'])
    worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.mergeCells('A1:H1')
    worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("A2").value = `ลำดับ`
    worksheet.getCell("B2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("B2").value = `รหัสคำขอ`
    worksheet.getCell("C2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("C2").value = `ชื่อรายการ`
    worksheet.getCell("D2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("D2").value = `สาขา`
    worksheet.getCell("E2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("E2").value = `ประเภท`
    worksheet.getCell("F2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("F2").value = `ระดับการขึ้นบัญชี`
    worksheet.getCell("G2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("G2").value = `สถานะการคงอยู่`
    worksheet.getCell("H2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("H2").value = `วันที่ปรับปรุงข้อมูลล่าสุด`
    //width
    worksheet.getColumn("A").width = 5
    worksheet.getColumn("B").width = 14
    worksheet.getColumn("C").width = 100
    worksheet.getColumn("D").width = 15
    worksheet.getColumn("E").width = 15
    worksheet.getColumn("F").width = 17
    worksheet.getColumn("G").width = 26
    worksheet.getColumn("H").width = 20

    this.national.forEach((el: any, i: number) => {
      worksheet.addRow([
        i + 1,
        el.code,
        el.name,
        el.branch??"-",
        el.type,
        "ระดับชาติ",
        el.persist,
        new Date(el.createdAt).toThaiShort()
      ])
    })

    worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn > 1) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })

    workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "มรดกภูมิปัญญาระดับชาติ.xlsx");
    });
  }
  ExportInternational() {
    console.log("generateExcel");
    let workbook = new ExcelJS.Workbook()
    let worksheet = workbook.addWorksheet('Report')
    worksheet.addRow([`รายงานมรดกภูมิปัญญาระดับมนุษยชาติ`])
    worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.mergeCells('A1:H1')
    worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("A2").value = `ลำดับ`
    worksheet.getCell("B2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("B2").value = `รหัสคำขอ`
    worksheet.getCell("C2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("C2").value = `ชื่อรายการ`
    worksheet.getCell("D2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("D2").value = `สาขา`
    worksheet.getCell("E2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("E2").value = `ประเภท`
    worksheet.getCell("F2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("F2").value = `ระดับการขึ้นบัญชี`
    worksheet.getCell("G2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("G2").value = `สถานะการคงอยู่`
    worksheet.getCell("H2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("H2").value = `วันที่ปรับปรุงข้อมูลล่าสุด`
    //width
    worksheet.getColumn("A").width = 5
    worksheet.getColumn("B").width = 14
    worksheet.getColumn("C").width = 100
    worksheet.getColumn("D").width = 15
    worksheet.getColumn("E").width = 15
    worksheet.getColumn("F").width = 17
    worksheet.getColumn("G").width = 26
    worksheet.getColumn("H").width = 20

    this.international.forEach((el: any, i: number) => {
      worksheet.addRow([
        i + 1,
        el.code,
        el.name,
        el.branch??"-",
        el.type,
        "ระดับมนุษยชาติ",
        el.persist,
        new Date(el.createdAt).toThaiShort()
      ])
    })

    worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn > 1) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })

    workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "มรดกภูมิปัญญาระดับมนุษยชาติ.xlsx");
    });
  }
  getReportProvince() {
    axios.get(`${ADDRESS}/heritages/province/list`).then((response) => {
      console.log('provice',response)
      this.province = response.data
    });
  }
  getReportNational() {
    axios.get(`${ADDRESS}/heritages/national/list`).then((response) => {
      console.log('national',response)
      this.national = response.data
    });
  }
  getReportInternational() {
    axios.get(`${ADDRESS}/heritages/international/list`).then((response) => {
      console.log('international',response)
      this.international = response.data
    });
  }
  mounted() {
    this.getReportProvince();
    this.getReportNational();
    this.getReportInternational();
  }
}
