
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings"
import { useToast, POSITION } from "vue-toastification";
@Options({
  components: {},
})

export default class UserForm extends Vue {
  doc:any = {}
  usernameInvalid:boolean = false
  showPassword:boolean = true
  sectors:Array<any> = []

  show() {
    this.showPassword = !this.showPassword
    console.log('showPassword',this.showPassword)
  }
  submitForm() {
    let { password, repeatPassword } = this.doc
    if(password === repeatPassword){
    axios
      .post(`${ADDRESS}/user/register`, this.doc)
      .then((response) => {
        // console.log(this.doc)
        // let doc = response.data
        if(response.data.status === "error"){          
          if(response.data.error.code===11000){
            this.usernameInvalid = true
          } else {
            alert("พบปัญหาในการลงทะเบียน: " + JSON.stringify(response.data.error.message))
          }
          console.log(response.data)
        } else {
          // let data = response.data;  
          const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-34)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
          // console.log('res',response.data)
          // console.log(this.doc)
          console.log(response.data.data)
          this.doc = response.data.data;
          this.$router.push(`/registration/verify/${this.doc._id}`)
        }
      });
    } else {
      alert("ตรวจสอบรหัสผ่าน และ ยืนยันรหัสผ่านอีกครั้ง!")
    }
  }
  async fetchData(){
    const response = await axios.get(`${ADDRESS}/setting/get-by-name/หน่วยงานที่ลงทะเบียน`)
    console.log("response หน่วยงาน",response.data)
    this.sectors = response.data.result.member
  }
  mounted(){
    //@ts-ignore
    this.$refs.pdpaBtn.click()
    this.fetchData();
  }
}
