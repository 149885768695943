
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import * as _ from "lodash";
import { Watch } from "vue-property-decorator";
import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {},
})
export default class HeritageForm extends Vue {
  doc: any = {
    name: "",
    title: "",
    category: [],
    status: "",
    isHighlight: false,
    uploadItems:[]
  };
  get today() {
    return new Date();
  }

  submitForm(n: boolean) {
    console.log(n)
    console.log(this.doc.status)
    if(n) {
      this.doc.status = "แบบร่าง";
    } else {
      if (this.doc.status == "") {
        this.doc.status = "เผยแพร่";
      } else {
        this.doc.status = this.doc.status;
      }
    }
    console.log(this.doc.status)
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${ADDRESS}/article/update/${this.$route.params.id}`, {
          ...this.doc,
        })
        .then((response) => {
          const toast = useToast();
          toast.success("แก้ไขข้อมูลแล้ว!", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
          let savedDoc = response.data.result
          console.log({savedDoc})
          // if (n) this.$router.push(`/portal/article/edit/${savedDoc._id}`);
          // else this.$router.push(`/portal/article`);
          this.$router.push(`/portal/article`);
        });
    } else {
      axios
        .post(`${ADDRESS}/article/create`, {
          ...this.doc,
        })
        .then((response) => {
          console.log('response',response);
          const toast = useToast();
          toast.success("บันทึกข้อมูลแล้ว (ref:S-1)", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
          let savedDoc = response.data
          console.log({savedDoc})
          // if (n) this.$router.push(`/portal/article/edit/${savedDoc._id}`);
          // else this.$router.push(`/portal/article`);
          this.$router.push(`/portal/article`);
        });
    }
  }
  fetchData() {
    axios.get(`${ADDRESS}/article/get/${this.$route.params.id}`).then((res) => {
      console.log("re", res);
      this.doc = res.data.result;
    });
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
}
