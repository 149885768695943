
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings"
@Options({
  components: {},
})

export default class UserForm extends Vue {
  doc:any = {}
  submitForm() {
      axios
        .post(`${ADDRESS}/user/reset`, {
          email: this.doc.email
        })
        .then((response) => {
          console.log(response.data.status)
          if(response.data.status === "error"){
            alert("ไม่พบอีเมลในระบบ")
          } else {
            alert("ตรวจสอบอีเมลถูกต้อง รีเซ็ตรหัสผ่านของคุณ");
          }
        });
  }
}
