
import { Options, Vue } from "vue-class-component";
import TopNavigation from "@/components/TopNavigation.vue"
import { useStore } from "vuex";
import axios from "axios";
import ADDRESS from "@/settings";
import JMLoading from "@/components/JMLoading.vue"
import { Watch } from "vue-property-decorator";

@Options({
  components: {
    TopNavigation,
    JMLoading
  },
})
export default class Public extends Vue {
  store = useStore()
  count = 0;
  colorPrimary = "#3E3757";
  colorSecondary = "#E6DBCF";
  colorLight = "#F9F9FB";
  colorDark = "#71350D";
  colorHighlight = "#F4DBB8";
  colorBackground = "#F5F5F5";
  colorBoxBackground = "#00FFFF"
  fontSizeH1 = 18;
  fontSizeH2 = 18;
  fontSizeH3 = 18;
  fontSizeH4 = 18;
  fontSizeH5 = 18;
  fontSizeH6 = 18;
  fontSizePH = 18;
  fontSizeLK = 18;
  fontWeightH1 = 'bold'
  fontWeightH2 = 'bold'
  fontWeightH3 = 'bold'
  fontWeightH4 = 'bold'
  fontWeightH5 = 'bold'
  fontWeightH6 = 'bold'
  fontWeightPH = 'normal';
  fontWeightLK = 'normal';
  fontColorH1 = '#000000'
  fontColorH2 = '#000000'
  fontColorH3 = '#000000'
  fontColorH4 = '#000000'
  fontColorH5 = '#000000'
  fontColorH6 = '#000000'
  fontColorPH = '#000000';
  fontColorLK = '#000000';
  fontH1 = 'Kanit'
  fontH2 = 'Kanit'
  fontH3 = 'Kanit'
  fontH4 = 'Kanit'
  fontH5 = 'Kanit'
  fontH6 = 'Kanit'
  fontPH = 'Prompt';
  fontLK = 'Prompt';
  fontHoverLK = '#000000';
  doc:any = {}
  hide:boolean = false
  @Watch("$route.path")
  updateCount(){
    // this.count++
  }
  get cssProps() {
    return {
      '--color-primary': `${this.colorPrimary}`,
      '--color-secondary': `${this.colorSecondary}`,
      '--color-light': `${this.colorLight}`,
      '--color-dark': `${this.colorDark}`,
      '--color-highlight': `${this.colorHighlight}`,
      '--color-background': `${this.colorBackground}`,
      '--color-box-background': `${this.colorBoxBackground}`,
      '--font-size-h1': `${this.fontSizeH1}px`,
      '--font-size-h2': `${this.fontSizeH2}px`,
      '--font-size-h3': `${this.fontSizeH3}px`,
      '--font-size-h4': `${this.fontSizeH4}px`,
      '--font-size-h5': `${this.fontSizeH5}px`,
      '--font-size-h6': `${this.fontSizeH6}px`,
      '--font-size-ph': `${this.fontSizePH}px`,
      '--font-size-lk': `${this.fontSizeLK}px`,

      '--font-weight-h1': `${this.fontWeightH1}`,
      '--font-weight-h2': `${this.fontWeightH2}`,
      '--font-weight-h3': `${this.fontWeightH3}`,
      '--font-weight-h4': `${this.fontWeightH4}`,
      '--font-weight-h5': `${this.fontWeightH5}`,
      '--font-weight-h6': `${this.fontWeightH6}`,
      '--font-weight-ph': `${this.fontWeightPH}`,
      '--font-weight-lk': `${this.fontWeightLK}`,

      '--font-color-h1': `${this.fontColorH1}`,
      '--font-color-h2': `${this.fontColorH2}`,
      '--font-color-h3': `${this.fontColorH3}`,
      '--font-color-h4': `${this.fontColorH4}`,
      '--font-color-h5': `${this.fontColorH5}`,
      '--font-color-h6': `${this.fontColorH6}`,
      '--font-color-ph': `${this.fontColorPH}`,
      '--font-color-lk': `${this.fontColorLK}`,

      '--font-h1': `${this.fontH1}`,
      '--font-h2': `${this.fontH2}`,
      '--font-h3': `${this.fontH3}`,
      '--font-h4': `${this.fontH4}`,
      '--font-h5': `${this.fontH5}`,
      '--font-h6': `${this.fontH6}`,
      '--font-ph': `${this.fontPH}`,
      '--font-lk': `${this.fontLK}`,
      
      '--font-hover-lk': `${this.fontHoverLK}`,
    }
  }
  mounted(){
    this.fetchColor()
  }

  getColor(i:number){
    try {
      return this.doc.member[i]
    } catch(error){
      return {}
    }
  }

  fetchFontSize(){
    axios.get(`${ADDRESS}/setting/get-by-name/typography`).then((response) => {
      // console.log("huh?",response.data.result)
      if(!this.store.state.dataList.includes('fontSize')) {
        this.store.commit("setDataList",'fontSize')
      }
      // this.store.commit("setDataList",'fontSize')
      if(response.data.result.member.length > 0){
        let doc = response.data.result.member
        console.table(doc)
        this.fontSizePH = doc[0].number1
        this.fontSizeLK = doc[1].number1
        this.fontSizeH1 = doc[2].number1
        this.fontSizeH2 = doc[3].number1
        this.fontSizeH3 = doc[4].number1
        this.fontSizeH4 = doc[5].number1
        this.fontSizeH5 = doc[6].number1
        this.fontSizeH6 = doc[7].number1

        this.fontWeightPH = doc[0].text2
        this.fontWeightLK = doc[1].text2
        this.fontWeightH1 = doc[2].text2
        this.fontWeightH2 = doc[3].text2
        this.fontWeightH3 = doc[4].text2
        this.fontWeightH4 = doc[5].text2
        this.fontWeightH5 = doc[6].text2
        this.fontWeightH6 = doc[7].text2

        this.fontColorPH = doc[0].text3
        this.fontColorLK = doc[1].text3
        this.fontColorH1 = doc[2].text3
        this.fontColorH2 = doc[3].text3
        this.fontColorH3 = doc[4].text3
        this.fontColorH4 = doc[5].text3
        this.fontColorH5 = doc[6].text3
        this.fontColorH6 = doc[7].text3

        this.fontPH = doc[0].text1
        this.fontLK = doc[1].text1
        this.fontH1 = doc[2].text1
        this.fontH2 = doc[3].text1
        this.fontH3 = doc[4].text1
        this.fontH4 = doc[5].text1
        this.fontH5 = doc[6].text1
        this.fontH6 = doc[7].text1

        this.fontHoverLK = doc[1].text4
      } else {
      }
      this.$forceUpdate()
    });
    // console.log('store',this.store.state.dataList)
  }

  async fetchColor(){
    axios.get(`${ADDRESS}/setting/get-by-name/color`).then((response) => {
      console.log(response.data.result)
      if(!this.store.state.dataList.includes('color')) {
        this.store.commit("setDataList",'color')
      }
      // this.store.commit("setDataList",'color')
      if(response.data.result.member.length > 0){
        this.doc = response.data.result
      } else {
        this.doc._id = response.data.result._id
      }
      this.$forceUpdate()
      try { this.colorPrimary = this.doc.member[0].text1 } catch(error){ console.log('color error 1', error) }
      try { this.colorSecondary = this.doc.member[1].text1 } catch(error){ console.log('color error 2', error) }
      try { this.colorLight = this.doc.member[2].text1 } catch(error){ console.log('color error 3', error) }
      try { this.colorDark = this.doc.member[3].text1 } catch(error){ console.log('color error 4', error) }
      try { this.colorHighlight = this.doc.member[4].text1 } catch(error){ console.log('color error 5', error) }
      try { this.colorBackground = this.doc.member[5].text1 } catch(error){ console.log('color error 6', error) }
      try { this.colorBoxBackground = this.doc.member[6].text1 } catch(error){ console.log('color error 7', error); this.colorBoxBackground = "#FFFFFF" }
      
    });
    await this.fetchFontSize()
  }
}

