import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import "@/extensions/date.extension";
import "@/extensions/string.extension";
import "@/extensions/number.extension";

import "@popperjs/core";
import "../node_modules/bootstrap/dist/js/bootstrap";
import Toast from "vue-toastification";

import router from './router'
import store from './store'

import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'vue-select/dist/vue-select.css';
import "vue-toastification/dist/index.css";

import JMSelect from "@/components/JMSelect.vue";
import JMSwitch from "@/components/JMSwitch.vue";
import JMUpload from "@/components/JMUpload.vue";
import JMMap from "@/components/JMMap.vue";
import JMUploads from "@/components/JMUploadImages.vue";
import JMUploadPDF from "@/components/JMUploadPDF.vue";
import JMUploadsNoPreview from "@/components/JMUploads.vue";
import JMCalendar from "@/components/JMCalendar.vue";
import JMUser from "@/components/JMUser.vue";
import JMRichText from "@/components/JMRichText.vue"
import JMPaginate from "@/components/JMPaginate.vue";
import JMPaginate2 from "@/components/JMPaginate2.vue";
import JMPubPaginate from "@/components/JMPubPaginate.vue";
import Footer from "@/components/Footer.vue";
import JMEntriesItem from "@/components/JMEntriesItem.vue";
import JMHeritage from "@/components/JMHeritage.vue";
import JMCommunity from "@/components/JMCommunity.vue";
import vSelect from 'vue-select'


const app = createApp(App)
  .use(store)
  .use(Toast)
  .use(router)

// app.directive('click-outside', {
//   created: (el, binding, vnode) => {

//     // console.dir(el)
//     // console.log('binding', binding)
//     el.clickOutsideEvent = (event: any) => {
//       console.log('event', event)
//       if (!(el === event.target || el.contains(event.target))) {
//         try {
//           //@ts-ignore
//           console.dir(binding.instance["onClickOutside"]())
//         } catch (error) {
//           console.log(error)
//         }
//       }
//     };
//     document.addEventListener("click", el.clickOutsideEvent);
//   },
//   updated: (el, binding) => {
//     // document.removeEventListener("click", clickOutsideEvent);
//   },
// })

app.component('QuillEditor', QuillEditor)
app.component("JMCalendar", JMCalendar);
app.component('v-select', vSelect)
app.component('JMSelect', JMSelect)
app.component('JMSwitch', JMSwitch)
app.component('JMUser', JMUser)
app.component('JMMap', JMMap)
app.component('JMUpload', JMUpload)
app.component('JMUploads', JMUploads)
app.component('JMUploadPDF', JMUploadPDF)

app.component('JMUploadsNoPreview', JMUploadsNoPreview)
app.component('JMRichText', JMRichText)
app.component('JMPaginate', JMPaginate)
app.component('JMPaginate2', JMPaginate2)
app.component('JMPubPaginate', JMPubPaginate)
app.component('Footer', Footer)
app.component("JMEntriesItem", JMEntriesItem);
app.component("JMHeritage", JMHeritage);
app.component("JMCommunity", JMCommunity);
app.mount('#app')

