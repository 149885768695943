
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  components: {},
  prop: {
    show : Boolean
  }
})
export default class JMLoading extends Vue {
  show: boolean = true;
  store = useStore()
  restartAnimation(str: string) {
    var el = document.getElementById(str);
    el!.style.animation = "none";
    el!.offsetHeight; /* trigger reflow */
    //@ts-ignore
    el!.style.animation = null;
  }
  finishAnimation(str: string) {
    var el = document.getElementById(str);
    el!.style.display = "none";
    el!.style.width = "0";
    el!.style.height = "0";
  }

  restart() {
    // this.restartAnimation("dot-1");
    this.restartAnimation("dot-2");
    this.restartAnimation("dot-3");
    this.restartAnimation("dot-4");
  }

  loopRestart() {
    console.log("restarting...");
    this.restart();
    setTimeout(() => {
      this.loopRestart();
    }, 1800);
  }

  hide() {
    // setTimeout(() => {
    //   this.show = false;
    // }, 3400);
    let hide = this.store.state.dataList.length
    if(hide = 4 || hide > 4) {
      setTimeout(() => {
        this.show = false;
      }, 3400);
    } else {
      this.show = true;
    }
  }

  mounted() {
    this.loopRestart();
    this.hide();
    console.log(this.store.state.dataList)
  }
}
