
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";

@Options({
  components: {},
})
export default class Footer extends Vue {

  get today() {
    return DateTime.now().set({year:2022}).toFormat('yyyy')
  }
}
