import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import Public from '../views/Public.vue'
import Login from '../views/Login.vue'
import Debug from '../views/debug/Debug.vue'
import Portal from '../views/Portal.vue'
import Verify from '../views/Reset.vue'
import Reset from '../views/Reset.vue'
import Reset2 from '../views/Reset2.vue'
import { io } from "socket.io-client"

import PublicRoutes from "./public"
import PortalRoutes from "./portal"
import { SOCKET_ADDRESS } from '@/settings'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [

  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/reset2',
    name: 'Reset',
    component: Reset
  },
  {
    path: '/reset/verificationCode=:verify&_id=:id',
    name: 'Reset2',
    component: Reset2
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Public',
    component: Public,
    children: PublicRoutes
  },
  {
    path: '/portal',
    name: 'Portal',
    component: Portal,
    children: PortalRoutes
  },
  {
    path: '/debug',
    name: 'Debug',
    component: Debug
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path.search("/portal") !== -1) {
    console.log("tototo")
    const socket = io(SOCKET_ADDRESS, { path: "/api/socket" })
    socket.emit("routeTo", {
      path: to.path,
      page: to.name,
      fullName: store.state.fullName,
      id: store.state.userId,
      dt: new Date()
    })
  }
  next()
})

export default router
