
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import * as _ from "lodash";
import { Watch } from "vue-property-decorator";
import Chart from "chart.js/auto";
import { DateTime } from "luxon";

@Options({
  components: {},
})
export default class HeritageForm extends Vue {
  doc: any = {};
  top10: Array<any> = [];
  last7days: Array<any> = [];
  initChartTop10() {
    var element = document.getElementById("chart-top-10") as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.top10.map((item) => item.name),
        datasets: [
          {
            label: "จำนวนการเข้าชม",
            data: this.top10.map((item) => item.views),
            backgroundColor: ["rgba(54, 162, 235, 0.7)"],
            borderColor: ["rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }
  initChartHistory() {
    for (const doc of this.last7days) {
      let items = doc.items as Array<any>;
      var element = document.getElementById(
        `chart-for-item-${doc._id}`
      ) as HTMLCanvasElement;
      var ctx = element.getContext("2d") as CanvasRenderingContext2D;
      const myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: items.map((item) => {
            let dt = DateTime.fromISO(item.dt)
              .reconfigure({ outputCalendar: "buddhist" })
              .setLocale("th")
              .toFormat("dd LLL yy");
            return dt
          }),
          datasets: [
            {
              label: "จำนวนการเข้าชม",
              data: items.map((item) => item.views),
              backgroundColor: ["rgba(54, 162, 235, 0.7)"],
              borderColor: ["rgba(54, 162, 235, 1)"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }
  }

  fetchData() {
    axios.post(`${ADDRESS}/analytics/heritages`).then((res) => {
      // console.log("re", res);
      this.doc = res.data;
      this.top10 = this.doc.top10;
      this.last7days = this.doc.last7days.slice(0, 10);
      this.initChartTop10();
      setTimeout(() => {
        this.initChartHistory();
      }, 150);
    });
  }
  mounted() {
    this.fetchData();
  }
}
