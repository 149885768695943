
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import draggable from "vuedraggable";
@Options({
  props: {
    text: String,
    // value:String,
    // name:String,
    // disabled: Boolean,
  },
  Component: {
    draggable,
  },
})
export default class News extends Vue {
  name!: string;
  value!: string;
  val: string = "";
  ADDRESS = ADDRESS;
  news: Array<any> = [];
  search: string = "";
  page = 1;
  getFirstPhoto(items: Array<any>) {
    for (const item of items) {
      let split = item.path.toLowerCase().split(".");
      let ext: string = split[split.length - 1];
      if (["jpg", "jpeg", "png", "gif", "bmp"].includes(ext)) {
        return item.path;
      }
    }
    return "n/a";
  }
  fetchNews() {
    let query = {
      search: {
        status: "เผยแพร่",
        isHighlight: true,
      },
      page: this.page,
      limit: 4,
      sort: { code: -1 },
    };
    console.log(query);
    axios.post(`${ADDRESS}/blogs/paginate`, query).then((response) => {
      console.log(response);
      this.news = response.data.docs;
    });
  }
  mounted() {
    this.fetchNews();
  }
}
