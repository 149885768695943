
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings"
import { useToast, POSITION } from "vue-toastification";
@Options({
  components: {},
})

export default class UserForm extends Vue {
  doc:any = {}
  activities:Array<any> = []
   showPassword:boolean = true
  accesses:Array<any> = []
  disabled:boolean = false
  docs:Array<any> = []
  statusOptions:Array<any> = [
    { icon: "fa-solid fa-circle", value:"ใช้งานได้", color:'green' },
    { icon: "fa-solid fa-circle-minus", value:"ระงับ", color:'red' },
  ]
show() {
    this.showPassword = !this.showPassword
   
  }
  fetchAccessList(){
    axios
      .get(`${ADDRESS}/usergroups/list`)
      .then((response) => {
        console.log('list', response)
        this.accesses = response.data.result
      });
  }
  submitForm() {
      console.log(this.$route.params.id)
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${ADDRESS}/user/update/${this.$route.params.id}`, {
          ...this.doc,
          // isNew: false
          })
        .then((response) => {
          const toast = useToast();
    toast.success("แก้ไขข้อมูลแล้ว!", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
          this.$router.push('/portal/user')
        });
    } else {
      axios
        .post(`${ADDRESS}/user/create`, {
          ...this.doc,
          // isNew: true
        })
        .then((response) => {
          console.log(response)
          const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-27)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
          this.$router.push('/portal/user')
        });
    }
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/user/get/${this.$route.params.id}`)
      .then((response) => {
        this.doc = response.data.result
        this.fetchActivity()
      });
    this.fetchAccessList()
  }
  fetchActivity() {
    let query = {
      search: {},
      page: 1,
      limit: 30,
      sort:{
        dt:-1
      }
    }
    axios
      .post(`${ADDRESS}/activities/paginate`, query)
      .then((response) => {
        console.log(response.data)
        this.activities = response.data.docs
      });
  }
  fetchList(){
    axios
      .get(`${ADDRESS}/usergroups/list`)
      .then((response) => {
        console.log('list', response)
        this.docs = response.data.result
      });
  }
  mounted(){
    if(this.$route.params.id != undefined){
      this.disabled = true
      this.fetchData();
      // this.getActivity();
    }
    else this.disabled = false
    this.fetchList()
  }
}
