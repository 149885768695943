
import { Options, Vue } from "vue-class-component";
import L, { Icon } from "leaflet";
import axios from "axios";
import ADDRESS from "@/settings";
import "leaflet/dist/images/marker-shadow.png";
import * as _ from "lodash";
import { Watch } from "vue-property-decorator";
import { reject } from "lodash";

@Options({
  components: {},
})
export default class Contact extends Vue {
  doc: any = {
    name: "",
    code: "ระบบจะสร้างรหัสให้อัตโนมัติ",
    from: {
      sector:"",
    },
    type:"",
    location: {
      type: "Point",
      coordinates: [100.57296924653227, 13.766447852042793],
    },
  };
  location = {
    type: "Point",
    coordinates: [100.57296924653227, 13.766447852042793],
  };
  isHidden = true;
  mymap: any;
  layer: any;
  zoom = 17;
  layouts: Array<any> = [];
  mounted() {
    this.initPage();
  }
  async initPage() {
    await this.fetchLayout();
    this.initMap();
    this.renderMap();
  }
  fetchLayout() {
    return new Promise((resolve, reject) => {
      axios.get(`${ADDRESS}/layout/get-by-name/contact`).then((response) => {
        console.log(response);
        try {
          this.layouts = response.data.result.elementSingle;
        } catch (error) {
          this.layouts = [
            {
              name: "map",
              elementType: "image",
              image: "/img/map.9afe15a1.png",
              _id: "6278787ac7f286658d6ba846",
            },
            {
              name: "contact",
              elementType: "image",
              image: "/img/contact.24e943b9.png",
              _id: "6278787ac7f286658d6ba847",
            },
          ];
        } finally {
          resolve("done!");
        }
      });
    });
  }
  submit() {
    console.log("ok");

    axios.post(`${ADDRESS}/contact/create`, this.doc).then((response) => {
      alert("ส่งข้อมูลให้ผู้ดูแลระบบแล้ว!");
      // this.$router.push(`/portal/contact`)
    });
  }
  initMap() {
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }
  renderMap() {
    const coordinates = [
      (
        this.doc.location ?? {
          coordinates: [100.57296924653227, 13.766447852042793],
        }
      ).coordinates[0] ?? 100.57296924653227,
      (
        this.doc.location ?? {
          coordinates: [100.57296924653227, 13.766447852042793],
        }
      ).coordinates[1] ?? 13.766447852042793,
    ];
    var mymap = L.map("map").setView(
      [coordinates[1], coordinates[0]],
      this.zoom
    );
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(mymap);
    var greenIcon = L.icon({
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
    });

    var layerGroup = L.layerGroup().addTo(mymap);
    this.layer = layerGroup;
    this.mymap = mymap;
    this.markerRender();
    mymap.on("zoomend", () => {
      this.zoom = mymap.getZoom();
      this.markerRender();
    });
    let self = this;
    mymap.on("click", (e: any) => {
      this.doc.location.coordinates = [e.latlng.lng, e.latlng.lat];
      this.markerRender();
      // var popLocation= e.latlng;
      // var popup = L.popup()
      // .setLatLng(popLocation)
      // .setContent('<p>Hello world!<br />This is a nice popup.</p>')
      // .openOn(mymap);
    });
  }

  markerRender() {
    const coordinates = [
      (
        this.doc.location ?? {
          coordinates: [100.57296924653227, 13.766447852042793],
        }
      ).coordinates[0] ?? 100.57296924653227,
      (
        this.doc.location ?? {
          coordinates: [100.57296924653227, 13.766447852042793],
        }
      ).coordinates[1] ?? 13.766447852042793,
    ];
    this.layer.clearLayers();
    let content = `
    <a href="https://www.google.com/maps?saddr=My+Location&daddr=13.766447852042793,100.57296924653227" target="_blank">
    <img src="/img/dcp_logo_01.d2b93a39.png" width="60" height"60" style="position:relative; transform:translate(-50%,-50%);" alt="">`;
    L.marker([coordinates[1], coordinates[0]], {
      icon: new L.DivIcon({
        className: "my-div-icon",
        html: content,
      }),
    }).addTo(this.layer);
  }
}
