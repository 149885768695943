
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings"
import { confirmAction } from "@/decorators"
@Options({
  components: {},
})

export default class UserList extends Vue {
  filter1:string|undefined = undefined
  filter2:string|undefined = undefined
  search:string = ""
  docs:Array<any> = []
  accesses:Array<any> = []
  page =  1
  show = 99999
  showAdvance:boolean = false
  status = "รายการทั้งหมด"
  statusOptions:Array<any> = [
    { icon: "fa-solid fa-circle", value:"ใช้งานได้", color:'green' },
    { icon: "fa-solid fa-circle-minus", value:"ระงับ", color:'red' },
  ]

  @confirmAction("คุณแน่ใจหรือว่าต้องการลบรายการนี้")
  deleteItem(i:string) {
    // console.log(i)
    axios.delete(`${ADDRESS}/user/delete/${i}`).then((response) => {
      console.log(response)
      this.fetchData();
    });
  }
  countstatus(status:string){
    try {
      if(status=="รายการทั้งหมด") return this.docs.length
      else return this.docs.filter(item=>item.status == status).length
    } catch (error) {
      return 0 
    }
  }
  fetchData() {
    var statusFilter:any = {
      status: undefined
    }
    if(this.status !== "รายการทั้งหมด") {
      statusFilter.status = this.status
    }
    let query = {
      search: {
        ...statusFilter,
        role:this.filter1,
        sector:this.filter2,
        $or: [
          {
            username:
              this.search != ""
                ? { $regex: this.search, $options: "-i" }
                : undefined,
          },
          {
            sector:
              this.search != ""
                ? { $regex: this.search, $options: "-i" }
                : undefined,
          },
        ],
      },
      page: this.page,
      limit: this.show,
      
      sort: { code: -1 }
    };
    console.log('query',query)
    axios
      .post(`${ADDRESS}/users/paginate`, query)
      .then((response) => {
        console.log(response.data)
        this.docs = response.data.docs
      });
  }
  resolveRoleId(s:string){
    try {
      let access = this.accesses.find(ac=>ac._id === s)
      return access.name
    } catch (error) {
      return "-"
    }
  }
  resolveRole(n:number){
    switch(n) {
      case 2:
        return "ผู้ยื่นคำขอขึ้นบัญชีฯ"
        break;
      case 3:
        return "บุคลากร(Viewer)"
        break;
      case 4:
        return "บุคลากร(Editor)"
        break;
      case 5:
        return "ผู้ดูแลระบบ"
        break;
      case 100:
        return "นักพัฒนา"
        break;
      default:
        return n
        break;
    }
  }
  mounted() {
    this.fetchData()
    this.fetchList()
  }

  fetchList(){
    axios
      .get(`${ADDRESS}/usergroups/list`)
      .then((response) => {
        console.log('list', response)
        this.accesses = response.data.result
      });
  }
}
