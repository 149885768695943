
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import draggable from "vuedraggable";
import { useStore } from "vuex";
@Options({
  props: {
    text1: String,
    text2: String,
    // name:String,
    // disabled: Boolean,
  },
  Component: {
    draggable,
  },
})
export default class Intro extends Vue {
  store = useStore()
  name!: string;
  value!: string;
  text1!: string;
  text2!: string;
  val: string = "";
  ADDRESS = ADDRESS;
  news: Array<any> = [];

  get thai(){
    return this.store.getters.thai
  }
  updateVal() {
    this.val = this.value;
  }

  getFirstPhoto(items: Array<any>) {
    for (const item of items) {
      let split = item.path.toLowerCase().split(".");
      let ext: string = split[split.length - 1];
      if (["jpg", "jpeg", "png", "gif", "bmp"].includes(ext)) {
        return item.path;
      }
    }
    return "n/a";
  }
  updated() {
    this.updateVal();
  }
  mounted() {
    this.updateVal();
  }
  emitUp() {
    this.$emit("update:modelValue", this.val);
    this.$emit("change");
  }
}
