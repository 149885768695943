
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import { useToast, POSITION } from "vue-toastification";
@Options({
  components: {},
})
export default class UserGroup extends Vue {
  doc: any = {
    // name : "ผู้ยื่นคำขอขึ้นบัญชี",
    accesses: [],
  };
  docs: Array<any> = [];
  id: string = "";
  getRole() {
    try {
      this.doc = this.docs.find((doc) => doc._id == this.id);
    } catch (error) {}
  }
  getAccess(system: string) {
    let access = this.doc.accesses.find((el: any) => {
      return el.system == system;
    });
    if (!access) {
      access = {
        system,
        read: false,
        write: false,
        setting: false,
      };
      this.doc.accesses.push(access);
      // console.log(access)
    }
    return access;
  }
  submitForm() {
    console.log(this.id);
    axios
      .patch(`${ADDRESS}/usergroup/update/${this.id}`, {
        ...this.doc,
        // isNew: false
      })
      .then((response) => {
        const toast = useToast();
        toast.success("แก้ไขข้อมูลแล้ว!", {
          timeout: 2000,
          position: POSITION.BOTTOM_RIGHT,
        });
        // this.$router.push('/portal/user/group')
      });
  }
  fetchData(id: string) {
    axios.get(`${ADDRESS}/usergroup/get/${id}`).then((response) => {
      console.log(response);
      this.doc = response.data.result;
    });
  }
  fetchList() {
    axios.get(`${ADDRESS}/usergroups/list`).then((response) => {
      console.log("list", response);
      this.docs = response.data.result;
      try {
        this.id = this.docs[0]._id;
      } catch (error) {}
    });
  }
  mounted() {
    this.fetchList();
  }
}
