
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import PDFViewer from "pdf-viewer-vue";
import * as _ from "lodash";
import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {
    PDFViewer,
  },
})
export default class HeritageForm extends Vue {
  ADDRESS = ADDRESS;
  uploaded: string = "";
  doc: any = {};
  item: any = {};
  saving: boolean = false;
  get today() {
    return new Date();
  }
  mounted() {
    if (this.$route.params.id != undefined) {
      this.fetchData();
    }
  }
  submitForm(formSubmit?: boolean) {
    this.saving = true;
    if (this.$route.params.id != undefined)
      axios
        .patch(`${ADDRESS}/request/update-item/${this.$route.params.id}`, {
          ...this.item,
          mp1: true,
        })
        .then((response) => {
          if (formSubmit) {
            const toast = useToast();
            toast.success("บันทึกข้อมูลแล้ว (ref:S-21)", {
              timeout: 2000,
              position: POSITION.BOTTOM_RIGHT,
            });
            this.$router.push(`/portal/request/item-list/${this.doc._id}`);
          } else {
            this.fetchData();
          }
          this.saving = false;
        });
    else {
      axios
        .post(`${ADDRESS}/request/create`, {
          ...this.doc,
        })
        .then((response) => {
          const toast = useToast();
          toast.success("บันทึกข้อมูลแล้ว (ref:S-22)", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
        });
    }
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/request/item/${this.$route.params.id}`)
      .then((res) => {
        console.log(res);
        this.doc = res.data.doc;
        this.item = res.data.item;
      });
  }
}
