
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import { useStore } from "vuex"
import { confirmAction, time } from "@/decorators"
import ADDRESS from "@/settings";
import Helper from "@/helpers"
import * as _ from "lodash"
@Options({
  components: {},
})
export default class RequestList extends Vue{
  docs:Array<any> = []
  type:Array<any> = []
  store = useStore()
  filter1:string|null = null
  filter2:string|null = null
  filter3:string|null = null
  filter4:string|null = null
  filter5:string|null = null
  filter6:string|null = null
  filter7:string|null = null
  page =  1
  pages: number = 0;
  show = 20
  search:string = ""
  step = undefined
  showAdvance:boolean = false
  Helper = Helper
  pushToPath(id:string){
    this.$router.push(`/portal/request/item-list/${id}?page=${this.page}`)
  }
  get thailand() {
    const thailandObject = require("@/data/thailand.json")
    return _.values(thailandObject)
  }
  get category(){
    console.log("this.filter1", this.filter2)
    if(this.filter2==undefined)  return []
    switch (this.filter2) {
      case "ศิลปะการแสดง":
        return [
          "ดนตรี",
          "การแสดง",
          "ดนตรีและการแสดงในพิธีกรรม",
          "เพลงร้องพื้นบ้าน",
        ]
        break;
      case "งานช่างฝีมือดั้งเดิม":
        return [
          "ผ้าและผลิตภัณฑ์จากผ้า",
          "เครื่องจักสาน",
          "เครื่องรัก",
          "เครื่องปั้นดินเผา",
          "เครื่องโลหะ",
          "เครื่องไม้",
          "เครื่องหนัง",
          "เครื่องประดับ",
          "งานศิลปกรรมพื้นบ้าน",
          "ผลิตภัณฑ์อย่างอื่น",
        ]
        break;
    
      case "วรรรณกรรมพื้นบ้านและภาษา":
        return [
          "นิทานพื้นบ้าน",
          "ประวัติศาสตร์บอกเล่า",
          "บทสวดหรือบทกล่าวในพิธีกรรม",
          "บทร้องพื้นบ้าน",
          "สำนวนและภาษิต",
          "ปริศนาคำทาย",
          "ตำรา",
          "ภาษาไทย",
          "ภาษาถิ่น",
          "ภาษากลุ่มชาติพันธุ์",
        ]
        break;
    
      case "การเล่นพื้นบ้าน กีฬาพื้นบ้านและศิลปะการต่อสู้ป้องกันตัว":
        return [
          "การเล่นพื้นบ้าน",
          "กีฬาพื้นบ้าน",
          "ศิลปะการต่อสู้ป้องกันตัว",
        ]
        break;
    
      case "ความรู้และแนวปฏิบัติเกี่ยวกับธรรมชาติและจักรวาล":
        return [
          "อาหารและโภชนาการ",
          "การแพทย์แผนไทยและการแพทย์พื้นบ้าน",
          "โหราศาสตร์และดาราศาสตร์",
          "การจัดการทรัพยากรธรรมชาติ",
          "ชัยภูมิและการรตั้งถิ่นฐาน",
        ]
        break;
    
      case "แนวปฏิบัติทางสังคม พิธีกรรม ประเพณี และเทศกาล":
        return [
          "มารยาท",
          "ขนบธรรรมเนียมประเพณี",
          "งานเทศกาล"
        ]
        break;
    
      default:
        return []
        break;
    }
  }
  getType(n:number) {
    let result = this.type.find((el) => el.step == n)??{count:0}
    return result.count
  }
  countstep(step:number){
    try {
      if(step==undefined) return this.docs.length
      else return this.docs.filter(item=>item.step == step).length
    } catch (error) {
      return 0 
    }
  }
  @confirmAction("คุณแน่ใจหรือว่าต้องการลบรายการนี้")
  deleteItem(i:string) {
    axios.delete(`${ADDRESS}/request/delete/${i}`).then((response) => {
      console.log(response)
      this.fetchData();
    });
  }

  @time(this)
  fetchData() {
    // alert("กำลังโหลดข้อมูล...")
    var stepFilter:any = {
      step: undefined
    }
    if(this.step !==undefined) {
      stepFilter.step = this.step
    }
    let query = {
      search:{
        ...stepFilter,
        province: this.filter1??undefined,
        "items.branch": this.filter2??undefined,
        "items.category": this.filter3??undefined,
        "items.level": this.filter4??undefined,
        "items.persist": this.filter5??undefined,
        "items.registrationDate": this.filter6??undefined,
        zone: this.filter7??undefined,
        $or:[

        {code:
          this.search != ""
            ? { $regex: this.search, $options: "-i" }
            : undefined},
        {"items.name": 
          this.search != ""
            ? { $regex: this.search, $options: "-i" }
            : undefined,}
        ]
        // $or:[
          // {
            // code:
            //   this.search != ""
            //     ? { $regex: this.search, $options: "-i" }
            //     : undefined,
          // },
        //   {
        //     name:
        //       this.search != ""
        //         ? { $regex: this.search, $options: "-i" }
        //         : undefined,
        //   },
        // ],
        
        
      },
      page: this.page,
      limit: this.show,
      skip: (this.page-1)*this.show,
      sort: { code: -1,creartedAt:-1 },
      // select: "code",
    };
    console.log(query)
    axios
      .post(`${ADDRESS}/requests/paginate`, query)
      .then((response) => {
        console.log('requests/paginate',response)
        this.docs = response.data.docs
        this.pages = response.data.totalPages;
        this.type = response.data.glance;
      });
  }
  mounted() {
    this.fetchData()
  }
}
