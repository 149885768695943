
import { Options, Vue } from "vue-class-component";
import L, {Icon} from "leaflet";
import axios from "axios"
import ADDRESS from "@/settings"
import "leaflet/dist/images/marker-shadow.png";
import * as _ from "lodash"
import { forEach } from "lodash";
import Progress from "./components/Progress.vue"
import SaveIndicator from "@/components/SaveIndicator.vue"
import md5 from "md5-file"
import { Watch } from "vue-property-decorator";
import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {Progress, SaveIndicator},
})
export default class HeritageForm extends Vue {
  engVersion: boolean = true;
  doc: any = {
    items:[
      {
        name:"",
        successor:[]
      }
    ]
  }
  item:any = {}
  saving:boolean = false

  autoSave() {
    // setTimeout(() => {
    //   this.autoSave()
    //   this.submitForm()
    // }, 60 * 1 * 1000);
  }

  pushItem() {
    let successor: Array<any> = this.item.successor;
    if (!Array.isArray(successor)) successor = [];
    successor.push({
      name: "",
    });
  }
  get thailand() {
    const thailandObject = require("@/data/thailand.json")
    return _.values(thailandObject)
  }
  // get item() {
  //   try {
  //     let itemIndex = this.$route.params.index
  //     let found = _.find(this.doc.items, function(el) { return el._id == itemIndex; });
  //     try {
  //       if(found.successor.length < 1) found.successor = [{}]
  //     } catch (error) {
  //       found.successor = [{}]
  //     }
  //     return found!==undefined?found:{}
  //   } catch (error) {
  //     return {}
  //   }
  // }
  mounted(){
    if(this.$route.params.id!=undefined) {
      this.fetchData()
      this.autoSave()
    }
  }
  submitForm(formSubmit?:boolean) {
    this.saving = true
    if(this.$route.params.id!=undefined) 
    axios
      .patch(`${ADDRESS}/request/update-item/${this.$route.params.id}`, {
        ...this.item,
        mp1:true
      })
      .then((response) => {
        if(formSubmit) {
          const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-15)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
          this.$router.push(`/portal/request/item-list/${this.doc._id}`)
        } else {
          this.fetchData()
        }
        this.saving = false
      });
    else {
      axios
        .post(`${ADDRESS}/request/create`, {
          ...this.doc,
        })
        .then((response) => {
          const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-16)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
        });
    }
  }
  fetchData(){
    axios.get(`${ADDRESS}/request/item/${this.$route.params.id}`).then((res) => {
      console.log(res)
      this.doc = res.data.doc;
      this.item = res.data.item
    });
  }  
  getDistrict(name:string){
    try {
      const districtObject = this.thailand.find((province:any) => province.name.th == name).amphoes
      return _.values(districtObject)
    } catch (error) {
      return []
    }
  }
}
