import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "container-fluid p-0 overflow-x-hidden",
  style: {"width":"100vw","overflow-x":"hidden"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_Intro = _resolveComponent("Intro")!
  const _component_Highlight = _resolveComponent("Highlight")!
  const _component_News = _resolveComponent("News")!
  const _component_Collection = _resolveComponent("Collection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layouts, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        ((item??{}).name==='image')
          ? (_openBlock(), _createBlock(_component_Image, {
              key: 0,
              text: item.text1
            }, null, 8, ["text"]))
          : _createCommentVNode("", true),
        ((item??{}).name==='intro')
          ? (_openBlock(), _createBlock(_component_Intro, {
              key: 1,
              text1: item.text1,
              text2: item.text2
            }, null, 8, ["text1", "text2"]))
          : _createCommentVNode("", true),
        ((item??{}).name==='highlight')
          ? (_openBlock(), _createBlock(_component_Highlight, {
              key: 2,
              text: item.text1
            }, null, 8, ["text"]))
          : _createCommentVNode("", true),
        ((item??{}).name==='news')
          ? (_openBlock(), _createBlock(_component_News, {
              key: 3,
              text: item.text1
            }, null, 8, ["text"]))
          : _createCommentVNode("", true),
        ((item??{}).name==='collection')
          ? (_openBlock(), _createBlock(_component_Collection, {
              key: 4,
              text: item.text1
            }, null, 8, ["text"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ]))
}