
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { confirmAction } from "@/decorators"
@Options({
  components: {},
})
export default class DocumentList extends Vue{
  docs:Array<any> = []
  ADDRESS = ADDRESS

  @confirmAction("คุณแน่ใจหรือว่าต้องการลบรายการนี้")
  deleteItem(i:string) {
    console.log(i)
    axios.delete(`${ADDRESS}/uploaddoc/delete/${i}`).then((response) => {
      console.log(response)
      this.fetchData();
    });
  }
  fetchData() {
    let query = {
      limit:999,
      page:1
    };
    // console.log(query)
    axios
      .post(`${ADDRESS}/uploaddocs/paginate`,query)
      .then((response) => {
        console.log('response',response)
        this.docs = response.data.docs
      });
  }
  mounted() {
    this.fetchData()
  }
}
