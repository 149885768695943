
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import * as _ from "lodash"


@Options({
  components: {},
})
export default class List extends Vue{
  docs:Array<any> = []
  heritages: Array<any> = [];
  items: Array<any> = [];
  page =  1
  show = 20
  search:string = ""
  pages: number = 0;
  branches:Array<any> = []
  filter1!:string
  filter2:string|null = null
  filter3:string|null = null
  filter4:string|null = null
  Filter:boolean = false


  ADDRESS = ADDRESS

  getFirstPhoto(items:Array<any>){
    for(const item of items){
      let split = item.path.split(".")
      let ext:string = split[split.length-1]
      if(['jpg','jpeg','png','gif','bmp'].includes(ext)){
        return item.path
      }
    }
    return "n/a"
  }
  edit(id:string) {
    alert(`/public/community/${id}`)
    this.$router.push(`/public/community/${id}`)
  }
  fetchData() {
    let query = {
      search:{
        status:"เผยแพร่",
        province: this.filter1==""?undefined:this.filter1,
        name : {
          $regex : this.search
        }
      },
      page: this.page,
      limit: this.show,     
      sort: { code: -1 },
    };
    console.log(query)
    axios
      .post(`${ADDRESS}/communities/paginate`, query)
      .then((response) => {
        console.log(response)
        this.docs = response.data.docs
        this.pages = response.data.totalPages;
      });
  }
  get thailand() {
    const thailandObject = require("@/data/thailand.json")
    return _.values(thailandObject)
  }
  getTotal(){
    try{
      return this.docs.length
    } catch(error){
      return 0
    }
  }
  getBranchCount(str:string){
    try{
      return this.branches.find(branch=>branch._id===str).count
    } catch(error){
      return 0
    }
  }
  mounted() {
    this.fetchData();
  }
}
