
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import * as _ from "lodash";
import { useStore } from "vuex";
import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {},
})
export default class RequestForm extends Vue {
  store = useStore();
  isHighlight: boolean = false;
  doc: any = {
    code: "ระบบจะสร้างรหัสให้อัตโนมัติ",
    items: [],
  };
  search: string = "";
  heritages: Array<any> = [];
  debouncedHandler: any;
  created() {
    this.debouncedHandler = _.debounce((event) => {
      console.log("New value:", event.target.value);
    }, 1000);
  }
  beforeUnmount() {
    this.debouncedHandler.cancel();
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  get today() {
    return new Date();
  }
  fetchData() {
    axios.get(`${ADDRESS}/contact/get/${this.$route.params.id}`).then((res) => {
      console.log("re", res);
      this.doc = res.data.result;
    });
  }
  submitForm() {
    if (this.$route.params.id != undefined)
      axios
        .patch(`${ADDRESS}/contact/update/${this.$route.params.id}`, {
          ...this.doc,
          fromMember: this.store.state.userId,
        })
        .then((response) => {
          const toast = useToast();
          toast.success("บันทึกข้อมูลแล้ว (ref:S-8)", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
          // this.$router.push(`/portal/contact`)
        });
    else
      axios
        .post(`${ADDRESS}/contact/create`, {
          ...this.doc,
          fromMember: this.store.state.userId,
        })
        .then((response) => {
          const toast = useToast();
          toast.success("บันทึกข้อมูลแล้ว (ref:S-9)", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
          // this.$router.push(`/portal/contact`)
        });
  }
}
