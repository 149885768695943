
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import JMColorPicker from "@/components/JMColorPicker.vue";
import { Watch } from "vue-property-decorator";
import { useToast, POSITION } from "vue-toastification";
@Options({
  components: {
    JMColorPicker,
  },
})
export default class Typography extends Vue {
  colors: any | string = "#194D33A8";
  color = "#818684";
    doc = {
    _id:"",
    member:[
      { name:"Paragraph", number1:18, text1:"Prompt", text2:"Normal", text3:"#194D33A8", text5:"10" },
      { name:"Link", number1:18, text1:"Prompt", text2:"Normal", text3:"#194D33A8", text4:"#194D33A8", text5:"10" },
      { name:"H1", number1:18, text1:"Prompt", text2:"Normal", text3:"#000000", text5:"10" },
      { name:"H2", number1:18, text1:"Prompt", text2:"Normal", text3:"#000000", text5:"10" },
      { name:"H3", number1:18, text1:"Prompt", text2:"Normal", text3:"#000000", text5:"10" },
      { name:"H4", number1:18, text1:"Prompt", text2:"Normal", text3:"#000000", text5:"10" },
      { name:"H5", number1:18, text1:"Prompt", text2:"Normal", text3:"#000000", text5:"10" },
      { name:"H6", number1:18, text1:"Prompt", text2:"Normal", text3:"#000000", text5:"10" },
    ]
  }
  reset(){
    this.doc.member = [
      { name:"Paragraph", number1:18, text1:"Prompt", text2:"Normal", text3:"#194D33A8", text5:"10" },
      { name:"Link", number1:18, text1:"Prompt", text2:"Normal", text3:"#194D33A8", text4:"#194D33A8", text5:"10" },
      { name:"H1", number1:18, text1:"Prompt", text2:"Normal", text3:"#000000", text5:"10" },
      { name:"H2", number1:18, text1:"Prompt", text2:"Normal", text3:"#000000", text5:"10" },
      { name:"H3", number1:18, text1:"Prompt", text2:"Normal", text3:"#000000", text5:"10" },
      { name:"H4", number1:18, text1:"Prompt", text2:"Normal", text3:"#000000", text5:"10" },
      { name:"H5", number1:18, text1:"Prompt", text2:"Normal", text3:"#000000", text5:"10" },
      { name:"H6", number1:18, text1:"Prompt", text2:"Normal", text3:"#000000", text5:"10" },
    ]
  }
  getFont(i:number){
    try {
      return this.doc.member[i]
    } catch(error){
      return {}
    }
  }
  submit(){
    axios.patch(`${ADDRESS}/setting/update/${this.doc._id}`,this.doc).then(response=>{
      console.log("done!")
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-33)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
    })
  }
  fetchData(){
    axios.get(`${ADDRESS}/setting/get-by-name/typography`).then((response) => {
      console.log(response.data.result)
      if(response.data.result.member.length > 0){
        this.doc = response.data.result
      } else {
        this.doc._id = response.data.result._id
      }
      this.$forceUpdate()
    });
  }
  mounted(){
    this.fetchData()
  }
}
