export default class StatusHelper {
  public static resolveStatusRequester(n: number) {
    switch (n) {
      case 0:
        return 'รอคุณดำเนินการ'
        break;
      case 1:
        return 'รอตรวจสอบ'
        break;
      case -1:
        return 'ให้แก้ไขตามข้อเสนอแนะ'
        break;
      case 2:
        return 'รอพิจารณา'
        break;
      case -2:
        return 'ไม่มี'
        break;
      case 3:
        return 'ขึ้นบัญชี'
        break;
      case -3:
        return 'ยกเลิก'
        break;
      default:
        return '?'
        break;
    }
  }
  public static resolveStatusJury(n: number) {
    switch (n) {
      case 0:
        return '-'
        break;
      case 1:
        return 'คำขอใหม่'
        break;
      case -1:
        return 'รอดำเนินการ'
        break;
      case 2:
        return 'รอพิจารณา'
        break;
      case -2:
        return 'ไม่มี'
        break;
      case 3:
        return 'ขึ้นบัญชี'
        break;
      case -3:
        return 'ยกเลิก'
        break;
      default:
        return '?'
        break;
    }
  }
}