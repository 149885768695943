
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import draggable from "vuedraggable";

@Options({
  components: {
    draggable,
  },
})
export default class TemplateForm extends Vue {
  elements:Array<any> = [
    { id: "1", name:"image", image: require("@/assets/layout/khon-001.jpg") },
    { id: "2", name:"intro", image: require("@/assets/layout/text.jpg") },
    { id: "2", name:"highlight", image: require("@/assets/layout/highlight-ich.jpg") },
    { id: "3", name:"news", image: require("@/assets/layout/news-event.jpg") },
    { id: "4", name:"collection", image: require("@/assets/layout/collection.jpg") },
  ];
  temp:Array<any> = []
  dragging = false
  dragStart(){
    this.dragging = true
  }
  dragEnd(){
    this.dragging = false
  }
  get dragOptions() {
    return {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    };
  }
  mounted() {}
}
