import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container bg-white py-3" }
const _hoisted_2 = { class: "dragging-image-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_draggable, _mergeProps({
      class: "row",
      modelValue: _ctx.elements,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.elements) = $event)),
      "item-key": "id",
      group: "uis"
    }, _ctx.dragOptions, {
      onStart: _ctx.dragStart,
      onEnd: _ctx.dragEnd
    }), {
      item: _withCtx(({ element, index }) => [
        _createElementVNode("div", {
          class: _normalizeClass(["dragging", {'active':_ctx.dragging}])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["drag-box bg-white my-1", {'dragging-inside':_ctx.dragging}])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "w-100",
                src: element.image
              }, null, 8, _hoisted_3)
            ])
          ], 2)
        ], 2)
      ]),
      _: 1
    }, 16, ["modelValue", "onStart", "onEnd"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("pre", null, _toDisplayString(_ctx.elements), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("pre", null, _toDisplayString(_ctx.temp), 1)
      ])
    ])
  ]))
}