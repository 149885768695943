
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { confirmAction, time } from "@/decorators"
import { DateTime } from "luxon";
@Options({
  components: {},
})
export default class MediaList extends Vue{
  docs:Array<any> = []
  page =  1
  pages: number = 0;
  show = 100
  search:string = ""
  status = "รายการทั้งหมด"
  filter1:string|undefined = undefined
  filter2:number = -1
  
  statusOptions:Array<any> = [
    { icon: "fa-solid fa-circle", value:"เผยแพร่", color:'#00A325', weight:'fw-bold'},
    { icon: "fa-solid fa-circle-minus", value:"ไม่เผยแพร่", color:'grey', weight:'fw-bold'},
    { icon: "", value:"แบบร่าง", color:'grey', weight:'fw-light', border:'.border-0' },
  ]
  updateStatus(id:string, status:string){
    let option:any = {}
    if(status=="เผยแพร่") option = {status:"เผยแพร่" } 
    else if(status=="ไม่เผยแพร่") option = {status:"ไม่เผยแพร่" } 
    axios.patch(`${ADDRESS}/media/update/${id}`, option).then(response=>{
      console.log(response.data)
      this.fetchData()
    })
  }  
  get currentYear(){
    return new Date().getFullYear() + 543
  }
  @confirmAction("คุณต้องการลบรายการสื่อและเอกสาร")
  deleteItem(id:string) {
    axios.delete(`${ADDRESS}/media/delete/${id}`).then((response) => {
      this.fetchData()
    })
    console.log("ลบแล้ว!")
  }
  // deleteItem(i:number) {
  //   this.docs.splice(i,1)
  //   console.log("ลบแล้ว!")
  // }
  countstatus(status:string){
    try {
      if(status=="รายการทั้งหมด") return this.docs.length
      else return this.docs.filter(item=>item.status == status).length
    } catch (error) {
      return 0 
    }
  }
  fetchData() {
    let query = {
      search:{
        status:this.status!="รายการทั้งหมด"?this.status:undefined,
        type:this.filter1,
        modifiedAt: this.filter2 !== -1 ? { $gte: DateTime.fromObject({day:1,month:1,year:this.filter2-543}).toISO() , $lte:DateTime.fromObject({day:1,month:1,year:this.filter2-543}).endOf("year").toISO()} : undefined,
        $or:[
          {name:{ $regex: this.search }},
          {code:{ $regex: this.search }},
        ]
      },
      page: this.page,
      limit: this.show,   
      sort: { code: -1 }
    };
    axios
      .post(`${ADDRESS}/medias/paginate`, query)
      .then((response) => {
        this.docs = response.data.docs
        this.pages = response.data.totalPages;
        this.docs = this.docs.map(doc=>{
          return {
            ...doc,
            status:doc.isHighlight?"ไฮไลท์":doc.status
          }
        })
      });
  }
  mounted() {
    this.fetchData()
  }
  resolveType(n:string) {
    switch(n) {
      case("ebook") :
      return "หนังสือ/สื่อสิ่งพิมพ์"
      break;
      case("infographic") :
      return "อินโฟกราฟิก"
      break;
      case("article") :
      return "บทความ"
      break;
      case("report") :
      return "รายงาน"
      break;
      case("research") :
      return "วิจัย"
      break;
      case("law-manual-form") :
      return "กฏหมาย/คู่มือ/แบบฟอร์ม"
      break;
      default :
      return n
      break;
    }
  }
}
