
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";

@Options({
  components: {},
})
export default class InfoDetail extends Vue {
  doc: any = {};
  active: any = {};
  ci = 0;
  ADDRESS = ADDRESS;

  next(){
    if(this.ci<this.doc.uploadItems.length-1){
      this.ci++;
      this.restartAnimation(this.doc.uploadItems[this.ci],this.ci);
    }
  }
  prev(){
    if(this.ci>0){
      this.ci--;
      this.restartAnimation(this.doc.uploadItems[this.ci],this.ci);
    }
  }

  getFirstPhoto(items: Array<any>) {
    console.log(items);
    for (const item of items) {
      let split = item.path.toLowerCase().split(".");
      let ext: string = split[split.length - 1];
      if (["jpg", "jpeg", "png", "gif", "bmp"].includes(ext)) {
        return item.path;
      }
    }
    return "n/a";
  }
  fetchData() {
    axios.get(`${ADDRESS}/media/get/${this.$route.params.id}`).then((res) => {
      console.log("re", res);
      console.log(res);
      this.doc = res.data.result;
      try {
        this.active = this.doc.uploadItems[0];
      } catch (error) {}
    });
  }
  mounted() {
    this.fetchData();
  }

  restartAnimation(item: string, index:number) {
    this.ci = index
    var el = document.getElementById("main-infographic-carousel");
    el?.classList.remove("animation-change-carousel");
    setTimeout(() => {
      this.$nextTick(() => {
        el?.classList.add("animation-change-carousel");
      });
    }, 10);
    setTimeout(() => {
      this.active = item;
    }, 100);
  }
}
