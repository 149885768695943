
import ADDRESS, { SOCKET_ADDRESS } from "@/settings";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { io } from "socket.io-client"
@Options({
  components: {},
})
export default class SideNavigation extends Vue {
  showlevel2: boolean = false;
  fullSize: boolean = false;
  forceFullSize: boolean = false;
  store = useStore();
  accesses: Array<any> = [];
  openLevel2() {
    this.showlevel2 = !this.showlevel2;
  }
  toggleNav() {
    this.fullSize = !this.fullSize;
  }
  submitLogout() {
    this.store.commit("setUser", {});
    this.$router.push("/");
  }

  fetchList() {
    axios.get(`${ADDRESS}/usergroups/list`).then((response) => {
      this.accesses = response.data.result;
      // if
      //     option(value=2) ผู้ยื่นคำขอขึ้นบัญชีฯ
      //     option(value=3) บุคลากร(Viewer)
      //     option(value=4) บุคลากร(Editor)
      //     option(value=5) ผู้ดูแลระบบ
      //     option(value=100) นักพัฒนา
    });
  }
  canUserAccess(s: string) {
    try {
      let find = this.store.state.role.accesses.find(
        (ac: any) => ac.system === s
      );
      console.log(find);
      return find.read;
    } catch (error) {
      return false;
    }
  }
  get filteredNav(): Array<NavConfig> {
    return this.navigations.filter((el) =>
      this.canUserAccess(el.access_name as string)
    );
  }
  navigations: Array<NavConfig> = [
    {
      title: "จัดการคำขอขึ้นบัญชีฯ",
      icon: "fa-solid fa-list-check",
      access_name: "por_จัดการคำขอขึ้นบัญชี",
      active: false,
      wip: false,
      children: [
        {
          title: "รายการคำขอขึ้นบัญชีฯ",
          path: "/portal/request-management",
          icon: "fas fa-chevron-right",
          wip: false,
        },
        {
          title: "รายการเอกสาร",
          path: "/portal/request-management/document",
          icon: "fas fa-chevron-right",
          wip: false,
        },
      ],
    },
    {
      title: "รายการมรดกภูมิปัญญาทางวัฒนธรรม",
      icon: "fa-solid fa-books",
      access_name: "por_จัดการมรดก",
      path: "/portal/heritage",
      active: false,
      wip: false,
    },
    {
      title: "จัดหมวดหมู่",
      icon: "fa-solid fa-tags",
      access_name: "por_จัดการคอลเลคชัน",
      active: false,
      wip: false,
      children: [
        {
          title: "คอลเลกชัน",
          icon: "fas fa-chevron-right",
          path: "/portal/collection",
          wip: false,
        },
        {
          title: "ข้อมูลชุมชน",
          icon: "fas fa-chevron-right",
          path: "/portal/community",
          wip: false,
        }
      ]
    },
    // {
    //   title: "คอลเลกชัน",
    //   icon: "fa-solid fa-tags",
    //   access_name: "por_จัดการคอลเลคชัน",
    //   path: "/portal/collection",
    //   active: false,
    //   wip: false,
    // },
    // {
    //   title: "ข้อมูลชุมชน",
    //   icon: "fa-solid fa-users",
    //   access_name: "por_จัดการข้อมูลชุมชน",
    //   path: "/portal/community",
    //   active: false,
    //   wip: false,
    // },
    {
      title: "กิจกรรมและเอกสาร",
      icon: "fa-solid fa-photo-film",
      access_name: "por_จัดการสื่อและเอกสาร",
      active: false,
      wip: false,
      children: [
        {
          title: "สื่อและเอกสาร",
          icon: "fas fa-chevron-right",
          path: "/portal/media",
          wip: false,
        },
        {
          title: "กิจกรรมและข่าว",
          path: "/portal/blog",
          icon: "fas fa-chevron-right",
          wip: false,
        },
        {
          title: "บทความ",
          path: "/portal/article",
          icon: "fas fa-chevron-right",
          wip: false,
        },
      ]
    },
    {
      title: "ข้อความติดต่อจากผู้ใช้",
      icon: "fa-solid fa-messages",
      access_name: "por_จัดการข้อความ",
      path: "/portal/contact",
      active: false,
      wip: false,
    },
    {
      title: "ข้อมูลผู้ใช้",
      icon: "fa-solid fa-user-pen",
      access_name: "por_จัดการข้อมูลผู้ใช้",
      active: false,
      wip: false,
      children: [
        {
          title: "รายการข้อมูลผู้ใช้งาน",
          path: "/portal/user",
          icon: "fas fa-chevron-right",
          wip: false,
        },
        {
          title: "จัดการกลุ่มผู้ใช้งาน",
          path: "/portal/user/group",
          icon: "fas fa-chevron-right",
          wip: false,
        },
      ],
    },
    {
      title: "ยื่นคำขอขึ้นบัญชีฯ",
      icon: "fa-solid fa-file-lines",
      access_name: "por_ยื่นคำขอขึ้นบัญชี",
      active: false,
      wip: false,
      children: [
        {
          title: "รายการยื่นคำขอขึ้นบัญชีฯ",
          path: "/portal/request",
          icon: "fas fa-chevron-right",
          wip: false,
        },
        {
          title: "รายการเอกสาร",
          path: "/portal/request/document",
          icon: "fas fa-chevron-right",
          wip: false,
        },
      ],
    },
    // {
    //   title: "กิจกรรมและข่าว",
    //   icon: "fa-solid fa-megaphone",
    //   access_name: "por_จัดการกิจกรรม",
    //   path: "/portal/blog",
    //   active: false,
    //   wip: false,
    // },
    // {
    //   title: "บทความ",
    //   icon: "fa-solid fa-file-pen",
    //   access_name: "por_จัดการบทความ",
    //   path: "/portal/article",
    //   active: false,
    //   wip: false,
    // },
    {
      title: "การแสดงผลบนเว็บไซต์",
      icon: "fa-solid fa-tv",
      access_name: "por_การแสดงผล",
      active: false,
      wip: false,
      children: [
        {
          title: "ตั้งค่าขอบเขตข้อมูล",
          path: "/portal/display/field",
          icon: "fas fa-chevron-right",
          wip: false,
        },
        {
          title: "ตั้งค่า Template",
          path: "/portal/display/template",
          icon: "fas fa-chevron-right",
          wip: false,
        },
        {
          title: "ตั้งค่าโครงสร้างเมนู",
          path: "/portal/display/structure",
          icon: "fas fa-chevron-right",
          wip: false,
        },
        {
          title: "ตั้งค่าแบบอักษร",
          path: "/portal/display/typography",
          icon: "fas fa-chevron-right",
          wip: false,
        },
        {
          title: "ตั้งค่าสี",
          path: "/portal/display/color",
          icon: "fas fa-chevron-right",
          wip: false,
        },
        {
          title: "ตั้งค่าส่วนประกอบอื่นๆ",
          path: "/portal/display/other",
          icon: "fas fa-chevron-right",
          wip: false,
        },
        {
          title: "ตั้งค่าอื่นๆ",
          path: "/portal/display",
          icon: "fas fa-chevron-right",
          wip: false,
        },
      ],
    },
    {
      title: "สถิติการเข้าชม",
      icon: "fa-solid fa-bar-chart",
      access_name: "por_สถิติการเข้าชม",
      path: "/portal/analytics",
      active: false,
      wip: false,
    },
    {
      title: "รายงาน",
      icon: "fa-solid fa-chart-mixed",
      access_name: "por_รายงาน",
      path: "/portal/report",
      active: false,
      wip: false,
    },
  ];
  contactNotifications = 3;

  mounted() {
    this.getNotifications();
    this.fetchList();
    const socket = io(SOCKET_ADDRESS, { path: "/api/socket" })
    console.log("connecting to socket...")
    socket.on("connect", () => {
      console.log("connected")
    })
    socket.on("updateUserList", arg=>{
      this.store.commit("setUserList",arg)
    })

    socket.on("userStillOnline", arg=>{
      // console.log("userStillOnline")
      socket.emit("routeTo", {
        path: this.$route.path,
        page: this.$route.name,
        fullName: this.store.state.fullName,
        id: this.store.state.userId,
        dt: new Date()
      })
    })
  }
  getNotifications() {
    axios.get(`${ADDRESS}/contact/notifications`).then((response) => {
      console.log({ data: response.data });
      this.contactNotifications = response.data.result;
    });
  }
}
interface NavConfig {
  title: String;
  path?: String;
  anchor?: String;
  name?: String;
  active?: Boolean;
  access_name?: String;
  wip?: Boolean;
  icon: String;
  show?: Boolean;
  children?: Array<NavConfigChildren>;
}
interface NavConfigChildren {
  title: String;
  path?: String;
  anchor?: String;
  name?: String;
  active?: Boolean;
  access_name?: String;
  wip?: Boolean;
  icon: String;
}
