
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { Watch } from "vue-property-decorator";
import L, {Icon} from "leaflet";

@Options({
  components: {},
})
export default class Detail extends Vue{
  doc:any = {}
  heritage:Array<any> = []
  mymap: any;
  layer: any;
  zoom = 17;
  ADDRESS = ADDRESS
  fetchData(){
    axios.get(`${ADDRESS}/community/get/${this.$route.params.id}`).then((res) => {
      // console.log('re', res)
      // console.log(res)
      this.doc = res.data.result;
      this.heritage = this.doc.items
    });
  }

  getFirstPhoto(items:Array<any>){
    for(const item of items){
      let split = item.path.split(".")
      let ext:string = split[split.length-1]
      if(['jpg','jpeg','png','gif','bmp'].includes(ext)){
        return item.path
      }
    }
    return "n/a"
  }
  initMap(){
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }
  renderMap(){
    let coordinates = [100.5708937,13.7670538]
    try {
      coordinates = [
        (this.doc.location??{coordinates:[100.5708937,13.7670538]}).coordinates[0]??100.5708937,
        (this.doc.location??{coordinates:[100.5708937,13.7670538]}).coordinates[1]??13.7670538
      ]
    } catch (error) {
      
    }
    var mymap = L.map("map").setView([coordinates[1], coordinates[0]], this.zoom);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(mymap);
    var greenIcon = L.icon({
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
    });

    var layerGroup = L.layerGroup().addTo(mymap);
    this.layer = layerGroup;
    this.mymap = mymap;
    this.markerRender();
    mymap.on("zoomend", () => {
      this.zoom = mymap.getZoom();
      this.markerRender();
    });
    let self = this
    mymap.on('click', (e:any) => {   
      this.doc.location.coordinates = [e.latlng.lng, e.latlng.lat];     
      this.markerRender();
        // var popLocation= e.latlng;
        // var popup = L.popup()
        // .setLatLng(popLocation)
        // .setContent('<p>Hello world!<br />This is a nice popup.</p>')
        // .openOn(mymap);        
    });
  }
  markerRender() {
    let coordinates = [100.5708937,13.7670538]
    let name = ""
    try {
      coordinates = [
        (this.doc.location??{coordinates:[100.5708937,13.7670538]}).coordinates[0]??100.5708937,
        (this.doc.location??{coordinates:[100.5708937,13.7670538]}).coordinates[1]??13.7670538
      ]
    } catch (error) {
      
    }
    try {
      name = this.doc.name
    } catch (error) {
      
    }
    this.layer.clearLayers();
    let content =
      `<div class="my-map-marker">` +
      (this.mymap.getZoom() >= 8
        ? `<span class="title">${name}</span>`
        : "")
    L.marker([coordinates[1], coordinates[0]], {
      icon: new L.DivIcon({
        className: "my-div-icon",
        html: content,
      }),
    }).addTo(this.layer);
  }

  stripHTML(text:string){
    return text.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, "").substring(0,175)+"..."
  }
  mounted() {
    this.fetchData();
    this.initMap();
    this.renderMap();
  }
}
