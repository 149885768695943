
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import axios from "axios";
import ADDRESS from "@/settings";
import JMUpload from "../../../components/JMUpload.vue";
import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {
    draggable,
  },
})
export default class TemplateForm extends Vue {
  elements: Array<any> = [];
  temp: Array<any> = [];
  dragging = false;
  title: string = "";
  doc: any = {};
  active:any = {}
  ADDRESS = ADDRESS
  dragStart(ev:any) {
    this.active = this.elements[ev.oldIndex]
    console.log(this.active)
    this.dragging = true;
  }
  dragEnd() {
    this.dragging = false;
  }
  get dragOptions() {
    return {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost",
    };
  }
  async fetchData() {
    let response = await axios.get(
      `${ADDRESS}/layout/get-by-name/${this.$route.params.name}`
    );
    this.doc = response.data.result;
    this.elements = this.doc.elementSingle;
    if (this.elements.length === 0) this.resetForm();
  }
  async saveData() {
    let response = await axios.patch(
      `${ADDRESS}/layout/update/${this.doc._id}`,
      { elementSingle: this.elements }
    );
    const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-31)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT,
    });
    this.fetchData();
  }
  async resetForm() {
    if (this.$route.params.name === "home") {
      this.title = "หน้าแรก";
      this.elements = [
        {
          id: "1",
          name: "image",
          alt:"Banner",
          elementType: "upload",
          image: require("@/assets/layout/banner.png"),
        },
        {
          id: "2",
          name: "intro",
          alt:"Intro",
          elementType: "textarea",
          image: require("@/assets/layout/text.jpg"),
        },
        {
          id: "2",
          name: "highlight",
          elementType: "image",
          image: require("@/assets/layout/highlight-ich.jpg"),
        },
        {
          id: "3",
          name: "news",
          elementType: "image",
          image: require("@/assets/layout/news-event.jpg"),
        },
        {
          id: "4",
          name: "collection",
          elementType: "image",
          image: require("@/assets/layout/collection.jpg"),
        },
      ];
    } else if (
      this.$route.params.name === "province" ||
      this.$route.params.name === "national" ||
      this.$route.params.name === "international"
    ) {
      this.title = "รายการมรดกภูมิปัญญาทางวัฒนธรรม";
      this.elements = [
        {
          id: "1",
          name: "banner",
          elementType: "upload",
          image: require("@/assets/layout/banner.png"),
        },
        {
          id: "2",
          name: "intro",
          elementType: "textarea",
          image: require("@/assets/layout/text.jpg"),
        },
        {
          id: "2",
          name: "heritage",
          elementType: "image",
          image: require("@/assets/layout/heritage-list.png"),
        },
      ];
    } else if (this.$route.params.name === "contact") {
      this.title = "ติดต่อเรา";
      this.elements = [
        {
          id: "1",
          name: "map",
          elementType: "image",
          image: require("@/assets/layout/map.png"),
        },
        {
          id: "2",
          name: "contact",
          elementType: "image",
          image: require("@/assets/layout/contact.png"),
        },
      ];
    } else if (this.$route.params.name === "detail") {
      this.title = "รายละเอียดมรดก";
      this.elements = [
        {
          id: "1",
          name: "header",
          elementType: "image",
          image: require("@/assets/layout/heritage-detail-header.png"),
        },
        {
          id: "2",
          name: "info",
          elementType: "image",
          image: require("@/assets/layout/herigate-detail-info.png"),
        },
        {
          id: "2d",
          name: "divider2",
          elementType: "image",
          image: require("@/assets/layout/heritage-detail-divider.png"),
        },
        {
          id: "3",
          name: "summary",
          elementType: "image",
          image: require("@/assets/layout/heritage-detail-summary.png"),
        },
        {
          id: "3d",
          name: "divider3",
          elementType: "image",
          image: require("@/assets/layout/heritage-detail-divider.png"),
        },
        {
          id: "4",
          name: "photo",
          elementType: "image",
          image: require("@/assets/layout/heritage-detail-photo.png"),
        },
        {
          id: "4d",
          name: "divider4",
          elementType: "image",
          image: require("@/assets/layout/heritage-detail-divider.png"),
        },
        {
          id: "5",
          name: "map",
          elementType: "image",
          image: require("@/assets/layout/heritage-detail-map.png"),
        },
      ];
    }
  }
  mounted() {
    this.fetchData();
  }
}
