
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { confirmAction, time } from "@/decorators"
import draggable from 'vuedraggable'
@Options({
  components: {
    draggable
  },
})
export default class List extends Vue{
  doc:any = {
    member:[]
  }
  docs:Array<any> = []
  page =  1
  pages: number = 0;
  show = 20

  deleteMember(i:string) {
    this.doc.member.splice(i, 1)
  }
  pushMember(){
    this.doc.member.push({
      name: "field",
      text1: "ชื่อฟิลด์",
      text2: "inputText",
    })
  }
  fetchData() {
    axios.get(`${ADDRESS}/setting/get-by-name/data-field`).then((response) => {
      console.log(response.data)
      this.doc = response.data.result
    })
  }
  async submitForm(formSubmit?:boolean) {
    let result = await axios.patch(`${ADDRESS}/setting/update/${this.doc._id}`,this.doc)
    console.log(result)
    this.fetchData()
  }
  mounted() {
    this.fetchData()
  }
}
