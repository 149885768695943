
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { DateTime } from "luxon";
import { confirmAction, time } from "@/decorators"
@Options({
  components: {},
})
export default class MediaList extends Vue{
  docs:Array<any> = []
  page =  1
  show = 100
  isHighlight:boolean|undefined = undefined
  // total: number = 0;
  // show: number = 100;
  // page: number = 1; 
  // pages: number = 0;
  // search:string = ""
  // heritages:Array<any> = []
  filter1:number = -1
  search:string = ""
  status = "รายการทั้งหมด"
  showAdvance:boolean = false
  showDetail:boolean = false
  statusOptions:Array<any> = [
    { icon: "fa-solid fa-circle", value:"เผยแพร่", color:'#00A325', weight:'fw-bold'},
    { icon: "fa-solid fa-circle-minus", value:"ไม่เผยแพร่", color:'grey', weight:'fw-bold'},
    { icon: "fa-solid fa-star", value:"ไฮไลท์", color:'#008ED9', weight:'fw-bold' },
    // { icon: "", value:"แบบร่าง", color:'grey', weight:'fw-light', border:'.border-0' },
  ]
  updateStatus(id:string, status:string){
    let option:any = {}
    if(status=="เผยแพร่") option = {status:"เผยแพร่", isHighlight: false} 
    else if(status=="ไม่เผยแพร่") option = {status:"ไม่เผยแพร่", isHighlight: false} 
    else if(status=="ไฮไลท์") option = {status:"เผยแพร่", isHighlight: true} 
    console.log(id, status, option)
    axios.patch(`${ADDRESS}/collection/update/${id}`, option).then(response=>{
      console.log(response.data)
      this.fetchData()
    })
  }
  get currentYear(){
    return new Date().getFullYear() + 543
  }
  @confirmAction("คุณต้องการลบรายการคอลเลกชัน")
  deleteItem(id:string) {
    axios.delete(`${ADDRESS}/collection/delete/${id}`).then((response) => {
      this.fetchData()
    })
    console.log("ลบแล้ว!")
  }
  // deleteItem(i:number) {
  //   this.docs.splice(i,1)
  //   console.log("ลบแล้ว!")
  // }
  countstatus(status:string){
    try {
      if(status=="รายการทั้งหมด") return this.docs.length
      else return this.docs.filter(item=>item.status == status).length
    } catch (error) {
      return 0 
    }
  }
  fetchData() {
    let query = {
      search:{
        status:this.status!="รายการทั้งหมด"?this.status:undefined,
        isHighlight:this.isHighlight,
        createdAt: this.filter1 !== -1 ? { $gte: DateTime.fromObject({day:1,month:1,year:this.filter1-543}).toISO() , $lte:DateTime.fromObject({day:1,month:1,year:this.filter1-543}).endOf("year").toISO()} : undefined,
        $or:[
          {name:{ $regex: this.search }},
          {code:{ $regex: this.search }},
        ]
      },
      page: this.page,
      limit: this.show,
      
      sort: { code: -1 },
      populate:"items"
    };
    console.log(this.filter1, query)
    axios
      .post(`${ADDRESS}/collections/paginate`, query)
      .then((response) => {
        this.docs = response.data.docs
        this.docs = this.docs.map(doc=>{
          return {
            ...doc,
            status:doc.isHighlight?"ไฮไลท์":doc.status
          }
        })
      });
  }
  mounted() {
    this.fetchData()
  }
}
