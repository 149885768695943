
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import { useStore } from "vuex"
import { confirmAction, time } from "@/decorators"
import ADDRESS from "@/settings"
import * as _ from "lodash"
import "leaflet/dist/images/marker-shadow.png";
@Options({
  components: {},
})
export default class HeritageForm extends Vue {
  store = useStore()
  filter1: string | null = null
  filter2: string | null = null
  filter3: string | null = null
  filter4: string | null = null
  filter5: string | null = null
  filter6: string | null = null
  filter7: string | null = null
  filter8: string | null = null
  search: string = ""
  status = "รายการทั้งหมด"
  docs: Array<any> = []
  glances: Array<any> = []
  page = 1
  pages = 3
  show = 20
  total = 0
  showAdvance: boolean = false
  statusOptions: Array<any> = [
    { icon: "fa-solid fa-circle", value: "เผยแพร่", color: '#00A325', weight: 'fw-bold' },
    { icon: "fa-solid fa-circle-minus", value: "ไม่เผยแพร่", color: 'grey', weight: 'fw-bold' },
    { icon: "fa-solid fa-star", value: "ไฮไลท์", color: '#008ED9', weight: 'fw-bold' },
    // { icon: "", value:"แบบร่าง", color:'grey', weight:'fw-light', border:'.border-0' },
  ]

  updatePage(i: number) {
    this.page = i;
    console.log(this.page)
    this.$nextTick(() => {
      this.fetchData()
    })
  }
  resolveLevel(data: Array<string> | string): string {
    if (typeof data === "string")
      return data
    else
      return data.join(", ")
  }

  get thailand() {
    const thailandObject = require("@/data/thailand.json")
    return _.values(thailandObject)
  }

  updateStatus(id: string, status: string) {
    axios.patch(`${ADDRESS}/heritage/update/${id}`, { status }).then(response => {
      console.log(response.data)
      this.fetchData()
    })
  }

  countstatus(status: string) {
    try {
      if (status == "รายการทั้งหมด") return this.glances.map(item => item.count ?? 0).reduce((a, b) => a + b, 0)
      else return this.glances.find(item => item._id == status).count ?? 0
    } catch (error) {
      return 0
    }
  }
  get type() {
    console.log("this.filter1", this.filter1)
    if (this.filter1 == undefined) return []
    switch (this.filter1) {
      case "ศิลปะการแสดง":
        return [
          "ดนตรี",
          "การแสดง",
          "ดนตรีและการแสดงในพิธีกรรม",
          "เพลงร้องพื้นบ้าน",
        ]
        break;
      case "งานช่างฝีมือดั้งเดิม":
        return [
          "ผ้าและผลิตภัณฑ์จากผ้า",
          "เครื่องจักสาน",
          "เครื่องรัก",
          "เครื่องปั้นดินเผา",
          "เครื่องโลหะ",
          "เครื่องไม้",
          "เครื่องหนัง",
          "เครื่องประดับ",
          "งานศิลปกรรมพื้นบ้าน",
          "ผลิตภัณฑ์อย่างอื่น",
        ]
        break;

      case "วรรรณกรรมพื้นบ้านและภาษา":
        return [
          "นิทานพื้นบ้าน",
          "ประวัติศาสตร์บอกเล่า",
          "บทสวดหรือบทกล่าวในพิธีกรรม",
          "บทร้องพื้นบ้าน",
          "สำนวนและภาษิต",
          "ปริศนาคำทาย",
          "ตำรา",
          "ภาษาไทย",
          "ภาษาถิ่น",
          "ภาษากลุ่มชาติพันธุ์",
        ]
        break;

      case "การเล่นพื้นบ้าน กีฬาพื้นบ้านและศิลปะการต่อสู้ป้องกันตัว":
        return [
          "การเล่นพื้นบ้าน",
          "กีฬาพื้นบ้าน",
          "ศิลปะการต่อสู้ป้องกันตัว",
        ]
        break;

      case "ความรู้และแนวปฏิบัติเกี่ยวกับธรรมชาติและจักรวาล":
        return [
          "อาหารและโภชนาการ",
          "การแพทย์แผนไทยและการแพทย์พื้นบ้าน",
          "โหราศาสตร์และดาราศาสตร์",
          "การจัดการทรัพยากรธรรมชาติ",
          "ชัยภูมิและการรตั้งถิ่นฐาน",
        ]
        break;

      case "แนวปฏิบัติทางสังคม พิธีกรรม ประเพณี และเทศกาล":
        return [
          "มารยาท",
          "ขนบธรรรมเนียมประเพณี",
          "งานเทศกาล"
        ]
        break;

      default:
        return []
        break;
    }
  }

  get today() {
    return new Date();
  }

  @confirmAction("คำเตือน!")
  deleteItem(id: string) {
    axios.delete(`${ADDRESS}/heritage/delete/${id}`).then((response) => {
      this.fetchData()
    })
    console.log("ลบแล้ว!")
  }

  // deleteItem(i:number) {
  //   this.docs.splice(i,1)
  //   console.log("ลบแล้ว!")
  // }

  searchData() {
    this.page = 1
    this.fetchData()
  }
  fetchData() {

    
    var statusFilter: any = {
      status: undefined
    }
    if (this.status !== "รายการทั้งหมด") {
      statusFilter.status = this.status
    }
    let query = {
      search: {
        ...statusFilter,
        branch: this.filter1 ?? undefined,
        type: this.filter2 ?? undefined,
        level: this.filter3 ?? undefined,
        persist: this.filter4 ?? undefined,
        inscribedYear: this.filter5 ?? undefined,
        province: this.filter6 ?? undefined,
        zone: this.filter7 ?? undefined,
        $or: [
          {
            code:
              this.search != ""
                ? { $regex: this.search, $options: "-i" }
                : undefined,
          },
          {
            name:
              this.search != ""
                ? { $regex: this.search, $options: "-i" }
                : undefined,
          },
        ],
      },
      page: this.page,
      select: "-question",
      limit: this.show,
      sort: {
        createdAt: "desc",
      }

    };
    console.log(query)
    axios
      .post(`${ADDRESS}/heritages/paginate`, query)
      .then((response) => {
        console.log({ data: response.data })
        this.docs = response.data.docs
        this.total = response.data.totalDocs
        this.pages = response.data.totalPages
        this.glances = response.data.glance
      });
  }
  mounted() {
    if (this.$route.query.page != undefined) this.page = parseInt(this.$route.query.page as string)
    this.fetchData()
  }
}
