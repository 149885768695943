
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import draggable from "vuedraggable";
@Options({
    props:{
        // value:String,
        // name:String,
        // disabled: Boolean,
    },
    Component: {
        draggable,
    },
})

export default class Hightlight extends Vue {
  name!:string
  value!:string
  val:string = ""
  ADDRESS = ADDRESS
  page =  1
  offset = 0;
  heritages: Array<any> = []
  updateVal(){
    this.val = this.value
  }
    fetchHeritage() {
    let query = {
      search:{
        status:"ไฮไลท์",
      },
      page: this.page,
      limit: 5,     
      sort: { code: -1 },
    };
    console.log(query)
    axios
      .post(`${ADDRESS}/heritages/paginate`, query)
      .then((response) => {
        console.log(response)
        this.heritages = response.data.docs
      });
  }
  pushOffset(){
    if(this.offset > (this.heritages.length-4) * 32){
      this.offset = 0;
    } else {
      this.offset += 32
    }
  }
  getFirstPhoto(items:Array<any>){
    for(const item of items){
      let split = item.path.split(".")
      let ext:string = split[split.length-1]
      if(['jpg','jpeg','png','gif','bmp'].includes(ext)){
        return item.path.split("\\").join("/")
      }
    }
    return "n/a"
  }
  stripHTML(text:string){
    return text.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, "").substring(0,200)+"..."
  }
  updated(){
    this.updateVal()
  }
  mounted(){
    this.updateVal()
    this.fetchHeritage()
  }
  emitUp(){
    this.$emit("update:modelValue", this.val)
    this.$emit("change")
  }
}
