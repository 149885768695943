
import { Options, Vue } from "vue-class-component";
import * as _ from "lodash";

@Options({
  components: { SaveIndicator },
  props: {
    saving: Boolean,
    edited: Boolean,
    createdAt: String,
  },
})
export default class SaveIndicator extends Vue {
  saving!:boolean
  edited!:boolean
}
