
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import draggable from "vuedraggable";
@Options({
  props: {
    // value:String,
    // name:String,
    // disabled: Boolean,
  },
  Component: {
    draggable,
  },
})
export default class Collecttion extends Vue {
  page = 1;
  collections: Array<any> = [];
  fetchCollection() {
    let query = {
      search: {
        status: "เผยแพร่",
        isHighlight: true,
      },
      page: this.page,
      limit: 8,
      sort: { code: -1 },
    };
    console.log(query);
    axios.post(`${ADDRESS}/collections/paginate`, query).then((response) => {
      console.log(response);
      this.collections = response.data.docs;
    });
  }
  mounted() {
    this.fetchCollection();
  }
}
