
import { Options, Vue } from "vue-class-component";
@Options({
  components: {},
  props: {
    modelValue: String,
    options: Array
  },
  directives:{
    clickOutside:{
      created: (el,binding) => {
        let clickAnywhere = (event:any) => {
          if(!(event.target === el || el.contains(event.target))){
            binding.value()
          }
        }
        document.body.addEventListener("click", clickAnywhere)
      }
    }
  }
})
export default class AccountList extends Vue {
  options!: Array<any>;
  active:boolean = false
  modelValue!: string;

  mounted(){
    
  }

  close(){
    this.active = false
  }


  
  emitUp(item:any){
    this.active = false
    this.$emit("update:modelValue", item.value)
    this.$emit("change", item.value)
  }
  get displaySelect(){ 
    try {
      let found = (this.options??[]).find(item => item.value === this.modelValue)
      // console.log("found", found, this.options, this.modelValue)
      if(found==undefined) throw "not found"
      else return found
    } catch (error) {
      return {
        icon:"",
        color:"black",
        value:"กรุณาเลือก..."
      }
    }
  }
}
