
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import { confirmAction } from "@/decorators";
import { DateTime } from "luxon";
@Options({
  components: {},
})
export default class articleList extends Vue {
  docs: Array<any> = [];
  glances: Array<any> = [];
  filter1: string | undefined = undefined;
  search: string = "";
  status = "รายการทั้งหมด";
  page = 1;
  pages: number = 0;
  show = 20;
  isHighlight: boolean | undefined = undefined;

  statusOptions: Array<any> = [
    {
      icon: "fa-solid fa-circle",
      value: "เผยแพร่",
      color: "#00A325",
      weight: "fw-bold",
    },
    {
      icon: "fa-solid fa-circle-minus",
      value: "ไม่เผยแพร่",
      color: "grey",
      weight: "fw-bold",
    },
    // { icon: "fa-solid fa-star", value:"ไฮไลท์", color:'#008ED9', weight:'fw-bold' },
    // { icon: "", value:"แบบร่าง", color:'grey', weight:'fw-light', border:'.border-0' },
  ];
  updateStatus(id: string, status: string) {
    let option: any = {};
    if (status == "เผยแพร่") option = { status: "เผยแพร่", isHighlight: false };
    else if (status == "ไม่เผยแพร่")
      option = { status: "ไม่เผยแพร่", isHighlight: false };
    else if (status == "ไฮไลท์")
      option = { status: "เผยแพร่", isHighlight: true };
    console.log(id, status, option);
    axios.patch(`${ADDRESS}/article/update/${id}`, option).then((response) => {
      console.log(response.data);
      this.fetchData();
    });
  }
  countstatus(status: string) {
    try {
      if (status == "รายการทั้งหมด")
        return this.glances
          .map((item) => item.count ?? 0)
          .reduce((a, b) => a + b, 0);
      else return this.glances.find((item) => item._id == status).count ?? 0;
    } catch (error) {
      return 0;
    }
  }
  @confirmAction("คุณแน่ใจหรือว่าต้องการลบรายการนี้")
  deleteItem(i: string) {
    axios.delete(`${ADDRESS}/article/delete/${i}`).then((response) => {
      console.log(response);
      this.fetchData();
    });
  }
  fetchData() {
    let query = {
      search: {
        status: this.status != "รายการทั้งหมด" ? this.status : undefined,
        category: this.filter1 ?? undefined,
        isHighlight: this.isHighlight,
        $or: [{ title: { $regex: this.search } }],
      },
      page: this.page,
      limit: this.show,
      sort: { createdAt: "desc" },
    };
    axios.post(`${ADDRESS}/articles/paginate`, query).then((response) => {
      console.log(response.data);
      this.docs = response.data.docs;
      this.glances = response.data.glance;
      this.pages = response.data.totalPages;
      this.docs = this.docs.map((doc) => {
        return {
          ...doc,
          status: doc.isHighlight ? "ไฮไลท์" : doc.status,
        };
      });
    });
  }
  mounted() {
    this.fetchData();
  }
}
