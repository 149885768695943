
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import PDFViewer from "pdf-viewer-vue";
import { useStore } from "vuex";

@Options({
  components: {
    PDFViewer,
  },
})
export default class List extends Vue {
  store = useStore()
  docs: Array<any> = [];
  page = 1;
  show = 100;
  search: string = "";
  pages: number = 0;
  ADDRESS = ADDRESS;
  edit(id: string) {
    alert(`/public/media/${id}`);
    this.$router.push(`/public/media/${id}`);
  }
  get thai(){
    return this.store.getters.thai
  }
  fetchData() {
    let query = {
      search: {
        status: "เผยแพร่",
        type:"ebook",
        $or:[
          {name:{ $regex: this.search }},
          // {detail:{ $regex: this.search }},
        ]
      },
      page: this.page,
      limit: this.show,
      sort: { code: -1 },
    };
    console.log(query);
    axios.post(`${ADDRESS}/medias/paginate`, query).then((response) => {
      console.log(response);
      this.docs = response.data.docs;
      this.pages = response.data.totalPages;
    });
  }
  mounted() {
    this.fetchData();
  }
}
