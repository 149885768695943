
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
@Options({
  components: {},
})
export default class structureMenu extends Vue{
  docs:Array<any> = [
  ]

  mounted(){
    this.fetchData()
  }
  fetchData(){
    axios.get(`${ADDRESS}/navigations/list`).then((response) => {
      console.log(response.data)
      this.docs = response.data
    });
  }
  createNewItem(){
    axios.post(`${ADDRESS}/navigation/create`,{
      name:"เมนูใหม่"
    }).then((response) => {
      console.log(response.data)
      this.fetchData()
    });
  }
}
