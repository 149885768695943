
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import "leaflet/dist/images/marker-shadow.png";
import * as _ from "lodash";
import { Watch } from "vue-property-decorator";
import JMMap from "@/components/JMMap.vue";
import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {},
})
export default class RequestForm extends Vue {
  isHighlight: boolean = false;
  doc: any = {
    name: "",
    code: "ระบบจะสร้างรหัสให้อัตโนมัติ",
    proposer: {},
    location: {
      type: "Point",
      coordinates: [100.5708937, 13.7670538],
    },
    items: [],
    uploadItems: [],
  };
  proposer: Array<any> = [];
  search: string = "";
  heritages: Array<any> = [];
  debouncedHandler: any;
  created() {
    this.debouncedHandler = _.debounce((event) => {
      console.log("New value:", event.target.value);
      this.fetchHeritage();
    }, 1000);
  }
  beforeUnmount() {
    this.debouncedHandler.cancel();
  }
  get today() {
    return new Date();
  }
  get thailand() {
    const thailandObject = require("@/data/thailand.json");
    return _.values(thailandObject);
  }
  getDistrict(name: string) {
    try {
      const districtObject = this.thailand.find(
        (province: any) => province.name.th == name
      ).amphoes;
      return _.values(districtObject);
    } catch (error) {
      return [];
    }
  }
  getSubDistrict(name: string) {
    try {
      const subDistrictObject = this.getDistrict(this.doc.province).find(
        (district: any) => district.name.th == name
      ).tambons;
      return _.values(subDistrictObject);
    } catch (error) {
      return [];
    }
  }
  fetchHeritage() {
    console.log("fetching...");
    axios
      .post(`${ADDRESS}/heritages/paginate`, {
        search: {
          name: {
            $regex: this.search,
          },
        },
        limit: 5,
        skip: 0,
        page: 1,
        select: {
          _id: 1,
          name: 1,
          code: 1,
          province: 1,
          branch: 1,
          level: 1,
          status: 1,
          createdAt: 1,
        },
      })
      .then((res) => {
        console.log(res);
        this.heritages = res.data.docs;
      });
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/community/get/${this.$route.params.id}`)
      .then((res) => {
        // console.log('re', res)
        console.log(res);
        this.doc = res.data.result;

        let jmmap = this.$refs.jmmap as JMMap;
        jmmap.initMap();
        jmmap.renderMap();
      });
  }
  mounted() {
    _.throttle(this.fetchHeritage, 5000);
    if (this.$route.params.id == undefined) {
      let jmmap = this.$refs.jmmap as JMMap;
      jmmap.initMap();
      jmmap.renderMap();
    } else {
      this.fetchData();
    }
  }
  submitForm(isDraft = false) {
    if (this.$route.params.id != undefined)
      axios
        .patch(`${ADDRESS}/community/update/${this.$route.params.id}`, {
          ...this.doc,
        })
        .then((response) => {
          const toast = useToast();
          toast.success("บันทึกข้อมูลแล้ว (ref:S-5)", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
          let savedDoc = response.data.result;
          console.log({ savedDoc });
          if (isDraft)
            this.$router.push(`/portal/community/edit/${savedDoc._id}`);
          else this.$router.push(`/portal/community`);
        });
    else
      axios
        .post(`${ADDRESS}/community/create`, {
          ...this.doc,
        })
        .then((response) => {
          console.log(response);
          const toast = useToast();
          toast.success("บันทึกข้อมูลแล้ว (ref:S-6)", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
          let savedDoc = response.data;
          console.log({ savedDoc });
          if (isDraft)
            this.$router.push(`/portal/community/edit/${savedDoc._id}`);
          else this.$router.push(`/portal/community`);
        });
  }
}
