
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { Watch } from "vue-property-decorator";

@Options({
  components: {},
  props:{
    id:String
    
  }
})
export default class JMCommunity extends Vue{
  id :string = ""
  ADDRESS = ADDRESS
  doc:any = {}

  fetchData() {
    axios
      .get(`${ADDRESS}/community/get/${this.id}`)
      .then((response) => {
        console.log('response', response.data.result)
        this.doc = response.data.result
      });
  }
  mounted() {
    this.fetchData()
  }
}
