import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        class: "form-control form-control-sm form-input",
        type: "number",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitUp && _ctx.emitUp(...args))),
        placeholder: "0.00",
        disabled: _ctx.disabled
      }, null, 40, _hoisted_3), [
        [
          _vModelText,
          _ctx.val,
          void 0,
          { number: true }
        ]
      ])
    ])
  ]))
}