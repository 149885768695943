import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Debug from '@/views/debug/Debug.vue'
import Home from '@/views/public/Home.vue'

//pubic
import Contact from '@/views/public/contact/Main.vue'
import CommunityMain from '@/views/public/community/Main.vue'
import CommunityList from '@/views/public/community/List.vue'
import CommunityDetail from '@/views/public/community/Detail.vue'
import Agencies from '@/views/public/community/Agencies.vue'
import CollectionMain from '@/views/public/collection/Main.vue'
import CollectionList from '@/views/public/collection/List.vue'
import CollectionDetail from '@/views/public/collection/Detail.vue'
import BlogMain from '@/views/public/blog/Main.vue'
import BlogList from '@/views/public/blog/List.vue'
import BlogDetail from '@/views/public/blog/Detail.vue'
// import CollectionDetail from '@/views/public/collection/Detail.vue'
import BlogForm from '@/views/public/blog/Form.vue'
import HeritageMain from '@/views/public/heritage/Main.vue'
import ListProvince from '@/views/public/heritage/ListProvince.vue'
import ListNational from '@/views/public/heritage/ListNational.vue'
import ListInternational from '@/views/public/heritage/ListInternational.vue'
import HeritageDetail from '@/views/public/heritage/Detail.vue'
import MediaMain from '@/views/public/media/Main.vue'
import MediaList from '@/views/public/media/List.vue'
import MediaDetail from '@/views/public/media/Detail.vue' 
import RegistrationMain from '@/views/public/register/Main.vue' 
import RegistrationForm from '@/views/public/register/Form.vue' 
import RegistrationVerify from '@/views/public/register/Verify.vue' 
import Article from '@/views/public/media/Article.vue'
import ArticleDetail from "@/views/public/media/ArticleDetail.vue"
import Research from '@/views/public/media/Research.vue'
import InfographicList from '@/views/public/media/InfographicList.vue'
import InfographicDetail from '@/views/public/media/InfographicDetail.vue'
import Registration from '@/views/Registration.vue' 
import Lawmanualform from '@/views/public/media/Lawmanualform.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Home',
    component: Home
  },
  {
    path: 'heritage/province',
    name: 'Heritage Province',
    component: ListProvince
  },
  {
    path: 'heritage/national',
    name: 'Heritage National',
    component: ListNational
  },
  {
    path: 'heritage/international',
    name: 'Heritage International',
    component: ListInternational
  },
  {
    path: 'heritage/detail/:id',
    name: 'Heritage Detail',
    component: HeritageDetail
  },
  {
    path:"heritage/detail/:id",
    name:"Heritage Detail",
    component:HeritageDetail
  },
  {
    path: 'community',
    name: 'Community',
    component: CommunityList
  },
  {
    path: 'community/detail/:id',
    name: 'Community Detail',
    component: CommunityDetail
  },
  {
    path: 'collection',
    name: 'Collection',
    component: CollectionList
  },
  {
    path: 'collection/detail/:id',
    name: 'Collection Detail',
    component: CollectionDetail
  },
  {
    path: 'agency',
    name: 'Agency',
    component: Agencies
  },
  
  {
    path: 'news',
    name: 'News',
    component: BlogList,
    // children:[
    //   {
    //     path:"detail/:id",
    //     name:"News Detail",
    //     component:BlogDetail
    //   }
    // ]
  },
  {
    path:"news/detail/:id",
    name:"News Detail",
    component:BlogDetail
  },
  {
    path: 'ebook',
    name: 'Ebook',
    component: MediaList
  },
  {
    path: 'ebook/detail/:id',
    name: 'Ebook Detail',
    component: MediaDetail
  },
  {
    path: 'contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: 'registration/main',
    name: 'Registration Main',
    component: RegistrationMain
  },
  {
    path: 'registration',
    name: 'Registration Form',
    component: RegistrationForm
  },
  {
    path: 'registration/verify/:id',
    name: 'Registration Verify',
    component: RegistrationVerify
  },
  {
    path: 'article',
    name: 'Article',
    component: Article
  },
  {
    path: 'article/detail/:id',
    name: 'Article Detail',
    component: ArticleDetail
  },
  {
    path: 'research',
    name: 'Research',
    component: Research
  },
  {
    path: 'infographic',
    name: 'Infographic List',
    component: InfographicList
  },
  {
    path: 'infographic/detail/:id',
    name: 'InfographicDetail',
    component: InfographicDetail
  },
  {
    path: 'lawmanualform',
    name: 'Lawmanualform',
    component: Lawmanualform
  },
]
export default routes