
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";

@Options({
  components: {},
})
export default class Detail extends Vue{
  doc:any = {
    items:[]
  }
  heritage:Array<any> = []
  ADDRESS = ADDRESS
  fetchData(){
    let query = {
      search:{},
      populate:"items"
    };
    axios.get(`${ADDRESS}/collection/get/${this.$route.params.id}`).then((res) => {
      // console.log('re', res)
      // console.log(res)
      this.doc = res.data.result;
      this.getHeritage();
    });
  }

  getFirstPhoto(items:Array<any>){
    for(const item of items){
      let split = item.path.split(".")
      let ext:string = split[split.length-1]
      if(['jpg','jpeg','png','gif','bmp'].includes(ext)){
        return item.path
      }
    }
    return "n/a"
  }

  getHeritage() {
    console.log('heritage',(this.doc??{}).items??[]);
    ((this.doc??{}).items??[]).forEach((el:any) => {
      console.log('heritage2',el)
      axios
        .get(`${ADDRESS}/heritage/get/${el}`)
        .then((response) => {
          console.log('heritage3', response.data)
          let prep = response.data
          this.heritage.push(prep)
        });
    })
  }

  stripHTML(text:string){
    return text.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, "").substring(0,175)+"..."
  }
  mounted() {
    this.fetchData();
  }
}
