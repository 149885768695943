
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import PDFViewer from "pdf-viewer-vue";
@Options({
  components: {
    PDFViewer
  },
})
export default class List extends Vue{
  doc:any = {}
  address = ADDRESS

  fetchData(){
    axios.get(`${ADDRESS}/media/get/${this.$route.params.id}`).then((res) => {
      console.log('re', res)
      console.log(res)
      this.doc = res.data.result;
    });
  }
  mounted() {
    this.fetchData();
  }
}
