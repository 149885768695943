
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import { useToast, POSITION } from "vue-toastification";
import { DocumentDocx } from "@/documents/docx";
@Options({
  components: {},
})
export default class RequestList extends Vue {
  doc: any = {
    items: [],
  };
  page = 1;
  show = 99999;
  showAdvance: boolean = false;
  get MP3Ready() {
    let items = this.doc.items.map(
      (item: any) => (item.mp1 ?? false) && (item.mp2 ?? false)
    );
    return !items.includes(false);
  }
  MP3NotReady() {
    const toast = useToast();
    toast.error("ยังจัดทำ มภ.01/มภ.02 ไม่สมบูรณ์", {
      timeout: 2000,
      position: POSITION.TOP_RIGHT,
    });
  }
  getData() {
    axios
      .get(`${ADDRESS}/request/get/${this.$route.params.id}`)
      .then((response) => {
        this.doc = response.data.result;
      });
  }
  saveUploadItems() {
    axios
      .patch(`${ADDRESS}/request/update/${this.$route.params.id}`, {
        uploadItems: this.doc.uploadItems,
      })
      .then((response) => {
        const toast = useToast();
        toast.success("บันทึกเอกสารที่อัปโหลดแล้ว", {
          timeout: 2000,
          position: POSITION.TOP_RIGHT,
        });
        this.getData();
      });
  }
  mounted() {
    this.getData();
  }

  async getPDF01() {
    const docx = new DocumentDocx();
    await docx.createMP01(this.doc);
    docx.save("มภ01.docx");
  }
  async getPDF02() {
    const docx = new DocumentDocx();
    await docx.createMP02(this.doc);
    docx.save("มภ02.docx");
  }
  async getPDF03() {
    const docx = new DocumentDocx();
    await docx.createMP03(this.doc);
    docx.save("มภ03.docx");
  }
}
