
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { APIKEY } from "@/settings";
import axios from "axios"

@Options({
  components: {
  },
  props: {},
})
export default class MyApp extends Vue {
  store = useStore();
  showPDPA = true;
  mounted(){
    console.log("App mounted")
    // document.title = `ฐานข้อมูลมรดกวัฒนธรรม ${this.store.getters.version}`
  }
  beforeMount() {
    axios.interceptors.request.use(function (config) {
      const token = APIKEY;
      config!.headers!.apikey = token;
      config!.headers!.token = token;

      return config;
    });
  }
}
