
import { Options, Vue } from "vue-class-component";
import L, { Icon } from "leaflet";
import "leaflet/dist/images/marker-shadow.png";
import * as _ from "lodash";

@Options({
  components: {},
  props: {
    index: Number,
    modelValue: Object,
    "onUpdate:modelValue": Function,
  },
})
export default class JMMap extends Vue {
  mymap: any;
  layer: any;
  zoom = 17;
  modelValue!: any;
  index!: number;
  coord: string = "";
  doc: any = {
    location: {
      coordinates: [100.5708937, 13.7670538],
    },
  };
  mounted() {
    // this.doc = {
    //   location: this.modelValue.location,
    // };
    if (this.modelValue != undefined) {
      this.doc.location = {
        coordinates: [
          this.modelValue.coordinates[0],
          this.modelValue.coordinates[1],
        ],
      };
    }

    this.initMap();
    this.renderMap();
  }
  emitUp() {
    this.$emit("update:modelValue", this.doc.location);
  }
  coordPasted() {
    try {
      let coord: any = this.coord.trim().split(" ").join("");
      coord = coord.split(",");
      coord[0] = parseFloat(coord[0]);
      coord[1] = parseFloat(coord[1]);
      this.doc.location.coordinates[0] = coord[1];
      this.doc.location.coordinates[1] = coord[0];
      this.updateMapAndMarker();
    } catch (error) {
      console.log(error);
    }
  }
  updateMapAndMarker() {
    let coordinates = this.doc.location.coordinates;
    this.mymap.panTo(new L.LatLng(coordinates[1], coordinates[0]));
    this.markerRender();
    this.emitUp();
  }
  initMap() {
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }
  renderMap() {
    let coordinates = [100.5708937, 13.7670538];
    try {
      coordinates = [
        this.doc.location.coordinates[0],
        this.doc.location.coordinates[1],
      ];
    } catch (error) {}
    console.log({ coordinates });
    var mymap = L.map(`map-${this.index}`).setView(
      [coordinates[1], coordinates[0]],
      this.zoom
    );
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(mymap);
    var greenIcon = L.icon({
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
    });

    var layerGroup = L.layerGroup().addTo(mymap);
    this.layer = layerGroup;
    this.mymap = mymap;
    this.markerRender();
    mymap.on("zoomend", () => {
      this.zoom = mymap.getZoom();
      this.markerRender();
    });
    let self = this;
    mymap.on("click", (e: any) => {
      this.doc.location.coordinates = [e.latlng.lng, e.latlng.lat];
      this.markerRender();
    });
  }
  markerRender() {
    let coordinates = [100.5708937, 13.7670538];
    let name = "";
    try {
      coordinates = [
        (this.doc.location ?? { coordinates: [100.5708937, 13.7670538] })
          .coordinates[0] ?? 100.5708937,
        (this.doc.location ?? { coordinates: [100.5708937, 13.7670538] })
          .coordinates[1] ?? 13.7670538,
      ];
    } catch (error) {}
    try {
      name = "ตำแหน่ง";
    } catch (error) {}
    this.layer.clearLayers();
    let content =
      `<div class="my-map-marker">
        <div class="icon-bar">
          <a><i class="fa-solid fa-location-dot"></i></a>
        ` +
      (this.mymap.getZoom() >= 8 ? `<span class="title">${name}</span>` : "");
    L.marker([coordinates[1], coordinates[0]], {
      icon: new L.DivIcon({
        className: "my-div-icon",
        html: content,
      }),
    }).addTo(this.layer);

    this.emitUp();
  }
}
