
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { Watch } from "vue-property-decorator";

@Options({
  components: {},
})
export default class Agencies extends Vue{
  doc:Array<any> = []
  ADDRESS = ADDRESS

  fetchData() {
    axios.get(`${ADDRESS}/setting/get-by-name/หน่วยงานที่เกี่ยวข้อง`).then((response) => {
      console.log(response);
      this.doc = response.data.result;
    });
  }
  mounted() {
    this.fetchData();
  }
}
