
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Quill from "quill";
import { v4 as uuidv4 } from "uuid";
import { touchDistance } from "@interactjs/utils/pointerUtils";
@Options({
  components: {},
  props: {
    modelValue: String,
    uuid: String,
    uid: String,
  },
})
export default class AccountList extends Vue {
  uuid!: string;
  uid!: string;
  modelValue!: string;
  compValue = ""
  editor: any;
  initilized: boolean = false;
  created() {
    // this.uuid = uuidv4();
  }
  mounted() {
    this.initQuill();

    if(this.compValue!==this.modelValue){
      this.compValue = this.modelValue;
    }
  }
  initQuill() {
    var toolbarOptions = [
      ["bold", "italic", "underline", "strike"],
      ["link", "image", "video"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
    ];

    var options = {
      modules: {
        toolbar: toolbarOptions,
      },
      imageCompress: {
        quality: 0.65,
        maxWidth: 550,
        imageType: "image/jpeg", // default
        debug: false, // default
      },
      theme: "snow",
    };
    var container = document.getElementById(`editor-${this.uid}`);
    this.editor = new Quill(container!, options);

    this.editor.on("text-change", () => {
      this.emitUp();
    });
    if(this.compValue!==this.modelValue){
      this.editor.root.innerHTML = this.modelValue ?? "";
    }
  }
  updated() {
    if(this.compValue!==this.modelValue){
      this.editor.root.innerHTML = this.modelValue??"";

      if(this.editor.root.innerHTML==="<p>undefined</p>") this.editor.root.innerHTML = ""
    }
    // if (!this.initilized) {
    //   this.initilized = true;
    //   this.editor.root.innerHTML = this.modelValue;
    // }
  }

  emitUp() {
    this.compValue = this.editor.root.innerHTML
    this.$emit("update:modelValue", this.compValue);
  }

  /*
  updated() {
    if (!this.initilized) {
        this.editor.root.innerHTML = this.modelValue??"";
        this.initilized = true;
      if (this.modelValue != undefined) {
      }
    }
  }
  */
}
