
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings"
import contenteditable from "vue-contenteditable";

@Options({
  components: {
    contenteditable,
  },
  props:{
    modelValue:Object,
    "update:modelValue":Function
  }
})
export default class MP2PDF extends Vue {
  item:any = {
    proposer: {},
    consent: {},
    imageDetail: {},
    coordinator: [{}]
  }
  doc: any = {
    proposer:{},
    consent:{},
    coordinator:[],
    items: [
      {
        name:"",
        level: "",
        data:{},
      }
    ],
  };
  print(){
    window.print()
  }
  fetchData(){
    axios.get(`${ADDRESS}/request/get/${this.$route.params.id}`).then((res) => {
      console.log('request',res)
      this.doc = res.data.result;
    });
  } 
  mounted(){
    this.fetchData()
  } 
}
