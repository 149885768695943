
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import draggable from "vuedraggable";
import { Watch } from "vue-property-decorator";
import { useToast, POSITION } from "vue-toastification";
@Options({
  components: {
    draggable,
  },
})
export default class Form extends Vue {
  doc: any = {
    member:[{
      name:"",
      text1:"",
      text2:""
    }]
  };
  ADDRESS = ADDRESS
  @Watch("doc.member", {deep:true})
  onDocMemberChange(val: any) {
    if (!Array.isArray(this.doc.member)) this.doc.member = [];
    if(this.doc.member.length==0) this.pushMember(0)
  }

  pushMember(index:number) {
    if (!Array.isArray(this.doc.member)) this.doc.member = [];
    this.doc.member.splice(index+1, 0, {
      name: "",
      text1: "",
      text2: "",
    });
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  fetchData() {
    axios.get(`${ADDRESS}/setting/get/${this.$route.params.id}`).then((res) => {
      console.log(res);
      this.doc = res.data.result;
    });
  }
  submitForm() {
    if (this.$route.params.id != undefined)
      axios
        .patch(`${ADDRESS}/setting/update/${this.$route.params.id}`, {
          ...this.doc,
        })
        .then((response) => {
          const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-29)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
          this.$router.push(`/portal/display`);
        });
    else
      axios
        .post(`${ADDRESS}/setting/create`, {
          ...this.doc,
        })
        .then((response) => {
          console.log(response);
          const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-30)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
          this.$router.push(`/portal/display`);
        });
  }
}
