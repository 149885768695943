import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid bg-white pb-5 mb-5 px-5 pt-5" }
const _hoisted_2 = { class: "mt-5 mb-2 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFViewer = _resolveComponent("PDFViewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.doc.name), 1),
    _createVNode(_component_PDFViewer, {
      source: `${_ctx.address}/${(_ctx.doc.uploadItem??{}).path}`,
      style: {"min-height":"70vh"}
    }, null, 8, ["source"])
  ]))
}