
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";

@Options({
  components: {},
})
export default class Infographic extends Vue {
  docs: Array<any> = [];
  page = 1;
  show = 100;
  search: string = "";
  pages: number = 0;
  textSearch:string = "";
  total: number = 0;
  ADDRESS = ADDRESS;
  edit(id: string) {
    alert(`/public/media/${id}`);
    this.$router.push(`/public/media/${id}`);
  }
  get filtered(){
    return this.docs.filter(doc=>doc.name.search(this.textSearch) > -1)
  }
  fetchData() {
    let query = {
      search: {
        status: "เผยแพร่",
        type: "infographic",
        $or:[
          {name:{ $regex: this.search }},
          // {detail:{ $regex: this.search }},
        ]
      },
      page: this.page,
      limit: this.show,
      sort: { code: -1 },
    };
    console.log(query);
    axios.post(`${ADDRESS}/medias/paginate`, query).then((response) => {
      console.log(response);
      this.docs = response.data.docs;
      this.pages = response.data.totalPages;
    });
  }
  mounted() {
    this.fetchData();
  }
}
