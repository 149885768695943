import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "container border bg-white p-0 pb-3 pt-5" }
const _hoisted_3 = { class: "px-5 py-4" }
const _hoisted_4 = { class: "text-center mb-4" }
const _hoisted_5 = {
  class: "animation-change-carousel",
  id: "main-infographic-carousel"
}
const _hoisted_6 = { class: "d-flex justify-content-center" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "d-flex justify-content-center" }
const _hoisted_9 = { class: "d-flex justify-content-center align-items-center mt-5 px-3" }
const _hoisted_10 = { class: "row mx-3" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.doc.name), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("img", {
            class: "infographic-detail img-fluid",
            src: `${_ctx.ADDRESS}/${_ctx.active.path}`
          }, null, 8, _hoisted_7)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", null, _toDisplayString(_ctx.active.description), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("i", {
          class: "fal fa-circle-chevron-left fa-2x d-none d-lg-block icon cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.prev();})
        }),
        _createElementVNode("div", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.uploadItems, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-12 col-lg public-carousel-2-image mb-3 cursor-pointer",
              onClick: ($event: any) => {_ctx.restartAnimation(item,index);}
            }, [
              _createElementVNode("img", {
                src: `${_ctx.ADDRESS}/${item.path}`,
                class: _normalizeClass({'border border-primary':(_ctx.ci===index)})
              }, null, 10, _hoisted_12)
            ], 8, _hoisted_11))
          }), 256))
        ]),
        _createElementVNode("i", {
          class: "fal fa-circle-chevron-right fa-2x d-none d-lg-block icon cursor-pointer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.next();})
        })
      ])
    ])
  ]))
}