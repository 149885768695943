
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { useToast, POSITION } from "vue-toastification";
@Options({
  components: {},
})
export default class DocumentForm extends Vue{
  doc:any = {
    uploadItems:[]
  }

  submitForm(){
    if(this.$route.params.id!=undefined) 
    axios
      .patch(`${ADDRESS}/uploaddoc/update/${this.$route.params.id}`, {
        ...this.doc,
      })
      .then((response) => {
        console.log(response)
        const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-23)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
        // this.$router.push(`/portal/request-management/document`)
      });
    else
    axios
      .post(`${ADDRESS}/uploaddoc/create`, {
        ...this.doc,
      })
      .then((response) => {
        console.log(response)
        const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-24)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
        // this.$router.push(`/portal/request-management/document`)
      });
  }
  fetchData(){
    axios.get(`${ADDRESS}/uploaddoc/get/${this.$route.params.id}`).then((res) => {
      console.log(res)
      this.doc = res.data.result;
    });
  }
  mounted(){
    if(this.$route.params.id != undefined) this.fetchData();
  }
}
