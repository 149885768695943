
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings"
import PDFViewer from 'pdf-viewer-vue'
import * as _ from "lodash"

@Options({
  components: {
    PDFViewer
  },
})
export default class HeritageForm extends Vue {
  engVersion: boolean = true;
  ADDRESS = ADDRESS
  uploaded:string = ""
  doc:any = {}
  item:any = {}
  get today() {
    return new Date();
  }
  mounted(){
    this.fetchData()
  }
  fetchData(){
    axios.get(`${ADDRESS}/request/item/${this.$route.params.id}`).then((res) => {
      console.log(res)
      this.doc = res.data.doc;
      this.item = res.data.item
      if(this.item._id !==undefined) this.$router.push(`/portal/request/mp${this.$route.params.num}/${this.$route.params.id}`)
    });
  }  
}
