
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings"
@Options({
  components: {},
})

export default class UserForm extends Vue {
  doc:any = {}
  verify:string=""
  showPassword:boolean = true

  show() {
    this.showPassword = !this.showPassword
    console.log('showPassword',this.showPassword)
  }
  submitReset() {
    axios 
      .post(`${ADDRESS}/user/reset2`, {
        _id : this.$route.params.id,
        verificationCode: this.$route.params.verify,
        newPassword: this.doc.password,
      })
      .then((response) => {
        console.log(response)
        if(response.data.status === "error"){
          alert("รหัสยืนยันหรือผูใช้ไม่ถูกต้อง")
          // this.$router.push(`/reset2`)
        } else {
          alert("เปลี่ยนรหัสผ่านแล้ว");
          this.$router.push(`/login`)
        }
      });
  }
  // fetchUser() {
  //   axios.get(`${ADDRESS}/user/get/${this.$route.params.id}`)
  //   .then((response) => {
  //     // console.table(response)
  //     this.verify = response.data.result.verificationCode
  //   })
  // }
  // mounted() {
  //   this.fetchUser()
  // }
}
