
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { confirmAction, time } from "@/decorators"
@Options({
  components: {},
})
export default class List extends Vue{
  docs:Array<any> = []
  page =  1
  pages: number = 0;
  show = 20

  @confirmAction("คุณต้องการลบรายการ Data Setting?")
  deleteItem(i:string) {
    // console.log(i)
    axios.delete(`${ADDRESS}/setting/delete/${i}`).then((response) => {
      console.log(response)
      this.fetchData();
    });
  }
  fetchData() {
    let query = {
      page: this.page,
      limit: this.show,   
      sort: { category: -1 }
    };
    axios
      .post(`${ADDRESS}/settings/paginate`, query)
      .then((response) => {
        this.docs = response.data.docs
      });
  }
  mounted() {
    this.fetchData()
  }
}
