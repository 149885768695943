
import { Options, Vue } from "vue-class-component";
import draggable from "vuedraggable";
@Options({
  props:{
    modelValue:String,
    name:String,
    disabled: Boolean,
  },
  components: {
    draggable,
  },
})
export default class BudgetComponentInputNumber extends Vue {
  name!:string
  modelValue!:string
  val:string = ""
  disabled:boolean = false

  updateVal(){
    this.val = this.modelValue??""
  }
  mounted(){
    this.updateVal()
  }
  emitUp(){
    console.log(this.val)
    this.$emit("update:modelValue", this.val)
    this.$emit("change")
  }
}
