
import { Options, Vue } from "vue-class-component";
import L, { Icon } from "leaflet";
import axios from "axios";
import ADDRESS from "@/settings";
import "leaflet/dist/images/marker-shadow.png";
import * as _ from "lodash";
import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {},
})
export default class HeritageForm extends Vue {
  engVersion: boolean = true;
  isHidden = true;
  mymap: any;
  layer: any;
  zoom = 17;
  doc: any = {
    name: "",
    code: "ระบบจะสร้างรหัสให้อัตโนมัติ",
    location: {
      coordinates: [100.5708937, 13.7670538],
    },
    proposer: {},
  };
  proposer: Array<any> = [];

  get today() {
    return new Date();
  }
  get thailand() {
    const thailandObject = require("@/data/thailand.json");
    return _.values(thailandObject);
  }
  getDistrict(name: string) {
    try {
      const districtObject = this.thailand.find(
        (province: any) => province.name.th == name
      ).amphoes;
      return _.values(districtObject);
    } catch (error) {
      return [];
    }
  }

  getSubDistrict(name: string, field: string) {
    try {
      const subDistrictObject = this.getDistrict(this.doc[field].province).find(
        (district: any) => district.name.th == name
      ).tambons;
      return _.values(subDistrictObject);
    } catch (error) {
      return [];
    }
  }
  mounted() {
    this.fetchData();
  }
  fetchData() {
    axios.get(`${ADDRESS}/request/get/${this.$route.params.id}`).then((res) => {
      console.log(res);
      this.doc = res.data.result;
      if (this.doc.proposer == undefined) this.doc.proposer = {};
    });
  }
  submitForm() {
    if (this.$route.params.id != undefined)
      axios
        .patch(`${ADDRESS}/request/update/${this.$route.params.id}`, {
          ...this.doc,
          mp3: true,
          status: "รอตรวจสอบ",
          step: 1,
        })
        .then((response) => {
          const toast = useToast();
          toast.success("บันทึกข้อมูลแล้ว (ref:S-19)", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
        });
    else
      axios
        .post(`${ADDRESS}/request/create`, {
          ...this.doc,
        })
        .then((response) => {
          const toast = useToast();
          toast.success("บันทึกข้อมูลแล้ว (ref:S-20)", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
        });
  }
  updateBoard(){
    if(this.doc.proposer.province==="กรุงเทพมหานคร") this.doc.boardMember = this.bangkokBoard
    else this.doc.boardMember = this.ruralBoard
  }
  bangkokBoard: Array<any> = [
    {
      name: "",
      boardPosition: "ประธานกรรมการ",
      position: "ปลัดกรุงเทพมหานคร",
    },
    {
      name: "",
      boardPosition: "กรรมการโดยตำแหน่ง",
      position: "ผู้อำนวยการกองกิจการเครือข่ายทางวัฒนธรรม กรมส่งเสริมวัฒนธรรม",
    },
    {
      name: "",
      boardPosition: "กรรมการโดยตำแหน่ง",
      position: "ผู้อำนวยการสถาบันวัฒนธรรมศึกษา กรมส่งเสริมวัฒนธรรม",
    },
    {
      name: "",
      boardPosition: "กรรมการโดยตำแหน่ง",
      position: "ผู้อำนวยการสำนักงานเขตพื้นที่การศึกษาประถมศึกษา กรุงเทพมหานคร",
    },
    {
      name: "",
      boardPosition: "กรรมการโดยตำแหน่ง",
      position:
        "ผู้อำนวยการสำนักงานส่งเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัย กรุงเทพมหานคร",
    },
    {
      name: "",
      boardPosition: "กรรมการโดยตำแหน่ง",
      position: "ผู้อำนวยการสำนักการศึกษา กรุงเทพมหานคร",
    },
    {
      name: "",
      boardPosition: "กรรมการโดยตำแหน่ง",
      position:
        "ผู้อำนวยการสำนักวัฒนธรรมจังหวัดกีฬาและการท่องเที่ยว กรุงเทพมหานคร",
    },
    {
      name: "",
      boardPosition: "กรรมการโดยตำแหน่ง",
      position: "ประธานสภาวัฒนธรรม กรุงเทพมหานคร",
    },
    {
      name: "",
      boardPosition: "กรรมการผู้ทรงคุณวุฒิ",
      position: "-",
    },
    {
      name: "",
      boardPosition: "กรรมการผู้ทรงคุณวุฒิ",
      position: "-",
    },
    {
      name: "",
      boardPosition: "กรรมการผู้ทรงคุณวุฒิ",
      position: "-",
    },
    {
      name: "",
      boardPosition: "กรรมการผู้ทรงคุณวุฒิ",
      position: "-",
    },
    {
      name: "",
      boardPosition: "กรรมการผู้ทรงคุณวุฒิ",
      position: "-",
    },
    {
      name: "",
      boardPosition: "กรรมการผู้ทรงคุณวุฒิ",
      position: "-",
    },
  ];

  ruralBoard: Array<any> = [
    {
      name: "",
      boardPosition: "ประธานกรรมการ",
      position: "ผู้ว่าราชการจังหวัด",
    },
    {
      name: "",
      boardPosition: "กรรมการโดยตำแหน่ง",
      position: "วัฒนธรรมจังหวัด",
    },
    {
      name: "",
      boardPosition: "กรรมการโดยตำแหน่ง",
      position: "ผู้อำนวยการสำนักงานเขตพื้นที่การประถมศึกษา เขต 1",
    },
    {
      name: "",
      boardPosition: "กรรมการโดยตำแหน่ง",
      position: "ผู้อำนวยการสำนักงานส่งเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัยจังหวัด",
    },
    {
      name: "",
      boardPosition: "กรรมการโดยตำแหน่ง",
      position: "นายกองค์การบริหารส่วนจังหวัด",
    },
    {
      name: "",
      boardPosition: "กรรมการโดยตำแหน่ง",
      position: "ประธานสภาวัฒนธรรมจังหวัด",
    },
    {
      name: "",
      boardPosition: "กรรมการผู้ทรงคุณวุฒิ",
      position: "-",
    },
    {
      name: "",
      boardPosition: "กรรมการผู้ทรงคุณวุฒิ",
      position: "-",
    },
    {
      name: "",
      boardPosition: "กรรมการผู้ทรงคุณวุฒิ",
      position: "-",
    },
    {
      name: "",
      boardPosition: "กรรมการผู้ทรงคุณวุฒิ",
      position: "-",
    },
    {
      name: "",
      boardPosition: "กรรมการผู้ทรงคุณวุฒิ",
      position: "-",
    },
    {
      name: "",
      boardPosition: "กรรมการผู้ทรงคุณวุฒิ",
      position: "-",
    },
  ];
}
