
import ADDRESS from "@/settings";
import { useSlots } from "@vue/runtime-core";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
@Options({
  components: {},
})
export default class TopNavigation extends Vue {
  store = useStore()
  docs: Array<any> = []
  showNavbar = false
  dropdown:any = {
    heritage: false,
    agency: false,
    media: false,
  }

  toggleLanguage(){
    this.store.commit("toggleLanguage")
  }

  get thai(){
    return this.store.getters.thai
  }

  toggleNavBar(){
    this.showNavbar = !this.showNavbar
  }
  toggleDropdown(name:string){
    try {
      this.dropdown[name] = !this.dropdown[name]
    } catch (error) {
      
    }
  }
  turnOnDropdown(name:string){
    try {
      this.dropdown[name] = true
    } catch (error) {
      
    }
  }
  turnOffDropdown(name:string){
    try {
      this.dropdown[name] = false
    } catch (error) {
      
    }
  }
  getDropdown(name:string){
    return this.dropdown[name]
  }

  mounted(){
    this.fetchData()
  }
  fetchData(){
    axios.get(`${ADDRESS}/navigation/get-by-name/Header`).then((response) => {
      // console.log(response.data)
      // if(!this.store.state.dataList.includes('topnav')) {
      //   this.store.commit("setDataList",'topnav')
      // }
      this.store.commit("setDataList",'topnav')
      try {
        this.docs = response.data.result.children
      } catch (error) {
        this.docs = []
      }
    });
  }

}
