
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings";
import { useToast, POSITION } from "vue-toastification";
@Options({
  components: {},
})
export default class Other extends Vue{
  doc:any = {
    member:[
    {
    name:"icon",
    category:"Other",
    value:""
  },
  {
    name:"siteName",
    category:"Other",
    value:""
  },
  {
    name:"siteDescription",
    category:"Other",
    value:""
  },
  {
    name:"organizationAddress",
    category:"Other",
    value:""
  }]
  }
  ADDRESS = ADDRESS
  id:string=""

  mounted() {
    this.fetchData();
  }
  fetchData() {
    axios.get(`${ADDRESS}/setting/get-by-name/other`).then((res) => {
      console.log(res);
      this.doc = res.data.result;
      if(this.doc.member.length == 0 ){
        this.doc.member = [{},{},{},{}]
      }
    });
  }
  submitForm() {
    axios
      .patch(`${ADDRESS}/setting/update/${this.doc._id}`, {
        ...this.doc,
      })
      .then((response) => {
        console.log(response)
        const toast = useToast();
    toast.success("32!", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT
    });
      });
  }
}
