
import { Options, Vue } from "vue-class-component";
import axios from "axios"
import ADDRESS from "@/settings"
@Options({
  components: {},
})

export default class UserForm extends Vue {
  doc:any = {}
  submitForm() {
      console.log(this.$route.params.id)
      this.doc.id = this.$route.params.id
      axios
        .post(`${ADDRESS}/user/verification`, this.doc)
        .then((response) => {
          console.log(response.data.status)
          if(response.data.status === "error"){
            alert("พบปัญหาในการลงทะเบียน: " + response.data.message)
          } else {
            alert("ตรวจสอบรหัสยืนยันตัวตนถูกต้อง เปิดใช้งานบัญชีของคุณแล้ว");
            // console.log('res',response.data)
            // this.doc = response.data;
          }
          // this.$router.push(`/portal/media`)
        });
  }
  // fetchData(){
  //   axios.get(`${ADDRESS}/user/get/${this.$route.params.id}`).then((res) => {
  //     console.log('re', res)
  //     this.doc = res.data;
  //   });
  // }
  // mounted(){
  //   this.fetchData();
  // }
}
