
import ADDRESS from "@/settings";
import axios from "axios";
import { first } from "lodash";
import { Options, Vue } from "vue-class-component";
@Options({
  components: {
    modelValue: String
  },
})
export default class JMUser extends Vue {
  users:Array<any> = []
  search: string = "";
  handleSearch(){
    if(this.search.length >= 3) this.fetchData()
  }
  emitUp(user:any){
    this.$emit("update:modelValue",user)
    this.search = user.fullName
    this.users = []
  }
  fetchData(){
    console.log("searching...")
    let query = {
      search: {
        $or:[
          {
          firstName:{ $regex: this.search }
          },
          {
          lastName:{ $regex: this.search }
          },
        ]
      },
      limit: 20,
      page: 1
    }
    axios.post(`${ADDRESS}/users/paginate`,query).then(response=>{
      console.log(response.data)
      this.users = response.data.docs
    })
  }
}
