
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import * as _ from "lodash";
import { useStore } from "vuex";
import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {},
})
export default class RequestForm extends Vue {
  isHighlight: boolean = false;
  store = useStore();
  reply: any = {};
  doc: any = {
    code: "ระบบจะสร้างรหัสให้อัตโนมัติ",
    items: [],
  };
  email: any = {
    to: "",
    subject: "",
    body: "",
  };
  search: string = "";
  heritages: Array<any> = [];
  debouncedHandler: any;

  async sendMail() {
    //.patch(`${ADDRESS}/contact-reply/${this.$route.params.id}`, {
    await axios.post(`${ADDRESS}/email-reply-simple`, this.email);
    axios
      .patch(`${ADDRESS}/contact-reply/${this.doc._id}`, {
        direction: "in",
        description: this.email.body,
      })
      .then((response) => {
        console.log(response.data);
        const toast = useToast();
        toast.success("อัพเดทสถานะ...", {
          timeout: 2000,
          position: POSITION.BOTTOM_RIGHT,
        });
      });
  }
  get mailtoLink() {
    var mailToLink = `mailto:${this.getEmail(this.doc)}?subject=${
      this.doc.code
    }: คำถามตอบกลับจากระบบฐานข้อมูลมรดกภูมิปัญญาทางวัฒนธรรม-กรมส่งเสริมวัฒนธรรม&body=`;
    return mailToLink;
  }
  getNameFromMember(item: any) {
    try {
      return `${item.fromMember.firstName} ${item.fromMember.lastName}`;
    } catch (error) {
      return item.name;
    }
  }
  getEmailFromMember(item: any) {
    try {
      return `${item.fromMember.email}`;
    } catch (error) {
      return item.email;
    }
  }
  getTelephoneFromMember(item: any) {
    try {
      return `${item.fromMember.telephone}`;
    } catch (error) {
      return item.telephone;
    }
  }
  getName(item: any) {
    try {
      return `${item.from.firstName} ${item.from.lastName}`;
    } catch (error) {
      return item.name;
    }
  }
  getSector(item: any) {
    try {
      return `${item.from.sector}`;
    } catch (error) {
      return "กรมส่งเสริมวัฒนธรรม";
    }
  }

  getEmail(item: any) {
    try {
      return `${item.from.email}`;
    } catch (error) {
      return item.email;
    }
  }
  getTelephone(item: any) {
    try {
      return `${item.from.telephone}`;
    } catch (error) {
      return item.telephone;
    }
  }

  created() {
    this.debouncedHandler = _.debounce((event) => {
      console.log("New value:", event.target.value);
    }, 1000);
  }
  beforeUnmount() {
    this.debouncedHandler.cancel();
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  get today() {
    return new Date();
  }
  fetchData() {
    axios.get(`${ADDRESS}/contact/get/${this.$route.params.id}`).then((res) => {
      console.log("re", res.data);
      this.doc = res.data.result;
      try {
        this.email.to = this.doc.from.email
        this.email.subject = `[${this.doc.code}] ${this.doc.type}`
      } catch (error) {
        
      }
    });
  }
  submitForm() {
    axios
      .patch(`${ADDRESS}/contact-reply/${this.$route.params.id}`, {
        ...this.reply,
        direction:
          this.store.state.userId == (this.doc.fromMember ?? {})._id
            ? "out"
            : "in",
        //  thamStatus:this.store.state.userId == (this.doc.fromMember??{})._id ? "ส่งแล้ว" : "ตอบกลับแล้ว",
        //  thobStatus: this.store.state.userId == (this.doc.fromMember??{})._id ? "-" : "ตอบกลับแล้ว"
      })
      .then((response) => {
        const toast = useToast();
        toast.success("บันทึกข้อมูลแล้ว (ref:S-7)", {
          timeout: 2000,
          position: POSITION.BOTTOM_RIGHT,
        });
        this.fetchData();
        // this.$router.push(`/portal/contact`)
      });
  }
}
