
import { Options, Vue } from "vue-class-component";
import { Sketch } from "@ckpack/vue-color";
import { Watch } from "vue-property-decorator";
@Options({
  props: {
    modelValue: String,
  },
  components: {
    Sketch,
  },
  directives: {
    clickOutside: {
      created: (el, binding) => {
        let clickAnywhere = (event: any) => {
          if (!(event.target === el || el.contains(event.target))) {
            binding.value();
          }
        };
        document.body.addEventListener("click", clickAnywhere);
      },
    },
  },
})
export default class JMColorPicker extends Vue {
  modelValue!: string;
  active = false;
  colors: any | string = "#194D33A8";
  color:string = ""
  @Watch("colors")
  updateColor() {
    try {
      console.log("colors")
      this.color = this.colors.hex;
      this.$emit("update:modelValue",this.color)
    } catch (error) {}
  }
  mounted(){
    this.color = this.modelValue
  }
  updated(){
    if(this.color!==this.modelValue)
      this.color = this.modelValue
  }

  updateValue(ev: any) {
    console.log(ev);
  }
  close() {
    this.active = false;
  }
}
