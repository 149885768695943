import MyDocument from "../index";
import {
  Document,
  ImageRun,
  Packer,
  Paragraph,
  AlignmentType,
  UnderlineType,
  HorizontalPositionAlign,
  VerticalPositionAlign,
  PageOrientation,
  TextRun,
  Table,
  TableCell,
  TableRow,
  WidthType,
  Alignment,
  FrameAnchorType,
  BorderStyle,
  TabStopType,
} from "docx";

let stripHTML = (text: string) => {
  return text.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, "");
};

export class DocumentDocx extends MyDocument {
  _doc!: Document;
  fontName = "TH Sarabun New";
  arr: Array<any> = [];
  defaultTabStop = [
    {
      type: TabStopType.LEFT,
      position: 500,
    },
  ]
  TabStop = [
    {
      type: TabStopType.LEFT,
      position: 150,
    },
  ]
  tableNoBorder = {
    top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
  }
  async createMP01(doc: any): Promise<any> {
    this.init = true;
    const font = { name: this.fontName }
    const TR_paragraph = {
      size: 15 * 2,
      font
    }
    const TR_heading1 = {
      size: 15 * 2,
      bold: true,
      font
    }
    const tableBody = doc.items.map((item: any, i: number) => {
      return new TableRow({
        children: [
          new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: String(i + 1), ...TR_paragraph })] })] }),
          new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: item.name ?? "", ...TR_paragraph })] })] }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({ text: ((item.branch ?? []).includes('วรรณกรรมพื้นบ้านและภาษา') ? "✓" : "☐") + " วรรณกรรมพื้นบ้านและภาษา", ...TR_paragraph }),
                ]
              }),
              new Paragraph({
                children: [
                  new TextRun({ text: ((item.branch ?? []).includes('ศิลปะการแสดง') ? "✓" : "☐") + " ศิลปะการแสดง", ...TR_paragraph }),
                ]
              }),
              new Paragraph({
                children: [
                  new TextRun({ text: ((item.branch ?? []).includes('แนวปฏิบัติทางสังคม พิธีกรรม ประเพณี และเทศกาล') ? "✓" : "☐") + " แนวปฏิบัติทางสังคม พิธีกรรม ประเพณี และเทศกาล", ...TR_paragraph }),
                ]
              }),
              new Paragraph({
                children: [
                  new TextRun({ text: ((item.branch ?? []).includes('ความรู้และแนวปฏิบัติเกี่ยวกับธรรมชาติและจักรวาล') ? "✓" : "☐") + " ความรู้และแนวปฏิบัติเกี่ยวกับธรรมชาติและจักรวาล", ...TR_paragraph }),
                ]
              }),
              new Paragraph({
                children: [
                  new TextRun({ text: ((item.branch ?? []).includes('งานช่างฝีมือดั้งเดิม') ? "✓" : "☐") + " งานช่างฝีมือดั้งเดิม", ...TR_paragraph }),
                ]
              }),
              new Paragraph({
                children: [
                  new TextRun({ text: ((item.branch ?? []).includes('การเล่นพื้นบ้าน กีฬาพื้นบ้านและศิลปะการต่อสู้ป้องกันตัว') ? "✓" : "☐") + " การเล่นพื้นบ้าน กีฬาพื้นบ้านและศิลปะการต่อสู้ป้องกันตัว", ...TR_paragraph }),
                ]
              }),
            ]
          }),
          new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: stripHTML(item.information??''), ...TR_paragraph })] })] }),
          new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: stripHTML(item.location??''), ...TR_paragraph })] })] }),
          new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: item.successor.map((s: any) => s.name).join(", \r\n"), ...TR_paragraph })] })] }),
          new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: item.status === 'ปฏิบัติอย่างแพร่หลาย' ? "✓" : "", ...TR_paragraph })] })] }),
          new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: item.status === 'เสี่ยงต่อการสูญหาย' ? "✓" : "", ...TR_paragraph })] })] }),
          new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: item.status === 'ไม่มีปฏิบัติแล้ว' ? "✓" : "", ...TR_paragraph })] })] }),
          new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: stripHTML(item.note??''), ...TR_paragraph })] })] }),
        ]
      })
    })
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "ลำดับที่", ...TR_heading1 })] })], rowSpan: 2 }),
            new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "ชื่อรายการ", ...TR_heading1 })] })], rowSpan: 2 }),
            new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "ลักษณะ", ...TR_heading1 })] })], rowSpan: 2 }),
            new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "ข้อมูลโดยสังเขป", ...TR_heading1 })] })], rowSpan: 2 }),
            new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "พื้นที่ปฏิบัติ", ...TR_heading1 })] })], rowSpan: 2 }),
            new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "ชื่อผู้ที่ถือปฏิบัติและผู้สืบทอด", ...TR_heading1 })] })], rowSpan: 2 }),
            new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "สถานะการคงอยู่", ...TR_heading1 })] })], columnSpan: 3 }),
            new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "หมายเหตุ", ...TR_heading1 })] })], rowSpan: 2 }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "ปฏิบัติอย่างแพร่หลาย", ...TR_heading1 })] })] }),
            new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "เสี่ยงต่อการสูญหาย", ...TR_heading1 })] })] }),
            new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "ไม่มีปฏิบัติแล้ว", ...TR_heading1 })] })] }),
          ]
        }),
        ...tableBody
      ]
    });
    const document = new Document({
      sections: [{
        properties: {
          page: {
            size: {
              orientation: PageOrientation.LANDSCAPE
            }
          }
        },
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "แบบสำรวจมรดกภูมิปัญญาทางวัฒนธรรม",
                ...TR_heading1
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `อำเภอ${doc.district}  จังหวัด ${doc.province}`,
                ...TR_paragraph
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `ปีที่สำรวจ พ.ศ. ${doc.year ?? new Date().getFullYear() + 543}`,
                ...TR_paragraph
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
          table
        ],
      }],
    });
    this._blob = await Packer.toBlob(document);
  }
  async createMP02(doc: any): Promise<any> {
    this.init = true;
    const font = { name: this.fontName }
    const TR_paragraph = {
      size: 15 * 2,
      font
    }
    const TR_heading1 = {
      size: 15 * 2,
      bold: true
    }
    const TR_heading2 = {
      size: 14 * 2,
      bold: false,
      font
    }
    const document = new Document({
      sections: [
        {
          children: [
            new Paragraph({ children: [new TextRun({ text: "แบบจัดทํารายการเบื้องต้นมรดกภูมิปัญญาทางวัฒนธรรม", ...TR_heading1 }),], alignment: AlignmentType.CENTER }),
            new Paragraph({ children: [new TextRun({ text: "ส่วนที่ ๑ ลักษณะของมรดกภูมิปัญญาทางวัฒนธรรม", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "๑. ชื่อรายการ ", ...TR_heading2 })] }),
            new Paragraph({ children: [new TextRun({ text: "ชื่อเรียกในท้องถิ่น ", ...TR_heading2 })] }),
            new Paragraph({ children: [new TextRun({ text: "๒. ลักษณะของมรดกภูมิปัญญาทางวัฒนธรรม (เลือกได้มากกว่า ๑ ช่อง) ", ...TR_heading2 })] }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " วรรณกรรมพื้นบ้านและภาษา", ...TR_heading2 }),
              ]
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " ศิลปะการแสดง", ...TR_heading2 }),
              ]
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " แนวปฏิบัติทางสังคม พิธีกรรม ประเพณี และเทศกาล", ...TR_heading2 }),
              ]
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " ความรู้และแนวปฏิบัติเกี่ยวกับธรรมชาติและจักรวาล", ...TR_heading2 }),
              ]
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " งานช่างฝีมือดั้งเดิม", ...TR_heading2 }),
              ]
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " การเล่นพื้นบ้าน กีฬาพื้นบ้านและศิลปะการต่อสู้ป้องกันตัว", ...TR_heading2 }),
              ]
            }),
            new Paragraph({ children: [new TextRun({ text: "๓. พื้นที่ปฏิบัติ ", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),
            new Paragraph({ children: [new TextRun({ text: "๔.สาระสำคัญของมรดกภูมิปัญญาทางวัฒนธรรมโดยสังเขป", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),
            new Paragraph({ children: [new TextRun({ text: "๕.	ประวัติความเป็นมา", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),
            new Paragraph({ children: [new TextRun({ text: "๖. ลักษณะเฉพาะที่แสดงถึงอัตลักษณ์ของมรดกภูมิปัญญาทางวัฒนธรรม โดยให้มีรายละเอียดครอบคลุมสาระ ดังต่อไปนี้ ", ...TR_heading1})] }),
            new Paragraph({ children: [new TextRun({ text: "วรรณกรรมพื้นบ้านและภาษา", ...TR_heading2})] }),
            new Paragraph({ children: [new TextRun({ text: "- วรรณกรรมพื้นบ้าน ประกอบด้วย ประเภท โครงเรื่องของแต่ละสำนวน ลักษณะเด่นของแต่ละสำนวน และความสัมพันธ์และบทบาทในวิถีชีวิต", ...TR_heading2})] }),
            new Paragraph({ children: [new TextRun({ text: "- ภาษา ประกอบด้วย ระบบภาษา คำและความหมาย ระบบการเขียน ภาษาเขียนดั้งเดิม ลักษณะการสื่อสาร การปรากฏใช้ วรรณกรรมมุขปาฐะ", ...TR_heading2})] }),
            new Paragraph({ children: [new TextRun({ text: "ศิลปะการแสดงประกอบด้วย ลักษณะการแสดง ประเภท พัฒนาการ ขนบ ความเชื่อ ลำดับขั้นตอนการแสดง รูปแบบการจัดการแสดง โน้ตเพลง บทเพลง บทละคร อุปกรณ์ และกระบวนท่า", ...TR_heading2})] }),
            new Paragraph({ children: [new TextRun({ text: "แนวปฏิบัติทางสังคม พิธีกรรม ประเพณี และเทศกาล ประกอบด้วย ประเภท สถานที่ แหล่งปฏิบัติระเบียบพิธีกรรม การประพฤติปฏิบัติของการแสดงออกนั้น ๆ ภูมิธรรม ภูมิปัญญา เอกลักษณ์อัตลักษณ์ และความเชื่อ", ...TR_heading2})] }),
            new Paragraph({ children: [new TextRun({ text: "ความรู้และแนวปฏิบัติเกี่ยวกับธรรมชาติและจักรวาล ประกอบด้วย ประเภท ความเชื่อ วัสดุ และกระบวนการ ขั้นตอน วิธีการ", ...TR_heading2})] }),
            new Paragraph({ children: [new TextRun({ text: "งานช่างฝีมือดั้งเดิม ประกอบด้วย ประเภท ลักษณะพิเศษหรือเอกลักษณ์ เครื่องมือ กลวิธีการผลิตงานและกระบวนการจัดการองค์ความรู้ ของช่างฝีมือดั้งเดิม", ...TR_heading2})] }),
            new Paragraph({ children: [new TextRun({ text: "การเล่นพื้นบ้าน กีฬาพื้นบ้าน และศิลปะการต่อสู้ป้องกันตัว ประกอบด้วย ประเภท ความเชื่อต่าง ๆ ที่เกี่ยวข้อง ขนบ ข้อปฏิบัติที่เกี่ยวข้อง คุณค่า และวิธีการแข่งขัน ", ...TR_heading2})] }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),

            
            new Paragraph({ children: [new TextRun({ text: "ส่วนที่ ๒ คุณค่าและบทบาทของวิถีชุมชนที่มีต่อมรดกภูมิปัญญาทางวัฒนธรรม", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "๑. คุณค่าของมรดกภูมิปัญญาทางวัฒนธรรมที่สำคัญ", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),
            new Paragraph({ children: [new TextRun({ text: "๒. บทบาทของชุมชนที่มีต่อมรดกภูมิปัญญาทางวัฒนธรรม", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),

            new Paragraph({ children: [new TextRun({ text: "ส่วนที่ ๓ มรดกการในการส่งเสริมและรักษามรดกภูมิปัญญาทางวัฒนธรรม", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "๑.โครงการ กิจกรรมที่มีการดำเนินงานของรายการมรดกภูมิปัญญาทางวัฒนธรรม", ...TR_heading1 })] }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " การศึกษา วิจัย (ระบุวิธีดำเนินงาน พื้นที่ ชุมชน ระยะเวลา และงบประมาณ)", ...TR_heading2 }),
              ]
            }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " การอนุรักษ์ ฟื้นฟู (ระบุวิธีดำเนินงาน พื้นที่ ชุมชน ระยะเวลา และงบประมาณ)", ...TR_heading2 }),
              ]
            }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " การสืบสานและถ่ายทอด (ระบุวิธีดำเนินงาน พื้นที่ ชุมชน ระยะเวลา และงบประมาณ)", ...TR_heading2 }),
              ]
            }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " การพัฒนาต่อยอดมรดกภูมิปัญญา (ระบุวิธีดำเนินงาน พื้นที่ ชุมชน ระยะเวลา และงบประมาณ)", ...TR_heading2 }),
              ]
            }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " การดำเนินงานด้านอื่น ๆ (ระบุวิธีดำเนินงาน พื้นที่ ชุมชน ระยะเวลา และงบประมาณ)", ...TR_heading2 }),
              ]
            }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),
            new Paragraph({ children: [new TextRun({ text: "๒. มาตรการส่งเสริมและรักษามรดกภูมิปัญญาทางวัฒนธรรมอื่นๆ ที่คาดว่าจะดำเนินการในอนาคต", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),
            new Paragraph({ children: [new TextRun({ text: "๓. การส่งเสริม สนับสนุนจากหน่วยงานภาครัฐ หรือภาคเอกชน หรือภาคประชาสังคม (ถ้ามี)", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),

            new Paragraph({ children: [new TextRun({ text: "ส่วนที่ ๔ สถานภาพปัจจุบัน", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "๑. สถานะการคงอยู่ของมรดกภูมิปัญญาทางวัฒนธรรม ", ...TR_heading1 })] }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " มีการปฏิบัติอย่างแพร่หลาย", ...TR_heading2 }),
              ]
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " เสี่ยงต่อการสูญหายต้องได้รับการส่งเสริมและรักษาอย่างเร่งด่วน", ...TR_heading2 }),
              ]
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "☐" + " ไม่มีการปฏิบัติอยู่แล้วแต่มีความสำคัญต่อวิถีชุมชนที่ต้องได้รับการฟื้นฟู", ...TR_heading2 }),
              ]
            }),
            new Paragraph({ children: [new TextRun({ text: "๒. สถานภาพปัจจุบันของการถ่ายทอดความรู้และปัจจัยคุกคาม", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),
            new Paragraph({ children: [new TextRun({ text: "๓. รายชื่อผู้สืบทอดหลัก(เช่น บุคคล กลุ่มคน .... เป็นต้น)", ...TR_heading1 })] }),
            // new TableRow({
            //   children: [
            //     new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "ลำดับที่", ...TR_heading1 })] })], rowSpan: 2 }),
            //     new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "ชื่อรายการ", ...TR_heading1 })] })], rowSpan: 2 }),
            //     new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "ลักษณะ", ...TR_heading1 })] })], rowSpan: 2 }),
            //     new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "ข้อมูลโดยสังเขป", ...TR_heading1 })] })], rowSpan: 2 }),
            //     new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "พื้นที่ปฏิบัติ", ...TR_heading1 })] })], rowSpan: 2 }),
            //     new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "ชื่อผู้ที่ถือปฏิบัติและผู้สืบทอด", ...TR_heading1 })] })], rowSpan: 2 }),
            //     new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "สถานะการคงอยู่", ...TR_heading1 })] })], columnSpan: 3 }),
            //     new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: "หมายเหตุ", ...TR_heading1 })] })], rowSpan: 2 }),
            //   ]
            // }),






            new Paragraph({ children: [new TextRun({ text: "ส่วนที่ ๕ การยินยอมของชุมชนในการจัดทำรายการเบื้องต้นมรดกภูมิปัญญาทางวัฒนธรรม", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "(ไม่จำกัดจำนวน)", ...TR_heading2 })] }),
            new Paragraph({ children: [new TextRun({ text: "ชื่อ-สกุล ", ...TR_heading2 })] }),
            new Paragraph({ children: [new TextRun({ text: "สถานภาพที่เกี่ยวข้องกับมรดกภูมิปัญญาทางวัฒนธรรม (เช่น เป็นครูผู้สืบทอด เป็นผู้ได้รับการถ่ายทอดความรู้ เป็นผู้นำชุมชน เป็นเจ้าหน้าที่ในชุมชน  เป็นผู้ชม เป็นผู้รับบริการ หรือเป็นผู้สนับสนุนด้านอื่นๆที่เกี่ยวข้อง) ", ...TR_heading2 })] }),
            new Paragraph({ children: [new TextRun({ text: "... ", ...TR_heading2 })] }),


            new Paragraph({ children: [new TextRun({ text: "ส่วนที่ ๖ ภาคผนวก", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "๑. เอกสารอ้างอิง", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "๒. บุคคลอ้างอิง", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "๓. รูปภาพพร้อมคำอธิบายใต้ภาพ จำนวน ๑๐ ภาพ", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "๔. ข้อมูลภาพถ่าย ข้อมูลภาพเคลื่อนไหว หรือข้อมูลเสียง", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "๕. ข้อมูลผู้เสนอ", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "๖. ข้อมูลผู้ประสานงาน", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: "ชื่อ-สกุล .....", ...TR_paragraph })] }),
            new Paragraph({ children: [new TextRun({ text: "โทรศัพท์ ..... โทรสาร ..... มือถือ .....", ...TR_paragraph })] }),
            new Paragraph({ children: [new TextRun({ text: "อีเมล์ .....", ...TR_paragraph })] }),
          ],
        },
      ],
    });
    this._blob = await Packer.toBlob(document);
  }
  async createMP03(doc: any): Promise<any> {
    this.init = true;
    const font = { name: this.fontName }
    const TR_paragraph = {
      size: 15 * 2,
      font
    }
    const TR_heading1 = {
      size: 15 * 2,
      bold: true,
      font
    }
    const tableBody = doc.items.map((item: any, i: number) => {
      return new TableRow({
        children: [
          new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: String(i + 1), ...TR_paragraph })] })] }),
          new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: item.name, ...TR_paragraph })] })] }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({ text: ((item.branch ?? []).includes('วรรณกรรมพื้นบ้านและภาษา') ? "✓" : "☐") + " วรรณกรรมพื้นบ้านและภาษา", ...TR_paragraph }),
                ]
              }),
              new Paragraph({
                children: [
                  new TextRun({ text: ((item.branch ?? []).includes('ศิลปะการแสดง') ? "✓" : "☐") + " ศิลปะการแสดง", ...TR_paragraph }),
                ]
              }),
              new Paragraph({
                children: [
                  new TextRun({ text: ((item.branch ?? []).includes('แนวปฏิบัติทางสังคม พิธีกรรม ประเพณี และเทศกาล') ? "✓" : "☐") + " แนวปฏิบัติทางสังคม พิธีกรรม ประเพณี และเทศกาล", ...TR_paragraph }),
                ]
              }),
              new Paragraph({
                children: [
                  new TextRun({ text: ((item.branch ?? []).includes('ความรู้และแนวปฏิบัติเกี่ยวกับธรรมชาติและจักรวาล') ? "✓" : "☐") + " ความรู้และแนวปฏิบัติเกี่ยวกับธรรมชาติและจักรวาล", ...TR_paragraph }),
                ]
              }),
              new Paragraph({
                children: [
                  new TextRun({ text: ((item.branch ?? []).includes('งานช่างฝีมือดั้งเดิม') ? "✓" : "☐") + " งานช่างฝีมือดั้งเดิม", ...TR_paragraph }),
                ]
              }),
              new Paragraph({
                children: [
                  new TextRun({ text: ((item.branch ?? []).includes('การเล่นพื้นบ้าน กีฬาพื้นบ้านและศิลปะการต่อสู้ป้องกันตัว') ? "✓" : "☐") + " การเล่นพื้นบ้าน กีฬาพื้นบ้านและศิลปะการต่อสู้ป้องกันตัว", ...TR_paragraph }),
                ]
              }),
            ]
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({ text: (item.requestType === 'รายการตัวแทนมรดกภูมิปัญญาทางวัฒนธรรม' ? "✓" : "☐") + " รายการตัวแทนมรดกภูมิปัญญาทางวัฒนธรรม", ...TR_paragraph }),
                ]
              }),
              new Paragraph({
                children: [
                  new TextRun({ text: (item.requestType === 'รายการมรดกภูมิปัญญาทางวัฒนธรรม\r\nที่จำเป็นต้องส่งเสริมและรักษาอย่างเร่งด่วน' ? "✓" : "☐") + " รายการมรดกภูมิปัญญาทางวัฒนธรรมที่จำเป็นต้องส่งเสริมและรักษาอย่างเร่งด่วน", ...TR_paragraph }),
                ]
              })
            ]
          }),
        ]
      })
    })

    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({ width: { type: WidthType.PERCENTAGE, size: 10 }, children: [new Paragraph({ children: [new TextRun({ text: "ลำดับที่", ...TR_heading1 })] })] }),
            new TableCell({ width: { type: WidthType.PERCENTAGE, size: 30 }, children: [new Paragraph({ children: [new TextRun({ text: "ชื่อรายการ", ...TR_heading1 })] })] }),
            new TableCell({ width: { type: WidthType.PERCENTAGE, size: 30 }, children: [new Paragraph({ children: [new TextRun({ text: "ลักษณะ", ...TR_heading1 })] })] }),
            new TableCell({ width: { type: WidthType.PERCENTAGE, size: 30 }, children: [new Paragraph({ children: [new TextRun({ text: "ประเภท", ...TR_heading1 })] })] }),
          ]
        }),
        ...tableBody
      ]
    })

    const document = new Document({
      sections: [
        {
          children: [
            new Paragraph({ children: [new TextRun({ text: "แบบเสนอรายการเบื้องต้นมรดกภูมิปัญญาทางวัฒนธรรมเพื่อขอขึ้นบัญชีมรดกภูมิปัญญาทางวัฒนธรรม", ...TR_heading1 }),], alignment: AlignmentType.CENTER, }),
            new Paragraph({ children: [new TextRun({ text: `ของจังหวัด ${doc.province}`, ...TR_heading1 }),], alignment: AlignmentType.CENTER, }),
            new Paragraph({ children: [new TextRun({ text: `จำนวน ${(doc.items ?? []).length} รายการ`, ...TR_heading1 }),], alignment: AlignmentType.CENTER, }),
            new Paragraph({ children: [new TextRun({ text: "ส่วนที่ ๑ รายการมรดกภูมิปัญญาทางวัฒนธรรมที่เสนอ", ...TR_heading1 })] }),
            table,
            new Paragraph({ children: [new TextRun({ text: "", ...TR_paragraph })] }),
            new Paragraph({ children: [new TextRun({ text: "ทั้งนี้ได้แนบแบบ มภ.๒ มาพร้อมนี้", ...TR_paragraph })] }),
            new Paragraph({ children: [new TextRun({ text: "", ...TR_paragraph })] }),
            new Paragraph({ children: [new TextRun({ text: "ส่วนที่ ๒ ข้อมูลหน่วยงาน / ชุมชน / ท้องถิ่น /กลุ่มบุคคล ที่เสนอรายการ ", ...TR_heading1 })] }),
            new Paragraph({ children: [new TextRun({ text: `ชื่อ-สกุล ผู้ประสานงาน ${doc.coordinatorFirstName} ${doc.coordinatorLastName}`, ...TR_paragraph })] }),
            new Paragraph({ children: [new TextRun({ text: `เลขที่ ${(doc.proposer??{address:""}).address??""} อาคาร ${(doc.proposer??{village:""}).village} หมู่ ${(doc.proposer??{moo:""}).moo} ซอย ${(doc.proposer??{soi:""}).soi} ถนน ${(doc.proposer??{address:""}).address??""}`, ...TR_paragraph })] }),
            new Paragraph({ children: [new TextRun({ text: `แขวง/ตำบล ${(doc.proposer??{subDistrict:""}).subDistrict} เขต/อำเภอ ${(doc.proposer??{district:""}).district} จังหวัด ${(doc.proposer??{province:""}).province}`, ...TR_paragraph })] }),
            new Paragraph({ children: [new TextRun({ text: `รหัสไปรษณีย์ ${(doc.proposer??{postalCode:""}).postalCode}`, ...TR_paragraph })] }),
            new Paragraph({ children: [new TextRun({ text: `โทรศัพท์ ${(doc.proposer??{telephone:""}).telephone}  โทรสาร ${(doc.proposer??{fax:""}).fax} มือถือ ${(doc.proposer??{mobile:""}).mobile}`, ...TR_paragraph })] }),
            new Paragraph({ children: [new TextRun({ text: `อีเมล์ ${(doc.proposer??{email:""}).email}`, ...TR_paragraph })] }),
            new Paragraph({ children: [new TextRun({ text: `กรรมการส่งเสริมและรักษามรดกภูมิปัญญาทางวัฒนธรรม จังหวัด ${(doc.proposer??{province:""}).province}`, ...TR_paragraph })] }),
            new Paragraph({ children: [new TextRun({ text: "", ...TR_paragraph })] }),
            new Paragraph({ children: [new TextRun({ text: `ลงนาม ${('.').repeat(60)}`, ...TR_paragraph })], alignment: AlignmentType.RIGHT }),
            new Paragraph({ children: [new TextRun({ text: `(${('.').repeat(60)})`, ...TR_paragraph })], alignment: AlignmentType.RIGHT }),
            new Paragraph({ children: [new TextRun({ text: `วันที่ ${('.').repeat(60)}`, ...TR_paragraph })], alignment: AlignmentType.RIGHT })

          ],
        },
      ],
    });
    this._blob = await Packer.toBlob(document);
  }
}
