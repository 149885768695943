import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/mockup/khon-001.jpg'


const _hoisted_1 = { class: "container-fluid p-0 m-0 public-home-banner-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.text!==undefined)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: `${_ctx.ADDRESS}/${_ctx.text}`
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("img", _hoisted_3))
  ]))
}