
import { Options, Vue } from "vue-class-component";
import SideNavigation from "@/components/SideNavigation.vue";
import { useStore } from "vuex";
import { Watch } from "vue-property-decorator";
import { io } from "socket.io-client"
import { SOCKET_ADDRESS } from '@/settings'
@Options({
  components: {
    SideNavigation
  },
})
export default class Portal extends Vue {
  store = useStore()
  @Watch('store.state.userId')
  pushtoLogin(){
    if(this.store.state.userId == ''){
      console.log('this.store.state.userId',this.store.state.userId)
      this.$router.push('/login')
    }
  }
  mounted() {
    this.pushtoLogin()
    console.log("Portal Mounted")
    document.title = `ฐานข้อมูลมรดกวัฒนธรรม ${this.store.getters.version}`
  }
}
