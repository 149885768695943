
import { Options, Vue } from "vue-class-component";
@Options({
  components: {},
  props: {
    modelValue: String,
  },
})
export default class JMSwitch extends Vue {
  options!: Array<any>;
  active:boolean = false
  modelValue!: boolean;
  updateValue(){
    this.$emit("update:modelValue", this.modelValue)
  }
}
